import { getIncomers, getOutgoers } from '@xyflow/react'
import { useShallow } from 'zustand/react/shallow'

import { useStore } from '@app/store'
import type { MapDomainNode } from '@app/types'

type UseGetNeighbors = (node: MapDomainNode) => MapDomainNode[]

const useGetNeighbors: UseGetNeighbors = (node) =>
  useStore(
    useShallow((state) => {
      if (!node) {
        return []
      }

      const nodes = state.node
      const edges = state.edge

      const incomers = getIncomers(node, Object.values(nodes), Object.values(edges))
      const outgoers = getOutgoers(node, Object.values(nodes), Object.values(edges))

      return [...incomers, ...outgoers]
    })
  )

export default useGetNeighbors
