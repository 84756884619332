import { Box, Drawer, DrawerBody, DrawerCloseButton, HStack, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'

import RecurringReportConfigurationForm from './components/recurringReportConfigurationForm'

import { ReportContext } from '@app/pages/reports/home'
import type { RecurringReportConfigurationQuery } from '@graphql/queries'

const Edit: FC = () => {
  const { recurringReportConfiguration } = useLoaderData() as {
    recurringReportConfiguration: RecurringReportConfigurationQuery['recurringReportConfiguration']
  }

  const navigate = useNavigate()
  const backLink = '..'

  const onCancel = () => {
    navigate(backLink)
  }

  return (
    <ReportContext.Consumer>
      {({ containerRef }) => (
        <Box>
          <Stack w="3xl" mx="auto" spacing={4}>
            <HStack
              pos="sticky"
              top={0}
              py={4}
              borderBottom="1px"
              borderBottomColor="border.default"
              bgColor="bg.muted"
            >
              <Text color="subtle" fontSize="sm">
                Edit Recurring Report
              </Text>
            </HStack>
          </Stack>
          <Drawer
            isOpen
            onClose={() => {
              navigate('..')
            }}
            portalProps={{ containerRef }}
            size="sm"
          >
            <DrawerCloseButton />
            <DrawerBody overflowY="auto" h="100%" bgColor="bg.surface">
              <Stack spacing={4}>
                <RecurringReportConfigurationForm
                  onCancel={onCancel}
                  recurringReportConfiguration={recurringReportConfiguration}
                />
              </Stack>
            </DrawerBody>
          </Drawer>
        </Box>
      )}
    </ReportContext.Consumer>
  )
}

export default Edit
