import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { PiHeadCircuit } from 'react-icons/pi'
import { Link } from 'react-router-dom'

const AiChatMenu = ({ strategyId }) => (
  <Menu>
    <MenuButton
      as={IconButton}
      aria-label="AI Chat"
      colorScheme="gray"
      icon={<PiHeadCircuit />}
      size="2xs"
      variant="ghost"
    />
    <MenuList>
      <MenuItem as={Link} to={`/strategy/${strategyId}/map/ai/chat`}>
        Data scientist
      </MenuItem>
      <MenuItem as={Link} to={`/strategy/${strategyId}/map/ai/map_generator`}>
        Company map generator
      </MenuItem>
      <MenuItem as={Link} to={`/strategy/${strategyId}/map/ai/kpi_tree_generator`}>
        KPI tree generator
      </MenuItem>
    </MenuList>
  </Menu>
)

export default AiChatMenu
