import { Avatar, Divider, HStack, Spacer, Stack, Tag, Text, Wrap, WrapItem } from '@chakra-ui/react'
import type { FC } from 'react'

import useGetObject from '@app/hooks/useGetObject'
import CardTypeSection from '@app/shared/cards/components/cardTypeSection'
import CardEntityContributorsAndStatus from '@app/shared/cards/entity/cardEntityContributorsAndStatus'
import CardEntityStatsAndProgress from '@app/shared/cards/entity/cardEntityStatsAndProgress'
import CardEntityStatus from '@app/shared/cards/entity/cardEntityStatus'
import MarkdownDisplay from '@app/shared/markdownDisplay'
import ICON_MAP, { COLOR_MAP } from '@app/utils/iconMap'
import profileImage from '@app/utils/profileImage'
import type { ReportStandardQuery } from '@graphql/queries'

interface Props {
  entity: ReportStandardQuery['report']['entities'][0]
}

const Entity: FC<Props> = ({ entity }) => {
  const { foreignState, goalHypothesis, name, labels, ownerId, results, summary, workflowState } = entity
  const owner = useGetObject(ownerId, 'user')

  return (
    <Stack my={3}>
      <HStack>
        <CardTypeSection title="Work" icon={ICON_MAP.Work} color={COLOR_MAP.Entity} size="normal" />
        <Text fontWeight="semibold">{name}</Text>
        <Spacer />
        {owner && (
          <>
            <Avatar name={owner.name} size="xs" src={profileImage(owner.email)} />
            <Text>{owner.name}</Text>
          </>
        )}
      </HStack>
      <Divider />
      {summary && (
        <HStack my={2}>
          <Text as="span" alignSelf="start" fontWeight="bold">
            Description:
          </Text>
          <MarkdownDisplay as="span" text={summary} />
        </HStack>
      )}
      {goalHypothesis && (
        <HStack my={2}>
          <Text as="span" alignSelf="start" fontWeight="bold">
            Goal/Hypothesis:
          </Text>
          <MarkdownDisplay as="span" text={goalHypothesis} />
        </HStack>
      )}
      {results && (
        <HStack my={2}>
          <Text as="span" alignSelf="start" fontWeight="bold">
            Results:
          </Text>
          <MarkdownDisplay as="span" text={results} />
        </HStack>
      )}
      <CardEntityStatsAndProgress entity={entity} />
      <CardEntityContributorsAndStatus entity={entity} />
      <HStack>
        <Wrap spacing={4}>
          {labels.map((label) => (
            <WrapItem key={`label-${label.id}`}>
              <Tag key={label.id} fontSize="sm" fontWeight="bold">
                {label.name}
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
        <Spacer />
        <CardEntityStatus status={foreignState} workflowState={workflowState} />
      </HStack>
    </Stack>
  )
}

export default Entity
