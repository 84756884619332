import type { FC } from 'react'

import UserProfile from '@app/shared/userProfile'

interface Props {
  data: {
    name: string
    email: string
    disabledAt: string
  }
}

const NameCell: FC<Props> = ({ data: member }) => <UserProfile user={member} />

export default NameCell
