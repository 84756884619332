import { Button, type ButtonProps } from '@chakra-ui/react'
import type { FC, MouseEvent } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import Can from '@app/shared/authorization/can'
import useToast, { Notification } from '@app/shared/toast'
import { usePlaybookApplyMutation } from '@graphql/queries'

interface Props extends ButtonProps {
  playbookId: string
}

const UsePlaybookButton: FC<Props> = ({ playbookId, ...rest }) => {
  const { user } = useStoreCurrentUser()
  const [, createStrategyFromPlaybook] = usePlaybookApplyMutation()
  const toast = useToast()
  const navigate = useNavigate()

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    // Prevent the parent overlay (ImageWithOverlay) link from firing when this button is clicked.
    e.preventDefault()

    createStrategyFromPlaybook({
      input: {
        playbookId
      }
    }).then((resp) => {
      if (resp.error) {
        throw resp
      }

      const { errors } = resp.data.playbookApply

      if (errors.length) {
        throw errors
      }

      toast({
        title: 'Creating strategy',
        position: 'bottom-right',
        status: 'success',
        render: (props) => <Notification {...props} />
      })

      navigate(`/strategy/${resp.data.playbookApply.strategy.id}`)
    })
  }

  if (!user) {
    return (
      <Button
        key="sign_up"
        as={Link}
        aria-label="Sign up to use this playbook"
        to={`/sign_up?utm_source=playbooks&utm_medium=public&utm_content=use&utm_campaign=${playbookId}`}
        variant="primary"
        {...rest}
      >
        Use this playbook
      </Button>
    )
  }

  return (
    <Can I="use" a="playbook">
      <Button {...rest} w="100%" onClick={handleClick}>
        Use Playbook
      </Button>
    </Can>
  )
}

export default UsePlaybookButton
