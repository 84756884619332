import { Stack, HStack, Text, Spacer, Button, ButtonGroup } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect } from 'react'
import { Link, useParams, useLoaderData } from 'react-router-dom'

import ReportContent from './components/reportContent'

import useGetObject from '@app/hooks/useGetObject'
import PublishReportButton from '@app/pages/reports/components/shared/publishReportButton'
import SendReportButton from '@app/pages/reports/components/shared/sendReportButton'
import Can from '@app/shared/authorization/can'
import { useStore } from '@app/store'
import unpack from '@app/store/unpack'
import type { Report } from '@graphql/types'

const Show: FC = () => {
  const { strategyId, reportId } = useParams()
  const bulkAdd = useStore.use.bulkAdd()

  const { report: serverLoadedReport } = useLoaderData() as { report: Report }

  useEffect(() => {
    if (serverLoadedReport) {
      const combinedArray = unpack(serverLoadedReport)

      bulkAdd(combinedArray)
    }
  }, [bulkAdd, serverLoadedReport])

  const report = useGetObject(reportId, 'report')
  const strategy = useGetObject(strategyId, 'strategy')

  if (!report) {
    return null
  }

  const { reportState, updatedAt } = report
  const updated = new Date(updatedAt)

  return (
    <Stack w="3xl" mx="auto" spacing={4}>
      <HStack
        pos="sticky"
        zIndex="overlay"
        top={0}
        py={4}
        borderBottom="1px"
        borderBottomColor="border.default"
        bgColor="bg.muted"
      >
        <Text color="subtle" fontSize="sm">
          Last updated on{' '}
          {updated.toLocaleDateString(undefined, {
            weekday: 'short',
            month: 'numeric',
            day: 'numeric',
            year: 'numeric'
          })}{' '}
          at {updated.toLocaleTimeString()}
        </Text>
        <Spacer />
        <Can I="update" an="strategy">
          <ButtonGroup>
            {reportState === 'published' ? (
              <SendReportButton report={report} />
            ) : (
              <PublishReportButton report={report} />
            )}
            <Button as={Link} to="edit" variant="tertiary">
              Edit
            </Button>
          </ButtonGroup>
        </Can>
      </HStack>
      <ReportContent report={report} strategy={strategy} />
    </Stack>
  )
}

export default Show
