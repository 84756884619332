import { IconButton, Menu, MenuButton, MenuItem, MenuList, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import useGetEdges from '@app/hooks/useGetEdges'
import useGetNodes from '@app/hooks/useGetNodes'
import { hasLoop } from '@app/pages/maps/components/nodes/helpers'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import { useStore } from '@app/store'
import type { MapDomainNode } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

interface Props {
  node: MapDomainNode
}

const CollapseNodeButton: FC<Props> = ({ node }) => {
  const { strategyId } = useParams()
  const nodes = useGetNodes(strategyId)
  const edges = useGetEdges(strategyId)
  const collapseNode = useStore.use.collapseNode()
  const collapseNodes = useStore.use.collapseNodes()
  const getIncomers = useStore.use.getIncomers()

  const incomers = getIncomers(node)
  const hasIncomers = incomers.length > 0

  const { canEdit } = usePermissionsContext()

  if (!canEdit) {
    return null
  }

  const disabled = hasLoop(node, Object.values(nodes), Object.values(edges))

  const buttonStyles = {
    borderRadius: 'none',
    'aria-label': 'Collapse cards',
    icon: <ICON_MAP.CollapseAction />
  }

  const collapseCard = (
    <Tooltip label="Collapse card">
      <IconButton {...buttonStyles} onClick={() => collapseNode(strategyId, node)} />
    </Tooltip>
  )

  const menuOptions = (
    <Menu>
      <Tooltip label="Collapse card">
        <MenuButton as={IconButton} {...buttonStyles} />
      </Tooltip>
      <MenuList>
        <Tooltip isDisabled={!disabled} label="Cannot collapse a loop">
          <MenuItem isDisabled={disabled} onClick={() => collapseNode(strategyId, node)}>
            Collapse downstream
          </MenuItem>
        </Tooltip>

        <Tooltip isDisabled={!disabled} label="Cannot collapse a loop">
          <MenuItem isDisabled={disabled} onClick={() => collapseNodes(strategyId, incomers)}>
            Collapse all upstream cards
          </MenuItem>
        </Tooltip>
      </MenuList>
    </Menu>
  )

  return hasIncomers ? menuOptions : collapseCard
}

export default CollapseNodeButton
