import { Alert, Box } from '@chakra-ui/react'
import pluralize from 'pluralize'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import type { CustomerSubscription } from '@graphql/types'

type Props = {
  subscription: Pick<CustomerSubscription, 'trialEndDate'>
}

const FreeTrialAlert: FC<Props> = ({ subscription }) => {
  const endDate = subscription.trialEndDate

  if (!endDate) {
    return null
  }

  const daysRemaining = Math.ceil((new Date(endDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))

  return (
    <Alert data-testid="free-trial-alert" status="info">
      <Box>
        You have {daysRemaining} {pluralize('day', daysRemaining)} remaining in your trial.{' '}
        <Link to="/settings/billing">Upgrade now</Link>
      </Box>
    </Alert>
  )
}

export default FreeTrialAlert
