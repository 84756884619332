import { StiggProvider } from '@stigg/react-sdk'
import { Suspense } from 'react'
import { CloudimageProvider } from 'react-cloudimage-responsive'
import { Outlet, ScrollRestoration } from 'react-router-dom'

import VersionCheck from '@app/lib/versionCheck'
import CASLProvider from '@app/shared/authorization/caslProvider'
import StiggTheme from '@app/shared/billing/stiggTheme'
import { RegisterSentryUser } from '@app/shared/errorPage'
import Head from '@app/shared/layout/head'
import { initializeSentry, Sentry } from '@app/utils/sentry'
import GraphqlProvider from '@graphql/graphqlProvider'

const cloudimageConfig = {
  token: import.meta.env.PUBLIC_CLOUDIMAGE_TOKEN
}

const stiggApiKey = import.meta.env.PUBLIC_STIGG_CLIENT_API_KEY

initializeSentry()

interface CloudImageProviderProps {
  children: React.ReactNode
}

const CloudimageProviderFiltered = ({ children }: CloudImageProviderProps) => (
  <CloudimageProvider {...({ children } as Element)} config={cloudimageConfig} />
)

const Root = () => (
  <GraphqlProvider>
    <StiggProvider
      apiKey={stiggApiKey}
      theme={StiggTheme() as unknown}
      customerId={window?.dlOrganizationId?.toString() || null}
    >
      <Suspense fallback={null}>
        <CloudimageProviderFiltered>
          <RegisterSentryUser />
          <Head />
          <CASLProvider>
            <Outlet />
            <VersionCheck />
          </CASLProvider>
        </CloudimageProviderFiltered>

        <ScrollRestoration />
      </Suspense>
    </StiggProvider>
  </GraphqlProvider>
)

export default Sentry.withProfiler(Root)
