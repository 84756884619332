import { Divider, HStack, Spacer, Stack, Tag, Text, Wrap, WrapItem } from '@chakra-ui/react'
import type { FC } from 'react'

import useGetObject from '@app/hooks/useGetObject'
import CardBasicType from '@app/shared/cards/basicCard/cardBasicType'
import { CardAvatar, CardConfidenceRating } from '@app/shared/cards/components'
import MarkdownDisplay from '@app/shared/markdownDisplay'
import type { ReportStandardQuery } from '@graphql/queries'

interface Props {
  basicCard: ReportStandardQuery['report']['basicCards'][0]
}
const BasicCard: FC<Props> = ({ basicCard }) => {
  const { cardType, labels, name, confidenceRating, description, ownerId } = basicCard
  const owner = useGetObject(ownerId, 'user')

  return (
    <Stack my={3}>
      <HStack>
        <CardBasicType fieldName="cardTypeId" cardType={cardType} iconProps={{ boxSize: 4 }} />
        <Text fontWeight="semibold"> {name} </Text>
        <Spacer />
        <CardAvatar user={owner} showName />
      </HStack>
      <Divider />
      {description && (
        <HStack my={2}>
          <Text as="span" alignSelf="start" fontWeight="bold">
            Description:
          </Text>
          <MarkdownDisplay as="span" text={description} />
        </HStack>
      )}
      <CardConfidenceRating type={confidenceRating} />
      <HStack>
        <Wrap w="100%" spacing={4}>
          {labels.map((label) => (
            <WrapItem key={`label-${label.id}`}>
              <Tag key={label.id} fontSize="sm" fontWeight="bold">
                {label.name}
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
      </HStack>
    </Stack>
  )
}

export default BasicCard
