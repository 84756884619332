import { Box, Drawer, DrawerBody, DrawerCloseButton, HStack, Spacer, Stack, Text, StackDivider } from '@chakra-ui/react'
import debounce from 'lodash/debounce'
import type { ChangeEvent, ChangeEventHandler, FC } from 'react'
import { useParams, useNavigate, useSubmit } from 'react-router-dom'

import ChannelInput from './components/channelInput'
import HideSectionsInput from './components/hideSectionsInput'
import ReportContent from './components/reportContent'
import ReportDatesInput from './components/reportDatesInput'
import ReportDeleteButton from './components/reportDeleteButton'

import useGetObject from '@app/hooks/useGetObject'
import ReportPipelines from '@app/pages/reports/components/reportPipelines'
import { ReportContext } from '@app/pages/reports/home'
import Can from '@app/shared/authorization/can'
import { Form, TextInput, useForm } from '@app/shared/rawForms'

const Edit: FC = () => {
  const { strategyId, reportId } = useParams()
  const report = useGetObject(reportId, 'report')
  const strategy = useGetObject(strategyId, 'strategy')
  const submit = useSubmit()
  const { errors, reset } = useForm({})
  const navigate = useNavigate()

  const handleSubmit: ChangeEventHandler<HTMLFormElement> = (event) => {
    submit(event.target.form)

    reset()
  }

  const debouncedSubmit = debounce((e) => handleSubmit(e), 200)

  if (!report) {
    return null
  }

  return (
    <ReportContext.Consumer>
      {({ containerRef }) => (
        <Box>
          <Stack w="3xl" mx="auto" spacing={4}>
            <HStack
              pos="sticky"
              zIndex="overlay"
              top={0}
              py={4}
              borderBottom="1px"
              borderBottomColor="border.default"
              bgColor="bg.muted"
            >
              <Text color="subtle" fontSize="sm">
                Editing: {report?.name}
              </Text>
              <Spacer />
            </HStack>
            <Box flex="1" bgColor="bg.surface">
              <ReportContent report={report} strategy={strategy} />
            </Box>
          </Stack>
          <Drawer
            isOpen
            onClose={() => {
              navigate('..')
            }}
            portalProps={{ containerRef }}
            size="sm"
          >
            <DrawerCloseButton />
            <DrawerBody overflowY="auto" h="100%" bgColor="bg.surface">
              <Stack pt={4} spacing={4}>
                <Form method="post" onChange={(e: ChangeEvent<HTMLFormElement>) => debouncedSubmit(e)} variant="drawer">
                  <Stack divider={<StackDivider />} spacing={6}>
                    <Stack spacing={6}>
                      <TextInput
                        labelSize="sm"
                        name="name"
                        label="Report title"
                        defaultValue={report?.name}
                        placeholder="Untitled"
                        autoComplete="off"
                        errors={errors}
                        isRequired
                      />
                      <ReportDatesInput report={report} errors={errors} />
                      <Can I="view" an="admin">
                        <ReportPipelines report={report} />
                      </Can>
                    </Stack>
                    <ChannelInput report={report} />
                    <Stack spacing={6}>
                      <HideSectionsInput report={report} />
                      <ReportDeleteButton />
                    </Stack>
                  </Stack>
                </Form>
              </Stack>
            </DrawerBody>
          </Drawer>
        </Box>
      )}
    </ReportContext.Consumer>
  )
}

export default Edit
