import { Box, Button } from '@chakra-ui/react'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import BasicCard from '@app/pages/roadmaps/cards/basicCard'
import EntityCard from '@app/pages/roadmaps/cards/entityCard'
import MetricCard from '@app/pages/roadmaps/cards/metricCard'
import StrategyCard from '@app/pages/roadmaps/cards/strategyCard'
import { ADD_ROADMAP_ITEM_FORMAT } from '@app/pages/roadmaps/useInteractions'
import { useStore } from '@app/store'
import type { MapDomainObject } from '@app/types'
import type { Entity } from '@graphql/types'

interface Props {
  objects?: MapDomainObject[]
}

const CardList: FC<Props> = ({ objects = [] }) => {
  const { strategyId, roadmapItemId } = useParams()
  const addExistingRoadmapItem = useStore.use.addExistingRoadmapItem()
  const addExistingEntityToRoadmapItem = useStore.use.addExistingEntityToRoadmapItem()

  const onDragStart = useCallback((event, object) => {
    event.dataTransfer.setData(ADD_ROADMAP_ITEM_FORMAT, JSON.stringify(object))

    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.effectAllowed = 'move'
  }, [])

  if (!objects?.length) {
    return null
  }

  return (
    <>
      {objects.map((object) => {
        const { classType } = object
        let CardType

        switch (classType) {
          case 'entity':
            CardType = EntityCard
            break
          case 'metric':
            CardType = MetricCard
            break
          case 'strategy':
            CardType = StrategyCard
            break
          case 'basicCard':
            CardType = BasicCard
            break
          default:
        }

        if (!CardType) {
          return null
        }

        const handleAddClick = () => {
          if (roadmapItemId) {
            // This is always an entity for roadmaps
            addExistingEntityToRoadmapItem(roadmapItemId, object as Entity)
          } else {
            addExistingRoadmapItem(strategyId, object)
          }
        }

        return (
          <Box
            key={`existing-card-${object.id}`}
            my={1}
            p={2}
            borderWidth={1}
            borderColor="border.default"
            borderRadius="md"
            _hover={{ cursor: 'grab' }}
            _grabbed={{ cursor: 'grabbing' }}
            draggable
            onDragStart={(e) => onDragStart(e, object)}
          >
            <CardType
              domainObject={object}
              zIndex="modal" // This ensures that the element being draggable is above everything else so the browser can create a proper preview
              actionsChildren={
                <Button onClick={handleAddClick} size="sm" variant="ghost">
                  +
                </Button>
              }
            />
          </Box>
        )
      })}
    </>
  )
}

export default CardList
