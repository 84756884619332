import { Icon, Stack, useBreakpointValue } from '@chakra-ui/react'
import type { FC } from 'react'
import { BiPlay } from 'react-icons/bi'
import { Link } from 'react-router-dom'

import ImageWithOverlay from './imageWithOverlay'
import PlaybookCardAttribution from './playbookCardAttribution'

import UsePlaybookButton from '@app/pages/playbooks/components/usePlaybookButton'
import type { Playbook } from '@graphql/queries'

interface Props {
  playbook: Playbook
}

const PlaybookCard: FC<Props> = ({ playbook }) => {
  const { id, hero, name, friendlyId } = playbook

  return (
    <Stack
      minW="sm"
      spacing={useBreakpointValue({
        base: '4',
        md: '5'
      })}
    >
      <Link to={friendlyId}>
        <Stack spacing={1}>
          <PlaybookCardAttribution playbook={playbook} />
          <ImageWithOverlay
            playbook={playbook}
            title={name}
            src={hero}
            headingSize="2xs"
            wrapWithLink={false}
            url={friendlyId}
            cta={
              <UsePlaybookButton
                playbookId={id}
                aria-label="Use this playbook"
                variant="primary"
                leftIcon={<Icon as={BiPlay} boxSize="5" />}
              />
            }
          />
        </Stack>
      </Link>
    </Stack>
  )
}

export default PlaybookCard
