import { useColorModeValue } from '@chakra-ui/react'
import type { SuccessResponse, UppyFile, UppyOptions } from '@uppy/core'
import Uppy from '@uppy/core'
import { Dashboard, FileInput } from '@uppy/react'
import XHR from '@uppy/xhr-upload'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/file-input/dist/style.css'
import type { FC } from 'react'

import GenerateUploaderTicketPlugin from '@app/shared/uploader/generateUploaderTicketPlugin'
import { useUploadTicketGenerateMutation } from '@graphql/queries'

type Props = {
  uppyOptions?: UppyOptions
  onUploadSuccess?: (file: UppyFile, response: SuccessResponse) => void
  fileInput?: boolean
  inputName?: string
}

const ENDPOINT = import.meta.env.PUBLIC_UPLOADER_ENDPOINT

const DoubleloopUploader: FC<Props> = ({
  uppyOptions = {},
  onUploadSuccess = () => {},
  fileInput = false,
  inputName = undefined
}) => {
  const [, getTicket] = useUploadTicketGenerateMutation()
  const theme = useColorModeValue('light', 'dark')

  const uppy = new Uppy(uppyOptions)

  uppy.use(GenerateUploaderTicketPlugin, { getTicket })

  uppy.use(XHR, {
    endpoint: ENDPOINT,
    formData: false,
    headers: (file) => ({
      'Content-Type': file.type,
      'X-Filename': file.name,
      'X-Ticket': file.meta.ticket as string
    })
  })

  uppy.on('upload-success', (file, response) => {
    onUploadSuccess(file, response)
  })

  if (fileInput) {
    return <FileInput uppy={uppy} inputName={inputName} />
  }

  return <Dashboard uppy={uppy} theme={theme} />
}

export default DoubleloopUploader
