import chunk from 'lodash/chunk'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'

import CorrelationScoreBatchLoader from '@app/pages/correlations/components/correlationScoreBatchLoader'

const SCORE_BATCH_SIZE = 10

interface Props {
  correlationPairs: Array<{
    source: {
      id: string
    }
    target: {
      id: string
    }
  }>
}

const BatchLoadScores: FC<Props> = ({ correlationPairs }) => {
  const scoreBatches = chunk(
    correlationPairs.map(({ source, target }) => ({ sourceId: source.id, targetId: target.id })),
    SCORE_BATCH_SIZE
  )

  return (
    <>
      {scoreBatches.map((idPairs) => {
        const key = sortBy(idPairs, ['sourceId', 'targetId'])
          .map(({ sourceId, targetId }) => `${sourceId}-${targetId}`)
          .join('-')
        return <CorrelationScoreBatchLoader key={key} idPairs={idPairs} />
      })}
    </>
  )
}

export default BatchLoadScores
