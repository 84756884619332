import { Container, Flex, useBreakpointValue } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import Navbar from '@app/shared/layout/shells/components/navbar'
import Sidebar from '@app/shared/layout/shells/components/sidebar'
import GraphqlSubscriptionShell from '@app/shared/layout/shells/graphqlSubscriptionShell'

interface AppShellProps {
  children?: ReactNode
}

const AppShell = ({ children }: AppShellProps) => {
  const { user } = useStoreCurrentUser()
  const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false })

  const nav = isDesktop ? <Sidebar /> : <Navbar />

  return (
    <GraphqlSubscriptionShell strategyId={null}>
      <Flex as="section" direction={{ base: 'column', lg: 'row' }} overflow="hidden" h="100vh" bg="bg.muted">
        {user && nav}
        <Flex flex={1} overflowY="auto" id="main">
          <Container>{children || <Outlet />}</Container>
        </Flex>
      </Flex>
    </GraphqlSubscriptionShell>
  )
}

export default AppShell
