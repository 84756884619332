import { Button, ButtonGroup, IconButton, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import { useStore } from '@app/store'
import type { CardSize, MapDomainNode } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

interface Props {
  node: MapDomainNode
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

const SizeButton: FC<Props> = ({ node, isOpen, onOpen, onClose }) => {
  const { strategyId } = useParams()
  const updateNode = useStore.use.updateNode()
  const currentSize = node?.metadata?.size || 'normal'

  const { canEdit } = usePermissionsContext()

  if (!canEdit) {
    return null
  }

  const changeSize = (size: CardSize) => {
    updateNode(strategyId, node.id, { metadata: { ...node.metadata, size } })
  }

  const buttonParams = (size: CardSize) => ({
    isActive: currentSize === size,
    borderRadius: 'sm',
    fontSize: size === 'large' ? 'sm' : 'xs',
    onClick: () => (currentSize === size ? onClose() : changeSize(size))
  })

  return (
    <>
      {isOpen && (
        <ButtonGroup pos="absolute" top="-50px" right="5px" p={0} isAttached size="sm">
          <Button {...buttonParams('large')}>Large</Button>
          <Button {...buttonParams('normal')}>Normal</Button>
        </ButtonGroup>
      )}
      <Tooltip label="Card size">
        <IconButton
          borderRadius="none"
          aria-label="card size"
          icon={<ICON_MAP.CardSize />}
          isActive={isOpen}
          onClick={() => (isOpen ? onClose() : onOpen())}
        />
      </Tooltip>
    </>
  )
}

export default SizeButton
