import BasicCardEntitiesFetcher from '@app/fetchers/basicCardEntitiesFetcher'
import EntityEntitiesFetcher from '@app/fetchers/entityEntitiesFetcher'
import MetricEntitiesFetcher from '@app/fetchers/metricEntitiesFetcher'
import StrategyEntitiesFetcher from '@app/fetchers/strategyEntitiesFetcher'

const EntityContainmentsFetcher = ({ nodeId, nodeType, workflowState = null, children }) => {
  let Fetcher = null
  const fetcherParams = { id: nodeId, workflowState: workflowState || null, limit: 100 }

  switch (nodeType) {
    case 'metric':
      Fetcher = MetricEntitiesFetcher
      break
    case 'entity':
    case 'work':
      Fetcher = EntityEntitiesFetcher
      break
    case 'basicCard':
      Fetcher = BasicCardEntitiesFetcher
      break
    case 'strategy':
      Fetcher = StrategyEntitiesFetcher
      break
    default:
      throw new Error(`Unknown entity list for type: ${nodeType}`)
  }

  return <Fetcher {...fetcherParams}>{(collection, metadata) => children(collection, metadata)}</Fetcher>
}

export default EntityContainmentsFetcher
