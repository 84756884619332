import { Avatar, Divider, HStack, Icon, Stack, Spacer, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import useGetObject from '@app/hooks/useGetObject'
import CardMetricGoals from '@app/shared/cards/metric/cardMetricGoals'
import CardStats from '@app/shared/cards/metric/cardStats'
import MarkdownDisplay from '@app/shared/markdownDisplay'
import IconMap from '@app/utils/iconMap'
import profileImage from '@app/utils/profileImage'
import type { ReportStandardQuery } from '@graphql/queries'

type Props = {
  metric: ReportStandardQuery['report']['metrics'][0]
}

const Metric: FC<Props> = ({ metric }) => {
  const {
    description,
    name,
    ownerId,
    goals,
    strategyStats: metricStats,
    strategyNodeData: { strategyStats = [] }
  } = metric
  const owner = useGetObject(ownerId, 'user')

  const hasMetricStats = metricStats.some((stat) => stat.total || stat.change)
  const hasStrategyStats = strategyStats.some((stat) => stat.total || stat.change)

  const showStats = hasMetricStats || hasStrategyStats
  const goalIds = goals ? goals.map((goal) => goal.id) : []

  return (
    <Stack my={3}>
      <HStack>
        <Icon as={IconMap.Metric} boxSize={4} />
        <Text fontWeight="semibold">{name}</Text>
        <Spacer />
        {owner && (
          <>
            <Avatar name={owner.name} size="xs" src={profileImage(owner.email)} />
            <Text>{owner.name}</Text>
          </>
        )}
      </HStack>
      <Divider />
      {description && (
        <HStack my={2}>
          <Text as="span" alignSelf="start" fontWeight="bold">
            Description:
          </Text>
          <MarkdownDisplay as="span" text={description} />
        </HStack>
      )}
      <Stack>
        {goalIds.length > 0 && <CardMetricGoals metric={metric} goalIds={goalIds} />}
        {showStats && <CardStats metric={metric} />}
      </Stack>
    </Stack>
  )
}

export default Metric
