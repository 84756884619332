import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react'
import type { SuccessResponse, UppyFile, UppyOptions } from '@uppy/core'
import type { FC, FormEventHandler } from 'react'
import { useCallback, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { FiSend, FiUpload, FiX } from 'react-icons/fi'
import { useParams } from 'react-router-dom'
import type { OperationResult } from 'urql'

import useGetObject from '@app/hooks/useGetObject'
import { MAX_DOUBLELOOP_UPLOADER_FILE_SIZE } from '@app/lib/globals'
import ChatTextarea from '@app/pages/maps/components/aiChat/components/chatTextarea'
import DoubleloopUploaderModal from '@app/shared/uploader/doubleloopUploaderModal'
import type { PipelineRunMutation } from '@graphql/queries'
import { useChatCancelMutation, usePipelineRunMutation } from '@graphql/queries'
import type { PipelineEnum } from '@graphql/types'

type Props = {
  pipelineId: PipelineEnum
  chatId?: string | null
  strategyId?: string
  onSuccess?: (data: OperationResult<PipelineRunMutation>) => void
}

const MAX_FILES = 20 // OpenAI limit

const CancelButton = ({ chatId, ...rest }) => {
  const [, cancelChat] = useChatCancelMutation()

  const onCancel = useCallback(() => {
    if (chatId) {
      cancelChat({ input: { chatId } })
    }
  }, [chatId, cancelChat])

  return (
    <Button colorScheme="gray" onClick={onCancel} size="sm" type="button" variant="text" {...rest}>
      <FiX />
    </Button>
  )
}

const SubmitButton = ({ isDisabled }) => (
  <Button colorScheme="gray" isDisabled={isDisabled} size="sm" type="submit" variant="text">
    {isDisabled ? <Spinner /> : <FiSend />}
  </Button>
)

const PromptForm: FC<Props> = ({ pipelineId, strategyId: propStrategyId = null, chatId = null, onSuccess = null }) => {
  const [fileIds, setFileIds] = useState<string[]>([])
  const [, runPipeline] = usePipelineRunMutation()
  const [stateIsDisabled, setStateIsDisabled] = useState(false)
  const { strategyId: paramStrategyId } = useParams()
  const strategyId = propStrategyId || paramStrategyId
  const chat = useGetObject(chatId, 'chat')
  const isProcessing = chat?.processedState === 'processing' || chat?.processedState === 'waiting'
  const error = chat?.processedState === 'error'
  const borderColor = error ? 'error' : 'normal'
  const isDisabled = stateIsDisabled || isProcessing

  const maxRemainingFiles = MAX_FILES - fileIds.length

  const uppyOptions: UppyOptions = {
    restrictions: {
      maxFileSize: MAX_DOUBLELOOP_UPLOADER_FILE_SIZE,
      maxNumberOfFiles: maxRemainingFiles
    }
  }

  const inputRef = useHotkeys<HTMLTextAreaElement>(
    'mod+enter',
    (e) => {
      e.preventDefault()
      if (!isDisabled) {
        inputRef.current.form.requestSubmit()
      }
    },
    {
      enableOnFormTags: true
    }
  )

  const onUploadSuccess = useCallback((_file: UppyFile, response: SuccessResponse) => {
    setFileIds((prevFileIds) => [...prevFileIds, response.body.id])
  }, [])

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()
      const formEntries = new FormData(e.currentTarget)
      const prompt = formEntries.get('prompt') as string

      if (!prompt) {
        return Promise.resolve()
      }

      inputRef.current.value = ''

      setStateIsDisabled(true)

      return runPipeline({
        input: {
          prompt,
          fileIds,
          chattable: { strategy: { id: strategyId } },
          pipelineId,
          chatId
        }
      })
        .then((result) => {
          setFileIds([])
          onSuccess?.(result)

          return result
        })
        .finally(() => {
          setStateIsDisabled(false)
        })
    },
    [fileIds, runPipeline, strategyId, chatId, onSuccess]
  )

  return (
    <Box as="form" pb="1" onSubmit={onSubmit}>
      <Box pos="relative">
        <ChatTextarea ref={inputRef} borderColor={borderColor} isRequired isDisabled={isDisabled} />
        <Flex pos="absolute" zIndex="2" top="3" right="0">
          {isProcessing && <CancelButton chatId={chat.id} px={0} />}
          <SubmitButton isDisabled={isDisabled} />
        </Flex>
        <Box pos="absolute" zIndex="2" right="0" bottom="3">
          <DoubleloopUploaderModal onUploadSuccess={onUploadSuccess} uppyOptions={uppyOptions}>
            <Button
              colorScheme="gray"
              isDisabled={isDisabled || maxRemainingFiles === 0}
              size="sm"
              type="button"
              variant="text"
            >
              <FiUpload /> {fileIds.length ? `(${fileIds.length})` : ''}
            </Button>
          </DoubleloopUploaderModal>
        </Box>
      </Box>
      {chat?.errorMessage && (
        <Text mt="1" color="error" fontSize="xs">
          {chat.errorMessage}
        </Text>
      )}
    </Box>
  )
}

export default PromptForm
