import { Box, IconButton, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import ICON_MAP from '@app/utils/iconMap'

interface Props {
  commentsCount: number
}

const CollapsedCommentsCount: FC<Props> = ({ commentsCount }) => {
  let count = `${commentsCount}`
  let right = '17px'

  if (!commentsCount || commentsCount === 0) {
    return null
  }

  if (commentsCount > 9) {
    count = '9+'
    right = '14px'
  }

  return (
    <Box pos="relative">
      <IconButton aria-label="Comments" colorScheme="gray" icon={<ICON_MAP.Comments />} size="md" variant="ghost" />
      <Text pos="absolute" top="13px" right={right} fontSize="2xs">
        {count}
      </Text>
    </Box>
  )
}

export default CollapsedCommentsCount
