import { Divider, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { NavLink } from 'react-router-dom'

import ReportPeriod from './reportPeriod'

import type { Report } from '@graphql/types'
import { ReportStateEnum } from '@graphql/types'

type CReport = Pick<Report, 'id' | 'name' | 'reportState' | 'startDate' | 'endDate'>

type ListItemProps = {
  report: CReport
}

type ListProps = {
  reports: CReport[]
}

const DrawerListItem: FC<ListItemProps> = ({ report }) => (
  <Stack
    as={NavLink}
    px={4}
    py={3}
    _hover={{ bgColor: 'bg.subtle' }}
    _activeLink={{ bgColor: 'bg.muted' }}
    spacing={1}
    to={report.id}
  >
    <Text variant="section-header">{report.name}</Text>
    <ReportPeriod report={report} />
  </Stack>
)

const DrawerList: FC<ListProps> = ({ reports }) => {
  const draftReports = reports.filter((report) => report.reportState === ReportStateEnum.Draft)
  const publishedReports = reports.filter((report) => report.reportState === ReportStateEnum.Published)

  return (
    <Stack overflowY="auto">
      {publishedReports.length > 0 && (
        <Stack>
          <Text align="center" fontWeight="semibold">
            Published
          </Text>
          <Divider />
          {publishedReports.map((report) => (
            <DrawerListItem key={`report-list-item-${report.id}`} report={report} />
          ))}
        </Stack>
      )}
      {draftReports.length > 0 && (
        <Stack>
          <Text align="center" px={4} color="red.700" fontWeight="semibold">
            Draft
          </Text>
          <Divider />
          {draftReports.map((report) => (
            <DrawerListItem key={`report-list-item-${report.id}`} report={report} />
          ))}
        </Stack>
      )}
    </Stack>
  )
}

export default DrawerList
