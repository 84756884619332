import { HStack, IconButton, Spacer } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiMail, FiTrash2 } from 'react-icons/fi'
import { useRevalidator } from 'react-router-dom'

import Can from '@app/shared/authorization/can'
import useToast from '@app/shared/toast'
import type { Invitation } from '@graphql/queries'
import { useInvitationDestroyMutation, useInvitationResendMutation } from '@graphql/queries'

interface Props {
  invitation: Invitation
}

const InvitationsListActions: FC<Props> = ({ invitation }) => {
  const { revalidate } = useRevalidator()
  const toast = useToast()

  const [, invitationDestroy] = useInvitationDestroyMutation()
  const [, invitationResend] = useInvitationResendMutation()

  const destroyInvitationHandler = async () => {
    await invitationDestroy({
      input: { invitationId: invitation.id }
    })

    revalidate()
  }

  const resendInvitationHandler = async () => {
    await invitationResend({
      input: { invitationId: invitation.id }
    })

    toast({
      title: 'Invitation resent',
      description: 'We shot off another invite email, they should have it soon.',
      status: 'success',
      duration: 5000,
      isClosable: true
    })

    revalidate()
  }

  return (
    <Can I="update" a="member">
      <HStack spacing={0}>
        <Spacer />
        <IconButton aria-label="Resend invite" icon={<FiMail />} onClick={resendInvitationHandler} variant="ghost" />
        <IconButton
          color="error"
          aria-label="Rescind invite"
          icon={<FiTrash2 />}
          onClick={destroyInvitationHandler}
          variant="ghost"
        />
      </HStack>
    </Can>
  )
}

export default InvitationsListActions
