import isEmpty from 'lodash/isEmpty'

import type { SortDir } from '@app/types'

const extractOrderings = (searchParams: URLSearchParams, fields: string | string[]): Record<string, SortDir> => {
  const list = Array.isArray(fields) ? fields : [fields]

  const result = list.reduce((acc, field) => {
    const sp = searchParams.get(`order.${field}`)

    if (sp) {
      acc[field] = sp
    }

    return acc
  }, {})

  return isEmpty(result) ? null : result
}

export default extractOrderings
