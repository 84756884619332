import { subject } from '@casl/ability'
import { Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useCallback } from 'react'

import Can from '@app/shared/authorization/can'
import { SelectInput } from '@app/shared/rawForms'
import useToast from '@app/shared/toast'
import { RoleEnum, useMemberAdministrateMutation } from '@graphql/queries'

interface Props {
  data: {
    id: string
    role: RoleEnum
    disabledAt: string
  }
}

const RoleCell: FC<Props> = ({ data: member }) => {
  const { id, role, disabledAt } = member
  const toast = useToast()
  const [, updateRole] = useMemberAdministrateMutation()

  const onChange = useCallback(
    async (e) => {
      await updateRole({
        input: { userId: id, role: e.target.value }
      })
      toast({ title: 'Updated member' })
    },
    [id, toast]
  )

  if (disabledAt) {
    return <Text color={disabledAt ? 'gray.400' : 'gray.700'}>{role}</Text>
  }

  return (
    <Can I="update" this={subject('member', member)} passThrough>
      {(allowed) => (
        <SelectInput defaultValue={role} onChange={onChange} isDisabled={!allowed} name="role" placeholder="">
          <option value={RoleEnum.Viewer}>Viewer</option>
          <option value={RoleEnum.Editor}>Editor</option>
        </SelectInput>
      )}
    </Can>
  )
}

export default RoleCell
