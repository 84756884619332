import { getIncomers, getOutgoers, type Node } from '@xyflow/react'
import capitalize from 'lodash/capitalize'

import type { MapDomainEdge, MapDomainNode } from '@app/types'

type IdAndTypeToRfId = (nodeId: string, nodeType: string) => string
export const idAndTypeToRfId: IdAndTypeToRfId = (nodeId, nodeType) => {
  const nodePrefix = nodeType === 'basicCard' ? 'basicCard' : nodeType[0]

  return `${nodePrefix}${nodeId}`
}

export const scaleSizeToMapZoom = (baseSize: number, zoom: number, maxSize: number | null = null) => {
  let size = baseSize / zoom
  if (maxSize && size > maxSize) {
    size = maxSize
  }

  return `${size}px`
}

export const getNodePositionInsideParent = (
  node: Partial<MapDomainNode>,
  groupNode: Pick<MapDomainNode, 'position'>
) => {
  const positionAbsolute = { x: node?.computed?.positionAbsolute?.x || 0, y: node?.computed?.positionAbsolute.y || 0 }

  const x = positionAbsolute.x - groupNode.position.x
  const y = positionAbsolute.y - groupNode.position.y

  return { x, y }
}

type AttachNodeToParent = <T extends Partial<MapDomainNode> = Partial<MapDomainNode>>(
  node: T,
  parent: Pick<MapDomainNode, 'id' | 'nodeId' | 'position'>
) => T & Pick<MapDomainNode, 'parentNode' | 'position'> & { parentNodeId: string | null }
export const attachNodeToParent: AttachNodeToParent = (node, parent) => ({
  ...node,
  position: getNodePositionInsideParent(node, parent),
  parentNodeId: parent.nodeId,
  parentNode: parent.id
})

export const detachNodeFromParent = (
  node: MapDomainNode,
  parentNode: MapDomainNode
): MapDomainNode & { parentNodeId?: string } => {
  const absolute = parentNode?.computed?.positionAbsolute
  let offsets

  if (absolute) {
    offsets = { x: absolute.x, y: absolute.y }
  } else {
    offsets = { x: parentNode?.position?.x, y: parentNode?.position?.y }
  }

  return {
    ...node,
    position: {
      x: node.position.x + (offsets.x ?? 0),
      y: node.position.y + (offsets.y ?? 0)
    },
    parentNodeId: null,
    parentNode: null
  }
}

export const getAllIncomers = (
  node: MapDomainNode,
  nodes: MapDomainNode[],
  edges: MapDomainEdge[],
  prevIncomerIds: string[] = []
): MapDomainNode[] => {
  const incomers = getIncomers(node, nodes, edges) as MapDomainNode[]

  return incomers.reduce((memo, incomer) => {
    memo.push(incomer)

    if (!prevIncomerIds.includes(incomer.id)) {
      prevIncomerIds.push(incomer.id)

      getAllIncomers(incomer, nodes, edges, prevIncomerIds).forEach((foundNode) => {
        if (!memo.map((i) => i.id).includes(foundNode.id)) {
          memo.push(foundNode)
        }

        if (!prevIncomerIds.includes(foundNode.id)) {
          prevIncomerIds.push(foundNode.id)
        }
      })
    }

    return memo
  }, [])
}

export const getAllOutgoers = (
  node: Node,
  nodes: MapDomainNode[],
  edges: MapDomainEdge[],
  prevOutgoerIds: string[] = []
): MapDomainNode[] => {
  const outgoers = getOutgoers(node, nodes, edges)

  return outgoers.reduce((memo, outgoer) => {
    memo.push(outgoer)

    if (!prevOutgoerIds.includes(outgoer.id)) {
      prevOutgoerIds.push(outgoer.id)

      getAllOutgoers(outgoer, nodes, edges, prevOutgoerIds).forEach((foundNode) => {
        if (!memo.map((i) => i.id).includes(foundNode.id)) {
          memo.push(foundNode)
        }

        if (!prevOutgoerIds.includes(foundNode.id)) {
          prevOutgoerIds.push(foundNode.id)
        }
      })
    }

    return memo
  }, [])
}

export const getNodeChildren = (node: Node, nodes: MapDomainNode[]) => {
  if (node.type !== 'section') {
    return []
  }

  return nodes.filter((n) => n.parentNode === node.id)
}

export const displayNameAndType = (domainObject) => {
  let name
  let type = capitalize(domainObject.classType)
  switch (domainObject.classType) {
    case 'basicCard':
      name = domainObject?.name
      type = domainObject?.cardType?.name || null
      break
    case 'entity':
      name = domainObject?.name
      type = 'work'
      break
    default:
      name = domainObject?.name || `Unnamed ${type}`
  }
  return { name, type }
}

export const hasLoop = (node: MapDomainNode, nodes: MapDomainNode[], edges: MapDomainEdge[]) => {
  const incomers = getAllIncomers(node, nodes, edges)
  const outgoers = getAllOutgoers(node, nodes, edges)

  return incomers.some((incomer) => outgoers.some((outgoer) => incomer.id === outgoer.id))
}
