import type { EdgeProps } from '@xyflow/react'
import { getBezierPath } from '@xyflow/react'
import type { FC } from 'react'
import { memo } from 'react'
import { useParams } from 'react-router-dom'

import CorrelationLabel from './components/correlationLabel'
import InsightLabel from './components/insightLabel'
import StyledPath from './components/styledPath'

import useGetObject from '@app/hooks/useGetObject'
import { useStore } from '@app/store'
import type { MapDomainEdge, MapDomainNode } from '@app/types'
import { correlationScoreColor, COLORS } from '@app/utils/metricHelpers'

interface Props extends EdgeProps {}

// THIS COPY IS KEPT HERE SINCE ITS NODE SPECIFIC, THE EDGE NEEDS TO BE UPDATED
// AWAY TO TAKE THE DOMAIN OBJECT DIRECTLY. MOST LIKELY WITH THE ELIMINATION OF
// getNodeById
export const shouldFlipColors = (source: MapDomainNode, target: MapDomainNode) => {
  let flipColors = false
  if (source?.data?.positiveDirection !== target?.data?.positiveDirection) {
    flipColors = true
  }
  return flipColors
}

const displayLabel = (
  edge: MapDomainEdge,
  source: MapDomainNode,
  target: MapDomainNode,
  showCorrelations: boolean,
  showAiInsights: boolean
) => {
  if (showAiInsights && edge?.insights) {
    const color = edge?.color || 'gray'

    return {
      displayType: 'insight',
      color,
      selectedColor: color,
      label: edge.insights
    }
  }

  const flipColors = shouldFlipColors(source, target)
  const { color, selectedColor } = correlationScoreColor(edge.correlationScore, flipColors)

  if (showCorrelations && edge?.correlationScore) {
    return {
      displayType: 'correlation',
      color,
      selectedColor,
      label: edge.correlationScore
    }
  }

  return {
    displayType: null,
    color: COLORS.neutral.color,
    selectedColor: COLORS.neutral.selectedColor,
    label: null
  }
}

const aiInsightsOffHide = (showAiInsights: boolean, source: MapDomainNode, target: MapDomainNode) =>
  !(!showAiInsights && (source?.data?.aiGenerated || target?.data?.aiGenerated))

const Edge: FC<Props> = ({
  id,
  markerEnd,
  selected,
  style,
  sourcePosition,
  sourceX,
  sourceY,
  targetPosition,
  targetX,
  targetY,
  labelStyle,
  labelShowBg,
  labelBgStyle,
  labelBgPadding,
  labelBgBorderRadius
}) => {
  const { strategyId } = useParams()
  const strategy = useGetObject(strategyId, 'strategy')
  const { showCorrelations, showAiInsights } = strategy

  const getNodeById = useStore.use.getNodeById()
  const edge = useGetObject(id, 'edge')
  const source = getNodeById(edge?.source)
  const target = getNodeById(edge?.target)

  const hideAiNodes = !aiInsightsOffHide(showAiInsights, source, target)

  // Do not render if either side is hidden. This kills the Phantom Edge bug
  if (!edge || source?.hidden || target?.hidden || hideAiNodes) {
    return null
  }

  const { sourceId, targetId } = edge || {}

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  })

  const { displayType, color, selectedColor, label } = displayLabel(
    edge,
    source,
    target,
    showCorrelations,
    showAiInsights
  )

  return (
    <>
      <StyledPath
        path={edgePath}
        isSelected={selected}
        style={style}
        color={color}
        selectedColor={selectedColor}
        markerEnd={markerEnd}
      />
      {displayType === 'correlation' && (
        <CorrelationLabel
          correlationScore={label}
          style={style}
          x={labelX}
          y={labelY}
          labelStyle={labelStyle}
          labelShowBg={labelShowBg}
          labelBgStyle={labelBgStyle}
          labelBgPadding={labelBgPadding}
          labelBgBorderRadius={labelBgBorderRadius}
          color={color}
          sourceId={sourceId}
          targetId={targetId}
        />
      )}
      {displayType === 'insight' && (
        <InsightLabel
          insights={label}
          x={labelX}
          y={labelY}
          labelStyle={labelStyle}
          labelShowBg={labelShowBg}
          labelBgPadding={labelBgPadding}
          labelBgBorderRadius={labelBgBorderRadius}
          color={color}
        />
      )}
    </>
  )
}

export default memo(Edge)
