import { Button, HStack, Stack, Stat, StatLabel, StatNumber, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Graph from './components/graph'
import IntervalSelect from './components/intervalSelect'
import LagInput from './components/lagInput'
import RangeSelect from './components/rangeSelect'

import CorrelationScoreDescription from '@app/pages/correlations/components/correlationScoreDescription'
import { ButtonRow } from '@app/shared/forms'
import { Card, PageHeader, PageStack } from '@app/shared/layout'
import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { correlationScoreColor } from '@app/utils/metricHelpers'
import type { CorrelationPairUpdateInput } from '@graphql/queries'
import { useCorrelationPairQuery, useCorrelationPairUpdateMutation } from '@graphql/queries'
import { IntervalEnum } from '@graphql/types'

const Show: FC = () => {
  const { sourceId, targetId } = useParams()

  const [interval, setInterval] = useState(IntervalEnum.Week)
  const [range, setRange] = useState('all')
  const [startDate, setStartDate] = useState<string | null>(null)
  const [endDate, setEndDate] = useState<string | null>(null)
  const [laggedId, setLaggedId] = useState('')
  const [lagDays, setLagDays] = useState<string | number>(0)
  const disabled = Number(lagDays) !== 0 && !laggedId

  const [{ data }] = useCorrelationPairQuery({
    variables: {
      sourceId,
      targetId
    }
  })

  const [, correlationPairUpdate] = useCorrelationPairUpdateMutation()

  // When the correlate data comes back, update the laggedId and lagDays
  useEffect(() => {
    if (!data?.organization?.correlate) {
      return
    }

    const {
      sourceLagDays,
      targetLagDays,
      range: storedRange,
      startDate: storedStartDate,
      endDate: storedEndDate
    } = data.organization.correlate

    setRange(storedRange)
    setStartDate(storedStartDate)
    setEndDate(storedEndDate)

    if (sourceLagDays !== 0) {
      setLaggedId(sourceId)
      setLagDays(Number(sourceLagDays))
    } else if (targetLagDays !== 0) {
      setLaggedId(targetId)
      setLagDays(Number(targetLagDays))
    }
  }, [data?.organization?.correlate, sourceId, targetId])

  if (!data?.organization?.correlate) {
    return null
  }

  const {
    sourceLagDays,
    targetLagDays,
    range: storedRange,
    startDate: storedStartDate,
    endDate: storedEndDate,
    score,
    source,
    target
  } = data.organization.correlate
  const formattedScore = Intl.NumberFormat(undefined).format(score)
  const flipColors = source.positiveDirection !== target.positiveDirection
  const { color } = correlationScoreColor(score, flipColors)

  const onSubmit = () => {
    let input: CorrelationPairUpdateInput = { sourceId, targetId, range, startDate, endDate }

    if (laggedId === sourceId) {
      input = { ...input, sourceLagDays: Number(lagDays), targetLagDays: 0 }
    } else if (laggedId === targetId) {
      input = { ...input, sourceLagDays: 0, targetLagDays: Number(lagDays) }
    }

    return correlationPairUpdate({ input })
  }

  return (
    <PageStack>
      <PageHeader title="Correlation" subtitle={`${source.name} & ${target.name}`} />
      <Card isStack>
        <HStack>
          <Stat>
            <StatLabel>Correlation Score</StatLabel>
            <StatNumber color={color}>{formattedScore}</StatNumber>
          </Stat>
          <CorrelationScoreDescription spacing={0} />
        </HStack>
        <Stack spacing={8}>
          <HStack>
            <RangeSelect
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setRange={setRange}
              range={range}
              setEndDate={setEndDate}
            />
            <LagInput
              source={source}
              target={target}
              lagDays={lagDays}
              setLagDays={setLagDays}
              laggedId={laggedId}
              setLaggedId={setLaggedId}
            />
          </HStack>
          <ButtonRow>
            <Tooltip
              isOpen={disabled ? undefined : false}
              label="To set a lag, you must pick a lagging metric."
              shouldWrapChildren
            >
              <Button isDisabled={disabled} onClick={onSubmit} variant="primary">
                Update score
              </Button>
            </Tooltip>
          </ButtonRow>
        </Stack>
      </Card>
      <Card isStack>
        <IntervalSelect interval={interval} setInterval={setInterval} />
        <Graph
          sourceId={sourceId}
          targetId={targetId}
          sourceLagDays={sourceLagDays}
          targetLagDays={targetLagDays}
          range={storedRange}
          interval={interval}
          startDate={storedStartDate}
          endDate={storedEndDate}
        />
      </Card>
    </PageStack>
  )
}

export default withSuspenseWrapper(Show)
