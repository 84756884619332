import { subject } from '@casl/ability'
import { HStack, IconButton, Spacer, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiTrash2, FiUserPlus } from 'react-icons/fi'
import { useRevalidator } from 'react-router-dom'

import MemberDisableButton from '@app/pages/settings/organization/components/memberDisableButton'
import Can from '@app/shared/authorization/can'
import type { User } from '@graphql/queries'
import { useMemberAdministrateMutation } from '@graphql/queries'

interface Props {
  member: User
}

const TeamMembersRowMenu: FC<Props> = ({ member }) => {
  const { revalidate } = useRevalidator()
  const [, administrateUser] = useMemberAdministrateMutation()

  const toggleAccess = async (disabled) => {
    await administrateUser({
      input: {
        userId: member.id,
        disabled
      }
    })

    revalidate()
  }

  return (
    <Can I="delete" this={subject('member', member)}>
      <HStack spacing={0}>
        <Spacer />
        {member.disabledAt ? (
          <Tooltip label="Enable access">
            <IconButton
              color="gray.500"
              aria-label="Enable access"
              icon={<FiUserPlus />}
              onClick={() => toggleAccess(false)}
              variant="ghost"
            />
          </Tooltip>
        ) : (
          <Tooltip hasArrow label="Disable access">
            <span>
              <MemberDisableButton userId={member.id}>
                <IconButton color="error" aria-label="Disable access" icon={<FiTrash2 />} variant="ghost" />
              </MemberDisableButton>
            </span>
          </Tooltip>
        )}
      </HStack>
    </Can>
  )
}

export default TeamMembersRowMenu
