import type { TextProps } from '@chakra-ui/react'
import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  useDisclosure
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef } from 'react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import CardStatusTag from '@app/shared/cards/components/cardStatusTag'
import { SearchList, SearchListItem } from '@app/shared/searchList'
import { useStore } from '@app/store'
import type { CardSize } from '@app/types'
import type { BasicCard, NodeObjectInput } from '@graphql/types'
import { DomainObjectStatusEnum } from '@graphql/types'

type DomainObject<T extends BasicCard> = Pick<T, 'id' | 'classType'> & Partial<Pick<T, 'status'>>

interface Props extends TextProps {
  domainObject: DomainObject<BasicCard>
  size?: CardSize
}

const DomainObjectStatusPopover: FC<Props> = ({ domainObject, size = 'normal' }) => {
  const { user } = useStoreCurrentUser()
  const initialFocusRef = useRef()
  const { isOpen, onToggle, onClose } = useDisclosure()
  const updateObject = useStore.use.updateObject()
  const { id, classType, status } = domainObject

  if (user?.role !== 'editor') {
    return <CardStatusTag status={status} size={size} />
  }

  const handleItemClick = (value: string) => {
    updateObject({ [classType]: { id, status: value as DomainObjectStatusEnum } } as NodeObjectInput)

    onToggle()
  }

  return (
    <Popover initialFocusRef={initialFocusRef} isLazy isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Box cursor="pointer" onClick={onToggle}>
          <CardStatusTag status={status} showChevron size={size} />
        </Box>
      </PopoverTrigger>
      <PopoverContent maxW={36}>
        <PopoverArrow />
        <PopoverBody m={0} p={0}>
          <SearchList
            searchField="status"
            currentValue={status}
            initialFocusRef={initialFocusRef}
            onChange={handleItemClick}
          >
            <SearchListItem text="Idea" value={DomainObjectStatusEnum.Idea} />
            <SearchListItem text="Next" value={DomainObjectStatusEnum.Next} />
            <SearchListItem text="Active" value={DomainObjectStatusEnum.Active} />
            <SearchListItem text="Learning review" value={DomainObjectStatusEnum.LearningReview} />
            <SearchListItem text="Done" value={DomainObjectStatusEnum.Done} />
          </SearchList>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default DomainObjectStatusPopover
