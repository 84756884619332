import {
  ButtonGroup,
  Divider,
  IconButton,
  Tooltip,
  Button,
  FormLabel,
  Input,
  Icon,
  useDisclosure
} from '@chakra-ui/react'
import type { FC } from 'react'
import { memo, useCallback, useMemo, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import useGetObjects from '@app/hooks/useGetObjects'
import useMapHotkeys from '@app/hooks/useMapHotkeys'
import useMapNodeCreators from '@app/hooks/useMapNodeCreators'
import useMousePosition from '@app/hooks/useMousePosition'
import BasicTypeModal from '@app/pages/maps/components/nodes/components/basicType/basicTypeModal'
import { iconMap as basicCardIconMap } from '@app/pages/maps/components/nodes/components/basicType/customIcons'
import KeyboardShortcutsTooltip from '@app/shared/keyboardShortcutsTooltip'
import ICON_MAP from '@app/utils/iconMap'

const buttonStyles = {
  fontSize: 'xl',
  borderRadius: 0
}

const tooltipPlacement = 'right'

const sectionLabel = <KeyboardShortcutsTooltip title="Section" shortcut="S" />
const noteLabel = <KeyboardShortcutsTooltip title="Note" shortcut="N" />
const imageLabel = <KeyboardShortcutsTooltip title="Image" shortcut="I" />
const commentLabel = <KeyboardShortcutsTooltip title="Comment" shortcut="C" />

const AddCardMenu: FC = () => {
  const { strategyId } = useParams()
  const navigate = useNavigate()

  const imageInputRef = useRef<HTMLInputElement>(null)
  const { createCommentThread, createNote, createSection, createImage } = useMapNodeCreators(strategyId)
  useMapHotkeys(strategyId, imageInputRef)
  const getLatestMousePosition = useMousePosition()

  const cardTypes = useGetObjects('cardType')
  const sortedCardTypes = useMemo(() => cardTypes.sort((a, b) => a.name.localeCompare(b.name)), [cardTypes])
  const shownCardTypes = useMemo(() => sortedCardTypes.filter((ct) => ct.showInMenu), [sortedCardTypes])
  const basicTypeModalDisclosure = useDisclosure()

  document.onpaste = useCallback((evt) => {
    const dataTransferred = evt.clipboardData
    const mousePosition = getLatestMousePosition()

    if (dataTransferred.files.length > 0) {
      createImage(dataTransferred.files[0], mousePosition)
    }
  }, [])

  const openExistingObjectDrawer = useCallback((type) => {
    navigate(`add/${type}`)
  }, [])

  return (
    <>
      <ButtonGroup borderRadius={0} colorScheme="gray" orientation="vertical" size="md" spacing={0} variant="ghost">
        <Tooltip hasArrow label={<KeyboardShortcutsTooltip title="Metric" shortcut="M" />} placement={tooltipPlacement}>
          <IconButton
            {...buttonStyles}
            aria-label="metric"
            icon={<ICON_MAP.Metric />}
            onClick={() => openExistingObjectDrawer('metric')}
          />
        </Tooltip>

        <Tooltip hasArrow label={<KeyboardShortcutsTooltip title="Work" shortcut="W" />} placement={tooltipPlacement}>
          <IconButton
            {...buttonStyles}
            borderRadius={0}
            aria-label="work"
            icon={<ICON_MAP.Work />}
            onClick={() => openExistingObjectDrawer('entity')}
          />
        </Tooltip>
        <Tooltip hasArrow label="Submap" placement={tooltipPlacement}>
          <IconButton
            {...buttonStyles}
            aria-label="map"
            icon={<ICON_MAP.StrategyMap />}
            onClick={() => openExistingObjectDrawer('strategy')}
          />
        </Tooltip>
        <Tooltip hasArrow label="Playbook" placement={tooltipPlacement}>
          <IconButton
            {...buttonStyles}
            aria-label="playbook"
            icon={<ICON_MAP.Playbook />}
            onClick={() => openExistingObjectDrawer('playbook')}
          />
        </Tooltip>
        {shownCardTypes.length > 0 && <Divider />}
        {shownCardTypes?.map((type) => (
          <Tooltip key={type.id} hasArrow label={type.name} placement={tooltipPlacement}>
            <IconButton
              {...buttonStyles}
              aria-label={type.name}
              icon={<Icon as={basicCardIconMap[type.icon] || ICON_MAP.BasicCard} />}
              onClick={() => openExistingObjectDrawer(`basicCard/${type.id}`)}
            />
          </Tooltip>
        ))}

        <Divider />

        <Tooltip hasArrow label={sectionLabel} placement={tooltipPlacement}>
          <IconButton {...buttonStyles} aria-label="section" icon={<ICON_MAP.Section />} onClick={createSection} />
        </Tooltip>

        <Tooltip hasArrow label={noteLabel} placement={tooltipPlacement}>
          <IconButton {...buttonStyles} aria-label="note" icon={<ICON_MAP.Note />} onClick={createNote} />
        </Tooltip>

        <Tooltip hasArrow label={imageLabel} placement={tooltipPlacement}>
          <Button w={11} borderRadius={0} aria-label="image">
            <FormLabel m={0} p={0} cursor="pointer" htmlFor="image-upload">
              <Icon as={ICON_MAP.Image} mx={3} {...buttonStyles} aria-label="image" style={{ pointerEvents: 'none' }} />
            </FormLabel>
            <Input
              ref={imageInputRef}
              accept="image/*"
              id="image-upload"
              onChange={(e) => createImage(e.target.files[0])}
              style={{
                position: 'absolute',
                display: 'none'
              }}
              type="file"
            />
          </Button>
        </Tooltip>

        <Tooltip hasArrow label={commentLabel} placement={tooltipPlacement}>
          <IconButton
            {...buttonStyles}
            aria-label="comment"
            icon={<ICON_MAP.Comments />}
            onClick={createCommentThread}
          />
        </Tooltip>

        <Divider />

        <Tooltip hasArrow label="Manage card types" placement={tooltipPlacement}>
          <IconButton
            {...buttonStyles}
            aria-label="manage card types"
            icon={<ICON_MAP.Ellipsis />}
            onClick={basicTypeModalDisclosure.onOpen}
          />
        </Tooltip>
      </ButtonGroup>
      <BasicTypeModal cardTypes={cardTypes} {...basicTypeModalDisclosure} />
    </>
  )
}

AddCardMenu.displayName = 'AddCardMenu'

export default memo(AddCardMenu)
