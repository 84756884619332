import { Stack, VisuallyHiddenInput } from '@chakra-ui/react'
import type { FC } from 'react'
// import type { ChangeEvent, FC } from 'react'

import { PasswordInput, TextInput } from '@app/shared/rawForms'
import type { Errors } from '@app/shared/rawForms/useForm'
import type { Credential } from '@graphql/types'

type Props = {
  credential?: Pick<Credential, 'clientId' | 'host'>
}

const LookerApiAuth: FC<Props> = ({ credential = {} }) => {
  const errors: Errors = {}
  const { clientId, host } = credential

  // const onInvalid = (event: ChangeEvent<HTMLInputElement>) => {
  //   event.target.setCustomValidity('Please enter your Looker URL, e.g. https://mycompany.cloud.looker.com/api/4.0')
  // }

  return (
    <Stack>
      <TextInput
        name="clientId"
        label="Client ID"
        placeholder="Looker client ID"
        autoComplete="off"
        errors={errors}
        defaultValue={clientId}
        isRequired
      />
      <PasswordInput
        name="clientSecret"
        label="Client secret"
        placeholder="Looker client secret"
        autoComplete="off"
        errors={errors}
        helperText={credential ? 'Leave blank to keep the current secret or fill in to set a new one' : null}
        isRequired={!credential}
      />
      <TextInput
        autoComplete="off"
        label="API endpoint"
        defaultValue={host}
        isRequired
        name="host"
        // onInvalid={onInvalid}
        pattern="https://.*looker.*"
        placeholder="https://mycompany.cloud.looker.com/api/4.0"
      />
      {Object.keys(credential || {}).length && <VisuallyHiddenInput defaultValue="clientSecret" name="secretFields" />}
    </Stack>
  )
}

export default LookerApiAuth
