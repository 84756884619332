import { useMemo } from 'react'

import AddCardDrawer from './addCardDrawer'

import useCookie from '@app/hooks/useCookie'
import { METRIC_CARD_TYPE_COOKIE } from '@app/lib/globals'

const AddMetricCardDrawer = () => {
  const [strategyType] = useCookie(METRIC_CARD_TYPE_COOKIE)

  const mutationVariables = useMemo(
    () => ({
      strategyType
    }),
    [strategyType]
  )

  return (
    <AddCardDrawer
      cardType="metric"
      headerText="Metric Cards"
      buttonText="New Metric card"
      mutationVariables={mutationVariables}
    />
  )
}

export default AddMetricCardDrawer
