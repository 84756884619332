import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  useClipboard
} from '@chakra-ui/react'
import type { FC } from 'react'
import { FiClipboard } from 'react-icons/fi'

import useToast from '@app/shared/toast'
import type { Strategy } from '@graphql/queries'
import { SharingTypesEnum } from '@graphql/queries'

interface Props {
  strategy: Pick<Strategy, 'id' | 'token'>
  sharing: SharingTypesEnum
}

const StrategyFormUrl: FC<Props> = ({ strategy = null, sharing = SharingTypesEnum.PrivateAccess }) => {
  const toast = useToast()

  let url = ''

  if (strategy?.id) {
    const strategyToken = sharing === SharingTypesEnum.TokenAccess ? strategy.token : null
    const shareUrl = `${window.location.origin}/strategy/${strategy.id}/map`

    url = strategyToken ? `${shareUrl}?token=${strategyToken}` : shareUrl
  }

  const { onCopy } = useClipboard(url)

  if (!strategy) {
    return null
  }

  const copyToClipboard = () => {
    onCopy()

    toast({
      title: 'URL Copied!',
      description: 'The share url has been copied to your clipboard.',
      status: 'success',
      duration: 9000,
      isClosable: true
    })
  }

  return (
    <FormControl>
      <FormLabel fontSize="md">
        <>
          Access link -{' '}
          <Link color="link" fontSize="xs" wordBreak="break-all" href={url} isExternal>
            Open in new tab
          </Link>
        </>
      </FormLabel>
      <InputGroup size="md">
        <Input pr="6.5rem" isReadOnly value={url} />
        <InputRightElement w="6.5rem">
          <Button
            // h="1.75rem"
            aria-label="Copy share url"
            leftIcon={<FiClipboard />}
            onClick={copyToClipboard}
            size="2xs"
            variant="primary"
          >
            Copy URL
          </Button>
        </InputRightElement>
      </InputGroup>
    </FormControl>
  )
}

export default StrategyFormUrl
