import { Menu, MenuButton, MenuList, Portal } from '@chakra-ui/react'
import type { FC } from 'react'

import RunReportSummarizer from '@app/pages/reports/components/aiAssistant/runReportSummarizer'
import type { MapDomainReport } from '@app/types'

interface Props {
  report: MapDomainReport
}

const ReportPipelines: FC<Props> = ({ report }) => (
  <Menu>
    <MenuButton
      onClick={(e) => {
        e.stopPropagation()
      }}
      type="button"
    >
      Run Pipeline
    </MenuButton>
    <Portal>
      <MenuList>
        <RunReportSummarizer report={report} />
      </MenuList>
    </Portal>
  </Menu>
)

export default ReportPipelines
