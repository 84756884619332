import { ButtonGroup, Divider, IconButton, Tooltip, Icon, useDisclosure } from '@chakra-ui/react'
import type { FC } from 'react'
import { useMemo, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import useGetObjects from '@app/hooks/useGetObjects'
import useMapHotkeys from '@app/hooks/useMapHotkeys'
import BasicTypeModal from '@app/pages/maps/components/nodes/components/basicType/basicTypeModal'
import { iconMap as basicCardIconMap } from '@app/pages/maps/components/nodes/components/basicType/customIcons'
import KeyboardShortcutsTooltip from '@app/shared/keyboardShortcutsTooltip'
import ICON_MAP from '@app/utils/iconMap'

const AddRoadmapItemMenu: FC = () => {
  const { strategyId } = useParams()
  const navigate = useNavigate()

  const imageInputRef = useRef<HTMLInputElement>(null)
  useMapHotkeys(strategyId, imageInputRef)

  const cardTypes = useGetObjects('cardType')
  const sortedCardTypes = useMemo(() => cardTypes.sort((a, b) => a.name.localeCompare(b.name)), [cardTypes])
  const shownCardTypes = sortedCardTypes.filter((ct) => ct.showInMenu)
  const basicTypeModalDisclosure = useDisclosure()

  const openExistingObjectDrawer = (type) => {
    navigate(`add/${type}`)
  }

  const tooltipPlacement = 'bottom'
  const fontSize = 'xl'

  return (
    <>
      <ButtonGroup
        borderRadius="md"
        bgColor="bg.surface"
        colorScheme="gray"
        orientation="horizontal"
        size="md"
        spacing={0}
        variant="ghost"
      >
        <Tooltip hasArrow label={<KeyboardShortcutsTooltip title="Metric" shortcut="M" />} placement={tooltipPlacement}>
          <IconButton
            fontSize={fontSize}
            aria-label="metric"
            icon={<ICON_MAP.Metric />}
            onClick={() => openExistingObjectDrawer('metric')}
          />
        </Tooltip>

        <Tooltip hasArrow label={<KeyboardShortcutsTooltip title="Work" shortcut="W" />} placement={tooltipPlacement}>
          <IconButton
            fontSize={fontSize}
            aria-label="work"
            icon={<ICON_MAP.Work />}
            onClick={() => openExistingObjectDrawer('entity')}
          />
        </Tooltip>
        <Tooltip hasArrow label="Submap" placement={tooltipPlacement}>
          <IconButton
            fontSize={fontSize}
            aria-label="map"
            icon={<ICON_MAP.StrategyMap />}
            onClick={() => openExistingObjectDrawer('strategy')}
          />
        </Tooltip>
        {shownCardTypes.length > 0 && <Divider h="1.5rem" pt={4} orientation="vertical" />}
        {shownCardTypes?.map((type) => (
          <Tooltip key={type.id} hasArrow label={type.name} placement={tooltipPlacement}>
            <IconButton
              fontSize={fontSize}
              aria-label={type.name}
              icon={<Icon as={basicCardIconMap[type.icon] || ICON_MAP.BasicCard} />}
              onClick={() => openExistingObjectDrawer(`basicCard/${type.id}`)}
            />
          </Tooltip>
        ))}

        <Divider h="1.5rem" pt={4} orientation="vertical" />

        <Tooltip hasArrow label="Manage card types" placement={tooltipPlacement}>
          <IconButton
            fontSize={fontSize}
            aria-label="manage card types"
            icon={<ICON_MAP.Ellipsis />}
            onClick={basicTypeModalDisclosure.onOpen}
          />
        </Tooltip>
      </ButtonGroup>
      <BasicTypeModal cardTypes={cardTypes} {...basicTypeModalDisclosure} />
    </>
  )
}

export default AddRoadmapItemMenu
