import type { FC } from 'react'
import { useRevalidator } from 'react-router-dom'

import ConfirmPopover from '@app/shared/confirmPopover'
import { useMemberAdministrateMutation } from '@graphql/queries'

interface Props {
  userId: string
  children?: React.ReactNode
}

const MemberDisableButton: FC<Props> = ({ userId, children = null }) => {
  const { revalidate } = useRevalidator()
  const [, administrateUser] = useMemberAdministrateMutation()

  const toggleAccess = async (disabled) => {
    await administrateUser({
      input: {
        userId,
        disabled
      }
    })

    revalidate()
  }

  return (
    <ConfirmPopover
      onConfirm={() => toggleAccess(true)}
      header="Disable team member"
      body="Are you sure you'd like to disable this team member?"
      confirmButtonText="Confirm"
    >
      {children}
    </ConfirmPopover>
  )
}

export default MemberDisableButton
