import { useHotkeys } from 'react-hotkeys-hook'
import { Outlet } from 'react-router-dom'

import MapDrawer from '@app/pages/maps/components/drawer/mapDrawer'
import { useDrawerContext } from '@app/pages/maps/drawerContext'

const ChatDrawer = () => {
  const { onDrawerClose } = useDrawerContext()

  useHotkeys('esc', () => onDrawerClose())

  return (
    <MapDrawer w="600px" py={0} overflowX="hidden">
      <Outlet />
    </MapDrawer>
  )
}

export default ChatDrawer
