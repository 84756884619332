import { Box, Divider } from '@chakra-ui/react'
import Fuse from 'fuse.js'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'
import { useState } from 'react'
import { FixedSizeList as List } from 'react-window'

import FilterSegmentValueOption from './filterSegmentValueOption'

import SearchInput from '@app/next/forms/searchInput'
import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { useStrategyMetricDataPointSegmentValuesQuery } from '@graphql/queries'
import type { SegmentName } from '@graphql/types'

const optionBuilder = ({ data, index, style }) => {
  const option = data[index]
  const { id } = option

  return (
    <Box sx={style} h="45px">
      <FilterSegmentValueOption key={id} option={option} />
    </Box>
  )
}

interface Props {
  strategyId: string
  segmentName: Pick<SegmentName, 'id'>
}

const FilterSegmentValueOptions: FC<Props> = ({ segmentName, strategyId }) => {
  const [filter, setFilter] = useState('')
  const { id } = segmentName
  const [{ data }] = useStrategyMetricDataPointSegmentValuesQuery({
    variables: {
      id: strategyId,
      segmentNameId: id
    }
  })

  const fuse = new Fuse(data?.strategy?.metricDataPointSegmentValues || [], {
    keys: ['value']
  })

  const results = filter
    ? fuse.search(filter).map((result) => result.item)
    : data?.strategy?.metricDataPointSegmentValues || []

  const options = sortBy(results, (o) => o.value.toLowerCase())

  const builder = () => {
    // this is a parameterized field, so getting this data through zustand can lead to unexpected outcomes sometimes
    const listHeight = options.length < 7 ? options.length * 45 : 280

    return (
      <>
        <Box p={2}>
          <SearchInput
            placeholder="Search segments..."
            onChange={(e) => setFilter(e.target.value)}
            onClear={() => setFilter('')}
          />
        </Box>
        <Divider />
        <List height={listHeight} itemCount={options.length} itemData={options} itemSize={45} width="100%">
          {optionBuilder}
        </List>
        {options.length === 0 && filter && <Box p={2}>No segments match your search</Box>}
      </>
    )
  }

  return <Box>{builder()}</Box>
}

export default withSuspenseWrapper(FilterSegmentValueOptions)
