import { Button, HStack, Icon, Spacer, Stack, StackDivider } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'
import BasicCardTypePopover from '@app/next/basicCardTypePopover'
import TextAreaInput from '@app/next/forms/inline/textAreaInput'
import WithLabel from '@app/next/forms/withLabel'
import OwnerPopover from '@app/next/ownerPopover'
import MarkdownDisplay from '@app/shared/markdownDisplay'
import type { MapDomainObject } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'
import type { BasicCard as BasicCardType, DomainObject } from '@graphql/types'

interface Props {
  domainObject: Partial<BasicCardType>
  actionsChildren?: React.ReactNode
}

const BasicCard: FC<Props> = ({ domainObject, actionsChildren = null, ...rest }) => {
  const { strategyId } = useParams()
  const navigate = useNavigate()
  const route = `/strategy/${strategyId}/roadmap/basicCard/${domainObject.id}`
  const isAnonymousUser = useIsAnonymousUser(strategyId)

  return (
    <Stack {...rest} divider={<StackDivider />}>
      <>
        <HStack>
          <BasicCardTypePopover basicCard={domainObject as BasicCardType} fontSize="sm" boxSize={5} />
          <Spacer />
          {actionsChildren !== null ? actionsChildren : null}
        </HStack>
        <TextAreaInput
          domainObject={domainObject as MapDomainObject}
          color="fg.muted"
          fontWeight="bold"
          wordBreak="break-word"
          name="name"
          fontSize="sm"
          title={domainObject?.name}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.currentTarget.blur()
            }
          }}
        />
        {domainObject.goalHypothesis && (
          <WithLabel showLabel={!!domainObject?.goalHypothesis} label="Goal / Hypothesis">
            <MarkdownDisplay text={domainObject?.goalHypothesis} fontSize="xs" />
          </WithLabel>
        )}
      </>
      <HStack>
        <OwnerPopover object={domainObject as DomainObject} />
        {!isAnonymousUser && (
          <>
            <Spacer />
            <Button
              color="fg.subtle"
              onClick={() => navigate(route)}
              rightIcon={<Icon as={ICON_MAP.ExternalLink} boxSize={4} color="gray.500" />}
              size="xs"
              variant="ghost"
            >
              Details
            </Button>
          </>
        )}
      </HStack>
    </Stack>
  )
}

export default memo(BasicCard)
