import { Box, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useFetcher } from 'react-router-dom'

import BasicCard from '../basicCard/basicCard'
import Entity from '../entities/entity'
import Metric from '../metrics/metric'
import Strategy from '../strategies/strategy'

import DirectIncomers from '@app/pages/reports/components/shared/directIncomers'
import Loader from '@app/shared/loader'
import type { MapDomainReport } from '@app/types'
import type { ReportStandardQuery } from '@graphql/queries'

interface Props {
  report: MapDomainReport
}

const StandardSection: FC<Props> = ({ report }) => {
  const [reportId, setReportId] = useState(report?.id)
  const fetcher = useFetcher()

  useEffect(() => {
    if ((fetcher.state === 'idle' && !fetcher.data) || report?.id !== reportId) {
      fetcher.load(`${window.location.pathname}/standard`)
      setReportId(report.id)
    }
  }, [fetcher, report?.id, reportId])

  const data: ReportStandardQuery['report'] = fetcher?.data?.report || {}

  if (report.hideStrategies && report.hideMetrics && report.hideEntities && report.hideBasicCards) {
    return null
  }

  const { basicCards, entities, metrics, strategies } = data

  // TODO: This should be done on the backend but requires adding the strategy node data stats to the metric searchkick
  // results. That's a bigger lift than we're trying to tackle, so we'll do it here for now.
  const filteredMetrics = metrics?.filter((metric) => {
    const metricStats = metric.strategyStats
    const strategyStats = metric.strategyNodeData?.strategyStats

    // combine the stats display better so these checks naturally cluster together
    const hasMetricStats = metricStats?.some((stat) => stat.total || stat.change) || false
    const hasStrategyStats = strategyStats?.some((stat) => stat.total || stat.change) || false

    return hasMetricStats || hasStrategyStats
  })

  return (
    <Stack mb={3}>
      {fetcher.state === 'loading' ? (
        <Loader />
      ) : (
        <>
          {filteredMetrics?.map((metric) => (
            <Box key={metric.id} px={4} pb={4} borderWidth="1px" borderColor="border.default">
              <Metric metric={metric} />
            </Box>
          ))}
          {basicCards?.map((basicCard) => (
            <Box key={basicCard.id} px={4} pb={4} borderWidth="1px" borderColor="border.default">
              <BasicCard basicCard={basicCard} />
              <DirectIncomers directIncomers={basicCard.directIncomers} />
            </Box>
          ))}
          {entities?.map((entity) => (
            <Box key={entity.id} px={4} pb={4} borderWidth="1px" borderColor="border.default">
              <Entity entity={entity} />
            </Box>
          ))}
          {strategies?.map((strategy) => (
            <Box key={strategy.id} px={4} pb={4} borderWidth="1px" borderColor="border.default">
              <Strategy strategy={strategy} />
            </Box>
          ))}
        </>
      )}
    </Stack>
  )
}

export default StandardSection
