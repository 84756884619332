import type { TextareaProps } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'

import useAutosizeTextArea, { resizeTextArea } from '@app/hooks/useAutosizeTextArea'
import useInlineEditorProps from '@app/hooks/useInlineEditorProps'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import RawTextInput from '@app/next/forms/textAreaInput'
import type { MapDomainObject } from '@app/types'

interface Props extends TextareaProps {
  domainObject: MapDomainObject
  name: string
}

const TextAreaInput: FC<Props> = ({ domainObject, name, ...rest }) => {
  const domainObjectValue = domainObject[name]
  const [value, setValue] = useState(domainObjectValue)

  useEffect(() => {
    setValue(domainObjectValue)
  }, [domainObjectValue])

  const { onChange, onBlur } = useInlineEditorProps(domainObject)
  const { user } = useStoreCurrentUser()
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const editor = user?.role === 'editor'

  useAutosizeTextArea(textAreaRef.current, value as string)

  const inlineStyles = {
    width: '100%',
    _disabled: {
      cursor: 'default'
    }
  }

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(evt.target.value)

    onChange(evt)
  }

  // See if this prevents the cut-off text issue ENG-1377
  useEffect(() => {
    resizeTextArea(textAreaRef.current)
  })

  return (
    <RawTextInput
      name={name}
      value={value}
      variant="unstyled"
      onChange={handleChange}
      onBlur={onBlur}
      data-1p-ignore
      isDisabled={!editor}
      ref={textAreaRef}
      rows={1}
      overflow="hidden"
      resize="none"
      className="nodrag"
      {...inlineStyles}
      {...rest}
    />
  )
}

export default TextAreaInput
