import { Box, Center, Checkbox, Stack, Text, useCheckboxGroup } from '@chakra-ui/react'
import truncate from 'lodash/truncate'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import useGetObjectPage from '@app/hooks/useGetObjectPage'
import BasicCardTypePopover from '@app/next/basicCardTypePopover'
import BasicCardListActions from '@app/pages/basicCards/components/basicCardListActions'
import MapsCell from '@app/pages/shared/mapsCell'
import MultiOps from '@app/pages/shared/multiOps'
import SearchRow from '@app/pages/shared/searchRow'
import SelectAllCheckboxHeader from '@app/pages/shared/selectAllCheckboxHeader'
import useGenerateSelectableTableRowFormatter from '@app/pages/shared/useGenerateSelectableTableRowFormatter'
import EmptyState from '@app/shared/emptyState/emptyState'
import MapFilterSelectInput from '@app/shared/forms/mapFilterSelectInput'
import { Card, PageHeader, PageStack } from '@app/shared/layout'
import Pagination from '@app/shared/pagination'
import Table from '@app/shared/table'
import SortHeader from '@app/shared/table/components/sortHeader'
import { SortHeaderContextProvider } from '@app/shared/table/contexts/sortHeaderContext'
import type { Column } from '@app/shared/table/types'
import withAwait from '@app/shared/withAwait'
import type { MapDomainBasicCard } from '@app/types'
import type { BasicCard } from '@graphql/types'

type MakeColumns = (args: {
  getCheckboxProps: ReturnType<typeof useCheckboxGroup>['getCheckboxProps']
  selected: ReturnType<typeof useCheckboxGroup>['value']
  setSelected: ReturnType<typeof useCheckboxGroup>['setValue']
}) => Column<BasicCard>[]

const BasicCardName: FC<{ data: BasicCard }> = ({ data }) => (
  <Text minW="xs" fontSize="md" whiteSpace="normal" wordBreak="break-all" title={data.name}>
    {truncate(data.name, { length: 50 })}
  </Text>
)

const BasicCardType: FC<{ data: BasicCard }> = ({ data }) => (
  <BasicCardTypePopover basicCard={data} fontSize="sm" boxSize={4} />
)

const BasicCardWorkItems: FC<{ data: BasicCard }> = ({ data }) => (
  <Text>{data.containeeCount > 0 ? data.containeeCount : 'none'}</Text>
)

const makeColumns: MakeColumns = ({ getCheckboxProps, selected, setSelected }) => [
  {
    header: <SelectAllCheckboxHeader collectionType="basicCard" selected={selected} setSelected={setSelected} />,
    width: '5%',
    key: 'checkbox',
    cell: ({ data: basicCard }) => (
      <Center>
        <Checkbox {...getCheckboxProps({ value: basicCard.id })} />
      </Center>
    )
  },
  {
    header: <SortHeader field="name" title="Name" emptyState />,
    key: 'name',
    width: '40%',
    cell: BasicCardName
  },
  {
    header: <SortHeader field="cardTypeName" title="Type" emptyState />,
    key: 'cardType',
    cell: BasicCardType
  },
  {
    header: 'Work items',
    key: 'containeeCount',
    cell: BasicCardWorkItems
  },
  {
    header: 'Maps',
    key: 'maps',
    cell: MapsCell<BasicCard>
  },
  {
    header: 'Labels',
    key: 'labels',
    cell: ({ data }) => <Text whiteSpace="normal">{data?.labels?.map((label) => label.name)?.join(', ')}</Text>
  },
  {
    header: '',
    cell: BasicCardListActions
  }
]

const Empty = ({ strategyId }) => (
  <Box p={2}>
    <SearchRow
      leftChild={<MapFilterSelectInput field="strategyId" defaultValue={strategyId} />}
      page={0}
      limitValue={0}
      totalCount={0}
    />
    <EmptyState title="Add some basic cards on your maps to see them here." w="auto" />
  </Box>
)

const Populated = ({ strategyId, collection, metadata }) => {
  const { page, totalCount } = metadata
  const {
    value: selected,
    getCheckboxProps,
    setValue: setSelected
  } = useCheckboxGroup({
    defaultValue: []
  })
  const columns = useMemo(
    () => makeColumns({ getCheckboxProps, selected, setSelected }),
    [getCheckboxProps, selected, setSelected]
  )
  const onBulkDelete = useCallback(() => {
    setSelected([])
  }, [setSelected])
  const baseRowProps = useGenerateSelectableTableRowFormatter<MapDomainBasicCard>('basicCard', selected, setSelected)
  const rowFormatter = useCallback((basicCard: BasicCard) => baseRowProps(basicCard), [baseRowProps])

  return (
    <Stack spacing="0">
      <SearchRow
        leftChild={<MapFilterSelectInput field="strategyId" defaultValue={strategyId} />}
        leftSummarySibling={<MultiOps type="basicCard" selected={selected} fontSize="sm" onDelete={onBulkDelete} />}
        page={page}
        limitValue={collection.length}
        totalCount={totalCount}
      />
      <Box overflow="auto">
        <SortHeaderContextProvider defaultState={{ name: 'asc' }}>
          <Table<(typeof collection)[0]> columns={columns} data={collection} rowFormatter={rowFormatter} />
        </SortHeaderContextProvider>
        <Pagination px={6} py={4} {...{ ...metadata }} scrollContainer="main" />
      </Box>
    </Stack>
  )
}

const List: FC = () => {
  const { collection, metadata } = useGetObjectPage('basicCard')
  const [searchParams] = useSearchParams()
  const strategyId = searchParams.get('strategyId')
  const subtitle =
    'Your cards include your bets, objectives, any other type of card you create outside of work, metrics, and maps.'

  return (
    <PageStack>
      <PageHeader title="Cards" subtitle={subtitle} />
      <Card p={0}>
        {collection.length ? (
          <Populated strategyId={strategyId} collection={collection} metadata={metadata} />
        ) : (
          <Empty strategyId={strategyId} />
        )}
      </Card>
    </PageStack>
  )
}

export default withAwait(List, 'basicCards')
