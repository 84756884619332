import type { LexicalEditor } from 'lexical'
import { forwardRef } from 'react'

import RichTextInput from '@app/next/forms/inline/richTextInput'
import WithLabel from '@app/next/forms/withLabel'
import type { MapDomainObject } from '@app/types'

interface Props {
  domainObject: MapDomainObject
  name: string
  label: string
  placeholder?: string
  domainObjectUpdateId?: string
}
const RichTextInputWithLabel = forwardRef<LexicalEditor, Props>(
  ({ domainObject, name, placeholder, label, domainObjectUpdateId }, ref) => {
    const domainObjectValue = domainObject[name]

    return (
      <WithLabel label={label} showLabel={!!domainObjectValue}>
        <RichTextInput
          domainObject={domainObject}
          name={name}
          placeholder={placeholder}
          domainObjectUpdateId={domainObjectUpdateId}
          ref={ref}
        />
      </WithLabel>
    )
  }
)

export default RichTextInputWithLabel
