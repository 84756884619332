import { forwardRef, Textarea } from '@chakra-ui/react'

const ChatTextarea = forwardRef(({ onInput, ...rest }, ref) => (
  <Textarea
    ref={ref}
    maxH="200px"
    pe="9"
    _placeholder={{ color: 'fg.subtle' }}
    resize="none"
    autoFocus
    name="prompt"
    onInput={(event) => {
      const textarea = event.currentTarget
      textarea.style.height = 'auto'
      const borderHeight = textarea.offsetHeight - textarea.clientHeight
      textarea.style.height = `${textarea.scrollHeight + borderHeight}px`
      onInput?.(event)
    }}
    placeholder="Ask me anything..."
    rows={3}
    {...rest}
  />
))

export default ChatTextarea
