import { createStandaloneToast } from '@chakra-ui/react'
import type { RouteObject } from 'react-router-dom'
import { defer, Outlet, redirect } from 'react-router-dom'

import Create from '@app/pages/metrics/components/goals/create'
import Edit from '@app/pages/metrics/components/goals/edit'
import MetricGoalTab from '@app/pages/metrics/tabs/metricGoalTab'
import { useStore } from '@app/store'
import { requiresAuthorization } from '@app/utils/auth'
import { nullifyEmptyStrings } from '@app/utils/routeHelpers'
import { actionMutation, loaderQuery } from '@graphql/client'
import { GoalCreate, GoalUpdate, GoalDelete, Goals, Goal } from '@graphql/documents/goal.graphql'
import type { GoalsQuery, GoalsQueryVariables } from '@graphql/queries'

const NUMBERIZED_FIELDS = ['startValue', 'targetValue', 'periodYear', 'periodMonth', 'periodNumber']

const { toast } = createStandaloneToast()

const numberizeFields = (input) => {
  const output = { ...input }

  NUMBERIZED_FIELDS.forEach((field) => {
    if (input[field]) {
      output[field] = parseFloat(input[field])
    }
  })

  return output
}

const prepareFields = (input) => nullifyEmptyStrings(numberizeFields(input))

const loadGoals = async ({ params }) => {
  const metricId = params.metricId || params.nodeId

  const { loaderQuery: storeLoaderQuery } = useStore.getState()

  const resp = await storeLoaderQuery<GoalsQuery, GoalsQueryVariables>(Goals, { metricId })
  const goals = resp?.data?.metric?.goals || []

  return { goals }
}

const loadGoal = async ({ params }) => {
  const { data } = await loaderQuery(Goal, {
    id: params.goalId
  })

  return defer({ goal: data?.goal })
}

const createGoal = async (request: Request) => {
  const formData = await request.formData()
  const input = prepareFields(Object.fromEntries(formData.entries()))

  await actionMutation(GoalCreate, input)
  return redirect('..')
}

const updateGoal = async (request: Request) => {
  const formData = await request.formData()
  const input = prepareFields(Object.fromEntries(formData.entries()))

  await actionMutation(GoalUpdate, input)
  return redirect('..')
}

const deleteGoal = async (request: Request) => {
  const formData = await request.formData()
  const input = Object.fromEntries(formData.entries())

  toast({ title: 'Deleted goal' })

  return actionMutation(GoalDelete, input)
}

const routes: (namespace: string | null) => RouteObject = (namespace) => ({
  path: 'key_results',
  id: namespace ? `${namespace}-goals` : 'goals',
  loader: loadGoals,
  element: <Outlet />,
  action: ({ request }) => {
    switch (request.method) {
      case 'DELETE':
        return deleteGoal(request)
      default:
        return Promise.resolve()
    }
  },
  children: [
    {
      index: true,
      element: <MetricGoalTab />
    },
    {
      path: 'new',
      loader: async () => requiresAuthorization('create', 'goal'),
      element: <Create />,
      action: ({ request }) => {
        switch (request.method) {
          case 'POST':
            return createGoal(request)
          default:
            return Promise.resolve()
        }
      }
    },
    {
      path: ':goalId',
      loader: loadGoal,
      element: <Outlet />,
      id: namespace ? `${namespace}-goal` : 'goal',
      action: ({ request }) => {
        switch (request.method) {
          case 'POST':
            return updateGoal(request)
          default:
            return Promise.resolve()
        }
      },
      children: [
        {
          path: 'edit',
          loader: async () => requiresAuthorization('update', 'goal'),
          element: <Edit />
        }
      ]
    }
  ]
})

export default routes
