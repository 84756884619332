import { VisuallyHiddenInput } from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef } from 'react'
import { Form, useSubmit } from 'react-router-dom'

import useFormSubmitAction from '@app/hooks/useFormSubmitAction'
import ConfirmPopover from '@app/shared/confirmPopover'

type Props = {
  recurringReportConfigurationId: string
}

const RecurringReportConfigurationDelete: FC<Props> = ({ recurringReportConfigurationId }) => {
  const action = useFormSubmitAction(`recurring/${recurringReportConfigurationId}`)
  const ref = useRef()
  const submit = useSubmit()

  const doDelete = async () => {
    submit(ref.current, {
      method: 'delete',
      action
    })

    return false
  }

  return (
    <ConfirmPopover
      onConfirm={doDelete}
      header="Delete recurring report"
      body="Are you sure you want to delete this recurring report? You won&#39;t be able to undo this."
      confirmButtonText="Delete recurring report"
    >
      <Form method="delete">
        <VisuallyHiddenInput name="recurringReportConfigurationId" readOnly value={recurringReportConfigurationId} />
        Delete recurring report
        <VisuallyHiddenInput ref={ref} type="submit" />
      </Form>
    </ConfirmPopover>
  )
}

export default RecurringReportConfigurationDelete
