import { HStack, Icon, type IconProps, Text } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import type { FC } from 'react'

import BasicTypeSelect from '@app/pages/maps/components/nodes/components/basicType/basicTypeSelect'
import { iconMap } from '@app/pages/maps/components/nodes/components/basicType/customIcons'
import cardFontSize from '@app/shared/cards/cardFontSize'
import cardIconBoxSize from '@app/shared/cards/cardIconBoxSize'
import type { CardSize } from '@app/types'
import type { CardType } from '@graphql/types'

interface Props {
  cardType?: Pick<CardType, 'id' | 'name' | 'icon' | 'color'>
  fieldName: string
  size?: CardSize
  editing?: boolean
  iconProps?: IconProps
}

const CardBasicType: FC<Props> = ({ cardType, fieldName, size = 'normal', editing = false, iconProps = {} }) => {
  if (editing) {
    return <BasicTypeSelect fieldName={fieldName} type={cardType?.id} />
  }

  const fontSize = cardFontSize('sm', size)
  const boxSize = cardIconBoxSize(5, size)

  const IconComponent = cardType?.icon ? iconMap[cardType?.icon] : null
  const elementColor = cardType?.color ? `${cardType.color}.500` : 'gray.500'

  if (!cardType) {
    return null
  }

  return (
    <HStack>
      {cardType?.icon && <Icon as={IconComponent} boxSize={boxSize} color={elementColor} {...iconProps} />}
      <Text color={elementColor} fontSize={fontSize} fontWeight="semibold">
        {capitalize(cardType?.name || '')}
      </Text>
    </HStack>
  )
}

export default CardBasicType
