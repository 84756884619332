import { Stack, Icon, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { BiPlay } from 'react-icons/bi'

import ImageWithOverlay from './imageWithOverlay'

import UsePlaybookButton from '@app/pages/playbooks/components/usePlaybookButton'
import type { Playbook } from '@graphql/queries'

interface Props {
  playbooks: Array<Playbook>
}

const HeroPlaybooks: FC<Props> = ({ playbooks }) => {
  const [hero, special1, special2, ...rest] = playbooks

  return (
    <Stack>
      <Text fontWeight="semibold" size="lg">
        Featured
      </Text>
      <Stack
        align="stretch"
        direction={{ base: 'column', md: 'row' }}
        h={{ md: '400px' }}
        spacing={{ base: '6', md: '10' }}
      >
        <ImageWithOverlay
          flex="1"
          objectPosition="top center"
          title={hero.name}
          description={hero.shortDescription}
          src={hero.hero}
          alt={hero.name}
          url={hero.friendlyId}
          cta={
            <UsePlaybookButton
              playbookId={hero.id}
              key="use"
              aria-label="Use this playbook"
              variant="primary"
              leftIcon={<Icon as={BiPlay} boxSize="5" />}
            />
          }
        />
        {special1 && (
          <Stack maxW={{ md: '400px' }} spacing={{ base: '6', md: '10' }}>
            <ImageWithOverlay
              key="special-1"
              spacing="4"
              title={special1.name}
              description={special1.shortDescription}
              src={special1.hero}
              alt={special1.name}
              url={special1.friendlyId}
              headingSize="2xs"
              cta={
                <UsePlaybookButton
                  playbookId={special1.id}
                  aria-label="Use this playbook"
                  variant="primary"
                  leftIcon={<Icon as={BiPlay} boxSize="5" />}
                />
              }
            />
            {special2 && (
              <ImageWithOverlay
                key="special-2"
                spacing="4"
                title={special2.name}
                description={special2.shortDescription}
                src={special2.hero}
                alt={special2.name}
                url={special1.friendlyId}
                headingSize="2xs"
                cta={
                  <UsePlaybookButton
                    playbookId={special2.id}
                    aria-label="Use this playbook"
                    variant="primary"
                    leftIcon={<Icon as={BiPlay} boxSize="5" />}
                  />
                }
              />
            )}
          </Stack>
        )}
        {rest.length &&
          rest.map((playbook) => (
            <ImageWithOverlay
              key={`playbook-list-${playbook.id}`}
              spacing="4"
              maxW="30%"
              title={playbook.name}
              src={playbook.hero}
              alt={playbook.name}
              url={playbook.friendlyId}
            />
          ))}
      </Stack>
    </Stack>
  )
}

export default HeroPlaybooks
