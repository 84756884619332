import { Link, Text, useColorModeValue } from '@chakra-ui/react'
import type { FC } from 'react'

import cardFontSize from '@app/shared/cards/cardFontSize'
import type { CardSize } from '@app/types'

type Props = {
  sourceUrl: string
  size?: CardSize
}

const CardDetailsLink: FC<Props> = ({ sourceUrl, size = 'normal' }) => {
  const color = useColorModeValue('gray.700', 'gray.400')
  const fontSize = cardFontSize('sm', size)

  return (
    <Link color={color} href={sourceUrl} isExternal>
      <Text as="span" color="fg.subtle" fontSize={fontSize} _hover={{ textDecoration: 'underline' }}>
        External resource
      </Text>{' '}
      <Text as="span" color="fg.subtle" fontSize={fontSize}>
        ↗
      </Text>
    </Link>
  )
}

export default CardDetailsLink
