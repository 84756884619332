import {
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Stack
} from '@chakra-ui/react'
import type { FC, FormEvent, PropsWithChildren } from 'react'
import { useState, useRef } from 'react'
import { useParams } from 'react-router-dom'

import TextAreaInput from '@app/next/forms/textAreaInput'
import { RawForm } from '@app/shared/rawForms/form'
import useToast from '@app/shared/toast'
import { useStrategyPopulateFromPlaybookTemplateMutation } from '@graphql/queries'
import type { StrategyPopulateFromPlaybookTemplateInput } from '@graphql/types'

type OnSubmit = (e: FormEvent<HTMLFormElement>, onClose: () => void) => void

const PopulateFromPlaybookTemplatePopover: FC<PropsWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { strategyId } = useParams()
  const ref = useRef(null)
  const [, populate] = useStrategyPopulateFromPlaybookTemplateMutation()
  const toast = useToast()

  const onSubmit: OnSubmit = async (e, onClose) => {
    setIsLoading(true)
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const input = {
      strategyId,
      ...Object.fromEntries(formData.entries())
    } as StrategyPopulateFromPlaybookTemplateInput

    try {
      const response = await populate({ input, strategyId })

      if (response.error) {
        throw new Error(response.error.message)
      }

      onClose()
    } catch (ce) {
      toast({
        status: 'error',
        title: 'Error',
        description: ce.message
      })

      ref?.current?.focus?.()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Popover initialFocusRef={ref} isLazy placement="right">
      {({ onClose }) => (
        <>
          <PopoverTrigger>{children}</PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader fontSize="md">Populate From Playbook Template</PopoverHeader>
            <PopoverBody>
              <RawForm onSubmit={(e) => onSubmit(e, onClose)}>
                <Stack>
                  <TextAreaInput isRequired name="playbookTemplate" ref={ref} />
                  <ButtonGroup>
                    <Spacer />
                    <Button onClick={onClose}>Cancel</Button>
                    <Button isLoading={isLoading} type="submit" variant="primary">
                      Populate map
                    </Button>
                  </ButtonGroup>
                </Stack>
              </RawForm>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}

export default PopulateFromPlaybookTemplatePopover
