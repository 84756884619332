import { Box, ButtonGroup, HStack, IconButton, Tooltip, useColorModeValue } from '@chakra-ui/react'
import type { StackProps } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { useParams, Link, NavLink } from 'react-router-dom'

import CollapsedBasicCard from './collapsedCards/collapsedBasicCard'
import CollapsedEntity from './collapsedCards/collapsedEntity'
import CollapsedMetric from './collapsedCards/collapsedMetric'
import CollapsedSection from './collapsedCards/collapsedSection'
import CollapsedStrategy from './collapsedCards/collapsedStrategy'

import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'
import DeleteButton from '@app/pages/maps/components/nodes/components/toolbar/components/deleteButton'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import { useStore } from '@app/store'
import type {
  CardSize,
  MapDomainBasicCard,
  MapDomainEntity,
  MapDomainMetric,
  MapDomainNode,
  MapDomainObject,
  MapDomainSection,
  MapDomainStrategy
} from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

interface Props extends StackProps {
  node: MapDomainNode
  nodeObject: MapDomainObject
  size?: CardSize
}

const CollapsedNode: FC<Props> = ({ node, nodeObject, size = 'normal', ...rest }) => {
  const [isHovering, setIsHovering] = useState(false)
  const { strategyId } = useParams()
  const isAnonymousUser = useIsAnonymousUser(strategyId)

  const { canEdit } = usePermissionsContext()

  const expandNode = useStore.use.expandNode()
  const route = `/strategy/${strategyId}/map/${node.type}/${nodeObject.id}`
  const navHighlightColor = useColorModeValue('blue.50', 'blue.800')

  let collapsedNavProps = {}
  let editButton = null
  if (!['strategy', 'section'].includes(node.type)) {
    collapsedNavProps = {
      as: NavLink,
      to: route,
      _activeLink: { bg: navHighlightColor }
    }

    editButton = (
      <Tooltip label="Edit">
        <IconButton
          as={Link}
          borderRadius={0}
          aria-label="Edit card"
          icon={<ICON_MAP.Edit />}
          to={`${route}/settings`}
        />
      </Tooltip>
    )
  }

  // if the node.type is strategy, set the collapsedNavProps to a link and route to `preview/${id}`
  if (node.type === 'strategy') {
    collapsedNavProps = {
      as: NavLink,
      to: `preview/${nodeObject.id}`,
      _activeLink: { bg: navHighlightColor }
    }
  }

  const { type } = node

  let cardDisplay
  switch (type) {
    case 'metric':
      cardDisplay = <CollapsedMetric metric={nodeObject as MapDomainMetric} size={size} />
      break
    case 'entity':
      cardDisplay = <CollapsedEntity entity={nodeObject as MapDomainEntity} size={size} />
      break
    case 'section':
      cardDisplay = <CollapsedSection section={nodeObject as MapDomainSection} size={size} />
      break
    case 'strategy':
      cardDisplay = <CollapsedStrategy strategy={nodeObject as MapDomainStrategy} size={size} />
      break
    case 'basicCard':
      cardDisplay = <CollapsedBasicCard basicCard={nodeObject as MapDomainBasicCard} size={size} />
      break
    default:
  }

  // This needs a better short circuit so anonymous users do not end up in a bad place.
  if (isAnonymousUser) {
    collapsedNavProps = {}
  }

  return (
    <HStack
      key={`collapsed-node-row-${node.id}`}
      pos="relative"
      px={2}
      py={2}
      _hover={{ cursor: 'pointer', bgColor: 'bg.subtle' }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      {...rest}
    >
      <Box w="100%" {...collapsedNavProps}>
        {cardDisplay}
      </Box>
      <ButtonGroup
        pos="absolute"
        top={-2}
        right={-2}
        borderRadius="sm"
        shadow="sm"
        visibility={canEdit && isHovering ? 'initial' : 'hidden'}
        bgColor="bg.surface"
        colorScheme="blue"
        size="sm"
        spacing={0}
        variant="ghost"
      >
        {editButton}
        <Tooltip label="Uncollapse card">
          <IconButton
            borderRadius={0}
            aria-label="Uncollapse card"
            icon={<ICON_MAP.ExpandAction />}
            onClick={() => expandNode(strategyId, node)}
          />
        </Tooltip>
        <DeleteButton node={node} />
      </ButtonGroup>
    </HStack>
  )
}

export default CollapsedNode
