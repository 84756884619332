import { BaseEdge } from '@xyflow/react'
import type { CSSProperties, FC } from 'react'

interface Props {
  path: string
  isSelected: boolean
  style?: CSSProperties
  color: string
  selectedColor: string
  markerEnd?: string
}

const StyledPath: FC<Props> = ({ path, isSelected, style = {}, color, selectedColor, markerEnd = '', ...rest }) => {
  const pathOneStyle = {
    strokeWidth: 5,
    fill: 'none',
    stroke: isSelected ? selectedColor : color,
    pointerEvents: 'stroke',
    strokeDasharray: 5,
    ...style
  }

  return (
    <BaseEdge
      path={path}
      style={pathOneStyle as CSSProperties}
      interactionWidth={100}
      markerEnd={markerEnd}
      {...rest}
    />
  )
}

export default StyledPath
