import { Button, ButtonGroup, Icon } from '@chakra-ui/react'
import type { FC } from 'react'
import { useSearchParams } from 'react-router-dom'

import ICON_MAP from '@app/utils/iconMap'

const ToggleMetricData: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const metricData = searchParams.get('metricData')

  const showMetricData = () => {
    searchParams.delete('metricData')

    setSearchParams(searchParams)
  }

  if (!metricData) {
    return null
  }

  return (
    <ButtonGroup colorScheme="gray" size="2xs" variant="ghost">
      <Button aria-label="Show metric data" leftIcon={<Icon as={ICON_MAP.ExpandAction} />} onClick={showMetricData}>
        Show metric data
      </Button>
    </ButtonGroup>
  )
}

export default ToggleMetricData
