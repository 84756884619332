import { IconButton, useDisclosure } from '@chakra-ui/react'
import { type FC } from 'react'
import { useParams } from 'react-router-dom'

import ColorButton from '../../../../../../shared/colorButton'

import useGetSelectedNodes from '@app/hooks/useGetSelectedNodes'
import { useStore } from '@app/store'
import type { Color } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

const BulkColorButton: FC = () => {
  const { strategyId } = useParams()
  const updateNodes = useStore.use.updateNodes()
  const nodes = useGetSelectedNodes(strategyId)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const changeColor = async (color: Color) => {
    await updateNodes(
      strategyId,
      nodes.map((node) => ({
        ...node,
        metadata: {
          ...node.metadata,
          color
        }
      }))
    )
    onClose()
  }

  return (
    <ColorButton selectedColor="" onColorSelect={changeColor} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      {(props) => <IconButton aria-label="color-button" icon={<ICON_MAP.ColorAction />} variant="ghost" {...props} />}
    </ColorButton>
  )
}

export default BulkColorButton
