import { Box, Flex, Heading, HStack, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import AddWorkItemButton from '@app/next/changelog/components/addWorkItemButton'
import ContainerFilters from '@app/next/changelog/components/containerFilters'
import LayoutRow from '@app/next/changelog/components/layoutRow'
import Can from '@app/shared/authorization/can'

const Hero: FC = () => {
  const { strategyId } = useParams()
  const strategy = useGetObject(strategyId, 'strategy')

  return (
    <LayoutRow variant="naked">
      <Stack my={[4, null, 6]} data-testid="hero" spacing={4}>
        <HStack>
          <Heading flex="1" color="muted" size="sm">
            Changelog - {strategy.name}
          </Heading>
        </HStack>
        <Flex gap={2}>
          <Box flex={1}>
            <ContainerFilters strategyId={strategyId} />
          </Box>
          <Can I="create" an="entity">
            <AddWorkItemButton />
          </Can>
        </Flex>
      </Stack>
    </LayoutRow>
  )
}

export default Hero
