import { useReactFlow } from '@xyflow/react'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import type { AnyVariables } from 'urql'

import useMousePosition from '@app/hooks/useMousePosition'
import { useStore } from '@app/store'
import type { Nodes } from '@app/types'
import generateRandomName from '@app/utils/uniqueNameGenerator'

const NAME_GEN_OVERRIDES = {
  basicCard: generateRandomName
}

const EMPTY = {}

type Arguments = {
  mutationVariables?: AnyVariables
}

type UseCreateNewObject = (args?: Arguments) => (nodeType: Nodes) => void

const useCreateNewObject: UseCreateNewObject = ({ mutationVariables = EMPTY }) => {
  const { screenToFlowPosition } = useReactFlow()
  const { strategyId } = useParams()
  const getLatestMousePosition = useMousePosition()

  const addNode = useStore.use.addNode()

  return useCallback(
    (objectType) => {
      const name = NAME_GEN_OVERRIDES[objectType]?.() || `${generateRandomName()} ${objectType}`
      const mousePosition = getLatestMousePosition()
      const position = screenToFlowPosition(mousePosition)
      const objectData = {
        name,
        ...mutationVariables
      }

      addNode({
        strategyId,
        nodeData: {
          position
        },
        objectType,
        objectData
      })
    },
    [screenToFlowPosition, getLatestMousePosition, mutationVariables, addNode, strategyId]
  )
}

export default useCreateNewObject
