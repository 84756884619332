import { Box, Center, Circle, Icon, Text } from '@chakra-ui/react'
import type { FC, PropsWithChildren } from 'react'
import { FiMic } from 'react-icons/fi'

const EmptyStatePrompt: FC<PropsWithChildren> = ({ children = null }) => (
  <Center h="full">
    <Box>
      <Center gap="6">
        <Circle color="fg.default" bg="bg.accent.default" outline="8px solid" outlineColor="fg.accent.subtle" size="12">
          <Icon as={FiMic} color="fg.inverted" />
        </Circle>
        <Text color="fg.muted" fontSize="xl" fontWeight="medium">
          {children}
        </Text>
      </Center>
      <Center mt={6}>
        <Text fontSize="sm">It might take a few minutes for your first message to process</Text>
      </Center>
    </Box>
  </Center>
)

export default EmptyStatePrompt
