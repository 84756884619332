import type { BoxProps } from '@chakra-ui/react'
import { HStack, Spacer, Stack } from '@chakra-ui/react'
import { useViewport } from '@xyflow/react'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import { Card, CardDueDate } from '../components'
import CardLabels from '../components/cardLabels'
import { CardProvider, useCardContext } from '../contexts/cardContext'

import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'
import { aiInsightsShadow } from '@app/lib/globals'
import ConfidenceRatingPopover from '@app/next/confidenceRatingPopover'
import WithLabel from '@app/next/forms/withLabel'
import OwnerPopover from '@app/next/ownerPopover'
import CollapsedNodes from '@app/pages/maps/components/nodes/components/collapsedNodes'
import { scaleSizeToMapZoom } from '@app/pages/maps/components/nodes/helpers'
import cardAvatarSize from '@app/shared/cards/cardAvatarSize'
import cardFontSize from '@app/shared/cards/cardFontSize'
import CardDetailsLink from '@app/shared/cards/components/cardDetailsLink'
import CardSection from '@app/shared/cards/components/cardSection'
import CardTitle from '@app/shared/cards/components/cardTitle'
import CardTypeSection from '@app/shared/cards/components/cardTypeSection'
import CardEntityContributorsAndStatus, {
  shouldRenderCardEntityContributorsAndStatus
} from '@app/shared/cards/entity/cardEntityContributorsAndStatus'
import CardEntityStatsAndProgress, {
  shouldRenderCardEntityStatsAndProgress
} from '@app/shared/cards/entity/cardEntityStatsAndProgress'
import CardEntityStatus from '@app/shared/cards/entity/cardEntityStatus'
import MarkdownDisplay from '@app/shared/markdownDisplay'
import PulsingCircle from '@app/shared/pulsingCircle'
import { withBasicProvider } from '@app/shared/utils/withProviders'
import type { MapDomainEntity, MapDomainNode } from '@app/types'
import ICON_MAP, { COLOR_MAP } from '@app/utils/iconMap'

interface Props extends BoxProps {
  entity?: MapDomainEntity
  node?: MapDomainNode
  onClick?: () => void
  onDoubleClick?: () => void
}

export const EntityCard: FC<Props> = ({ entity, node, ...rest }) => {
  const { strategyId } = useParams()
  const isAnonymousUser = useIsAnonymousUser(strategyId)
  const { editing, size } = useCardContext()
  const { zoom } = useViewport()
  const {
    id,
    sourceName,
    dueDate,
    goalHypothesis,
    recentActivity,
    linkUrl,
    foreignState,
    workflowState,
    labels,
    aiGenerated
  } = entity

  const route = isAnonymousUser ? null : `/strategy/${strategyId}/map/entity/${id}`

  const pulseSize = scaleSizeToMapZoom(24, zoom, 44)
  const avatarSize = cardAvatarSize('xs', size)
  const fontSize = cardFontSize('sm', size)

  const boxProps: BoxProps = {}
  if (aiGenerated) {
    boxProps.boxShadow = aiInsightsShadow
  }

  return (
    <Card {...boxProps} {...rest}>
      <input type="hidden" name="sourceName" value={sourceName || 'web'} />
      <CardTypeSection title="Work" icon={ICON_MAP.Work} color={COLOR_MAP.Entity} route={route} size={size} />
      <CardSection size={size}>
        <PulsingCircle isActive={!!recentActivity} size={pulseSize} link={`entity/${id}/events`} />
        <Stack spacing={size === 'large' ? 6 : 2}>
          <CardTitle domainObject={entity} name="name" size={size} />
          {/* {summary && <MarkdownDisplay text={summary} />} */}
          {goalHypothesis && (
            <WithLabel showLabel={!!goalHypothesis} label="Goal / Hypothesis">
              <MarkdownDisplay text={goalHypothesis} fontSize={fontSize} />
            </WithLabel>
          )}
          {/* {results && <MarkdownDisplay text={results} />} */}
          {linkUrl && <CardDetailsLink sourceUrl={linkUrl} size={size} />}
        </Stack>
      </CardSection>
      {shouldRenderCardEntityStatsAndProgress(entity) && (
        <CardSection size={size}>
          <CardEntityStatsAndProgress entity={entity} size={size} />
        </CardSection>
      )}

      {shouldRenderCardEntityContributorsAndStatus(entity) && (
        <CardSection size={size}>
          <CardEntityContributorsAndStatus entity={entity} size={size} />
        </CardSection>
      )}
      {dueDate && (
        <CardSection size={size}>
          <CardDueDate domainObject={entity} fieldName="dueDate" size={size} />
        </CardSection>
      )}
      {labels?.length && <CardLabels fieldName="labels" domainObject={entity} size={size} />}
      <CardSection size={size}>
        <HStack spacing={3}>
          <OwnerPopover object={entity} fontSize={fontSize} avatarProps={{ size: avatarSize }} showName />
          <Spacer />
          <CardEntityStatus status={foreignState} workflowState={workflowState} size={size} editing={editing} />
          <ConfidenceRatingPopover domainObject={entity} fontSize={fontSize} size={size} />
        </HStack>
      </CardSection>
      {node && <CollapsedNodes node={node} />}
    </Card>
  )
}

export const EntityCardWithContext = withBasicProvider(CardProvider)(EntityCard)
