import { chakra } from '@chakra-ui/react'
import type { EdgeProps } from '@xyflow/react'
import { EdgeText } from '@xyflow/react'
import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import CorrelationPopover from '@app/pages/correlations/components/correlationPopover'

interface Props
  extends Pick<
    EdgeProps,
    'labelStyle' | 'labelShowBg' | 'labelBgStyle' | 'labelBgPadding' | 'labelBgBorderRadius' | 'data' | 'style'
  > {
  x: number
  y: number
  color: string
  correlationScore?: number | string
  sourceId?: string
  targetId?: string
}

const CorrelationLabel: FC<Props> = ({
  x,
  y,
  labelBgStyle,
  labelStyle,
  labelShowBg,
  labelBgPadding,
  labelBgBorderRadius,
  style,
  correlationScore = null,
  sourceId = '',
  targetId = '',
  color
}) => {
  const navigate = useNavigate()

  // const { color } = correlationScoreColor(correlationScore, flipColors)
  let onClick = () => {}
  let onClickLabelStyle = labelStyle
  let onClickLabelBgStyle = labelBgStyle
  let rawLabelBgStyle = labelBgStyle

  if (!correlationScore || !sourceId || !targetId) {
    return null
  }

  if (correlationScore || typeof correlationScore === 'number') {
    onClick = () => {
      navigate(`/correlations/graph/${sourceId}/${targetId}`)
    }

    onClickLabelStyle = { ...labelStyle, cursor: 'pointer' }
    rawLabelBgStyle = {
      fill: color,
      ...style
    }
    onClickLabelBgStyle = { ...rawLabelBgStyle, cursor: 'pointer' }
  }

  return (
    <CorrelationPopover onClick={onClick} portaled>
      <chakra.svg overflow="visible">
        <EdgeText
          x={x}
          y={y}
          label={correlationScore}
          labelStyle={onClickLabelStyle}
          labelShowBg={labelShowBg}
          labelBgStyle={onClickLabelBgStyle}
          labelBgPadding={labelBgPadding}
          labelBgBorderRadius={labelBgBorderRadius}
        />
      </chakra.svg>
    </CorrelationPopover>
  )
}

export default CorrelationLabel
