import { HStack, Spacer, Stack, Text } from '@chakra-ui/react'
import { $convertFromMarkdownString, TRANSFORMERS } from '@lexical/markdown'
import type { LexicalEditor } from 'lexical'
import type { FC } from 'react'
import { useEffect, useRef } from 'react'

import ChangelogSection from './changelog/changelogSection'
import ReportPeriod from './reportPeriod'

import RichTextInput from '@app/next/forms/inline/richTextInput'
import ViewerFieldWrapper from '@app/next/forms/inline/support/viewerFieldWrapper'
import TextAreaInput from '@app/next/forms/inline/textAreaInput'
import StandardSection from '@app/pages/reports/components/standard/standardSection'
// import MarkdownDisplay from '@app/shared/markdownDisplay'
import type { MapDomainReport, MapDomainStrategy } from '@app/types'

type Props = {
  report: MapDomainReport
  strategy: MapDomainStrategy
}

const ReportContent: FC<Props> = ({ report, strategy }) => {
  const summary = report?.summary
  const summaryRef = useRef<LexicalEditor>(null)

  // Update the summary when it changes in the background, so AI pipelines can update it.
  useEffect(() => {
    if (
      summary?.length > 0 &&
      summaryRef.current?.isEditable() &&
      // do not update if the editor is focused, as it makes editing it inline nearly impossible.
      summaryRef.current.getRootElement() !== document.activeElement
    ) {
      summaryRef.current.update(() => {
        $convertFromMarkdownString(summary, TRANSFORMERS)
      })
    }
  }, [summary])

  return (
    <Stack px={8} py={10} bgColor="bg.surface">
      <Stack mb={3} pb={2} borderBottom="1px" borderBottomColor="border.default">
        <TextAreaInput
          domainObject={report}
          name="name"
          title={report.name}
          fontWeight="bold"
          wordBreak="break-word"
          fontSize="2xl"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.currentTarget.blur()
            }
          }}
        />

        <HStack>
          <Text color="subtle" fontWeight="bold" size="sm">
            {strategy.name}
          </Text>
          <Spacer />
          <ReportPeriod report={report} />
        </HStack>
        {/* <MarkdownDisplay text={report?.summary} /> */}

        <ViewerFieldWrapper value={report?.summary}>
          <RichTextInput domainObject={report} name="summary" placeholder="Summary..." ref={summaryRef} />
        </ViewerFieldWrapper>
      </Stack>
      {/* This is intended to be a "section" concept that handles the standard styles of spacing */}
      <StandardSection report={report} />
      <ChangelogSection report={report} />
    </Stack>
  )
}

export default ReportContent
