import {
  Box,
  Button,
  Divider,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack
} from '@chakra-ui/react'
import type { FC } from 'react'

import CorrelationScoreDescription from '@app/pages/correlations/components/correlationScoreDescription'

interface Props {
  children: React.ReactNode
  onClick?: () => void
  portaled?: boolean
}

const CorrelationPopover: FC<Props> = ({ children, onClick = () => {}, portaled = false }) => {
  const PortalComponent = portaled ? Portal : Box

  return (
    <Popover isLazy trigger="hover">
      <PopoverTrigger>{children}</PopoverTrigger>
      <PortalComponent>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Correlation</PopoverHeader>
          <PopoverBody>
            <Stack divider={<Divider />} spacing={4}>
              <CorrelationScoreDescription />
              <Button onClick={onClick} variant="primary">
                See comparison graph
              </Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </PortalComponent>
    </Popover>
  )
}

export default CorrelationPopover
