import { useRouteLoaderData } from 'react-router-dom'

import type { ReportChangelogEntityQuery } from '@graphql/queries'

type ChangelogEntity = ReportChangelogEntityQuery['entity']
type UseChangelogEntity = (routeId?: string) => ChangelogEntity | undefined

const useChangelogEntity: UseChangelogEntity = (routeId: 'changelogEntity') => {
  const { entity } = (useRouteLoaderData(routeId) || {}) as {
    entity?: ChangelogEntity
  }

  return entity
}

export default useChangelogEntity
