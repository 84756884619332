import { MenuItem, Spinner } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import useGetObject from '@app/hooks/useGetObject'
import { usePipelineRunMutation } from '@graphql/queries'
import { PipelineEnum } from '@graphql/types'

const RunReportSummarizer = ({ report }) => {
  const [, runPipeline] = usePipelineRunMutation()
  const [chatId, setChatId] = useState(null)
  const [isDisabled, setIsDisabled] = useState(false)
  const chat = useGetObject(chatId, 'chat')
  const processedState = chat?.processedState

  useEffect(() => {
    setIsDisabled(processedState === 'processing')
  }, [processedState])

  const onClick = async () => {
    setIsDisabled(true)

    const response = await runPipeline({
      input: { chattable: { report: { id: report.id } }, pipelineId: PipelineEnum.ReportSummarizer }
    })

    setChatId(response.data.pipelineRun.chat?.id)
  }

  return (
    <MenuItem isDisabled={isDisabled} onClick={onClick}>
      Report Summarizer
      {isDisabled && <Spinner ml={2} size="sm" />}
    </MenuItem>
  )
}

export default RunReportSummarizer
