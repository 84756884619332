import type { Node } from '@xyflow/react'
import cloneDeep from 'lodash/cloneDeep'

export const getPositionValue = (
  xNodesPosition: number[],
  yNodesPosition: number[],
  xNodesPositionPlusWidth: number[],
  yNodesPositionPlusHeight: number[],
  alignType: AlignType
) => {
  switch (alignType) {
    case 'left':
      return Math.min(...xNodesPosition)

    case 'right':
      return Math.max(...xNodesPositionPlusWidth)

    case 'centerHorizontal':
      return (Math.max(...xNodesPositionPlusWidth) + Math.min(...xNodesPosition)) / 2

    case 'top':
      return Math.min(...yNodesPosition)

    case 'bottom':
      return Math.max(...yNodesPositionPlusHeight)

    case 'centerVertical':
      return (Math.max(...yNodesPositionPlusHeight) + Math.min(...yNodesPosition)) / 2

    default:
      return 0
  }
}

export const align = <T extends Node = Node>(alignType: AlignType, selectedNodes: T[]): T[] => {
  const nodes = cloneDeep(selectedNodes)

  const getXPosition = nodes.map((item: Node) => item.position.x)
  const getXPositionWithWidth = nodes.map((item: Node) => item.position.x + item.width)
  const getYPositionWithHeight = nodes.map((item: Node) => item.position.y + item.height)
  const getYPosition = nodes.map((item: Node) => item.position.y)

  const newPositionValue = getPositionValue(
    getXPosition,
    getYPosition,
    getXPositionWithWidth,
    getYPositionWithHeight,
    alignType
  )
  const updatedNodes = []

  selectedNodes.forEach((node: Node) => {
    const ifIsRight = newPositionValue - node.width
    const ifIsBottom = newPositionValue - node.height
    const ifIsCenterHorizontal = newPositionValue - node.width / 2
    const ifIsCenterVertical = newPositionValue - node.height / 2

    switch (alignType) {
      case 'left':
        updatedNodes.push({
          id: node.id,
          position: { ...node.position, x: newPositionValue }
        })
        break
      case 'right':
        updatedNodes.push({
          id: node.id,
          position: { ...node.position, x: ifIsRight }
        })
        break
      case 'top':
        updatedNodes.push({
          id: node.id,
          position: { ...node.position, y: newPositionValue }
        })
        break
      case 'bottom':
        updatedNodes.push({
          id: node.id,
          position: { ...node.position, y: ifIsBottom }
        })
        break
      case 'centerHorizontal':
        updatedNodes.push({
          id: node.id,
          position: { ...node.position, x: ifIsCenterHorizontal }
        })
        break
      case 'centerVertical':
        updatedNodes.push({
          id: node.id,
          position: { ...node.position, y: ifIsCenterVertical }
        })
        break
      default:
        break
    }
  })

  return updatedNodes
}

export const getSortedNodesByComparator = (nodes: Node[], comparator: (previous: Node, current: Node) => number) =>
  cloneDeep(nodes).sort((previous: Node, current: Node) => comparator(previous, current))

export const handleDistribution = (axis: Axis, selectedNodes: Node[]) => {
  const updatedNodes = []

  const sortedNodes = getSortedNodesByComparator(selectedNodes, (previous: Node, current: Node) =>
    previous.position[axis] < current.position[axis] ? -1 : 1
  )

  const position0 = []
  const position1 = []
  const distance = []

  for (let i = 0; i < sortedNodes.length - 1; i += 1) {
    position0.push(sortedNodes[i].position[axis] + sortedNodes[i].width)
  }

  for (let i = 1; i < sortedNodes.length; i += 1) {
    position1.push(sortedNodes[i].position[axis])
  }

  position0.forEach((item, i) => {
    distance.push(position1[i] - position0[i])
  })

  const average = distance.reduce((a, b) => a + b, 0) / distance.length

  sortedNodes
    .map((node, i) => {
      if (i === 0) {
        return node
      }

      const data = {
        ...node,
        position: {
          ...node.position,
          [axis]: position0[i - 1] + average
        }
      }
      position0[i] = position0[i - 1] + average + sortedNodes[i].width
      return data
    })
    .forEach((node) => {
      updatedNodes.push({ ...node, position: { ...node.position, [axis]: node.position[axis] } })
    })

  return updatedNodes
}
