import { Stack, Text, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'

import cardFontSize from '@app/shared/cards/cardFontSize'
import { useCardContext } from '@app/shared/cards/contexts/cardContext'
import { SelectInput } from '@app/shared/rawForms'
import type { CardSize } from '@app/types'
import { RollUpEnum } from '@graphql/queries'

const rollupDisplayName = (type: RollUpEnum) => {
  let displayType
  let tooltipText

  switch (type) {
    case RollUpEnum.Sum:
      displayType = 'Sum'
      tooltipText = displayType
      break
    case RollUpEnum.LastMonthAvailable:
      displayType = 'LMA'
      tooltipText = 'MoM, month over 3-month average, YoY'
      break
    case RollUpEnum.Average:
      displayType = 'Average'
      tooltipText = displayType
      break
    case RollUpEnum.Increase:
      displayType = 'Amount increased'
      tooltipText = displayType
      break
    case RollUpEnum.WeightedAverage:
      displayType = 'Weighted average'
      tooltipText = displayType
      break
    default:
      displayType = ''
      tooltipText = displayType
  }

  return [displayType, tooltipText]
}

interface Props {
  type?: RollUpEnum
  fieldName?: string
  size?: CardSize
}

const CardRollupType: FC<Props> = ({ type, fieldName, size = 'normal' }) => {
  const { editing } = useCardContext()
  const [displayType, tooltipText] = rollupDisplayName(type)

  if (editing) {
    return (
      <Stack>
        <Text fontSize="xs" fontWeight="bold">
          Roll up
        </Text>
        <SelectInput name={fieldName} defaultValue={type} placeholder="">
          <option value="sum">Sum</option>
          <option value="average">Average</option>
          <option value="increase">Amount increased</option>
          <option value="weighted_average">Weighted average</option>
          <option value="last_month_available">Last month available</option>
        </SelectInput>
      </Stack>
    )
  }

  if (!type) {
    return null
  }

  const fontSize = cardFontSize('sm', size)

  return (
    <Tooltip aria-label="Roll up" label={tooltipText}>
      <Text color="muted" fontSize={fontSize}>
        {displayType}
      </Text>
    </Tooltip>
  )
}

export default CardRollupType
