import { Box } from '@chakra-ui/react'
import type { FC } from 'react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import MembersListActions from '@app/pages/settings/organization/components/membersListActions'
import type { User } from '@graphql/types'

interface Props {
  data: User
}

const UserActionsCell: FC<Props> = ({ data: member }) => {
  const { id } = member
  const { user } = useStoreCurrentUser()

  if (user.id === id) {
    return <Box m={2}>&nbsp;</Box>
  }

  return <MembersListActions member={member} />
}

export default UserActionsCell
