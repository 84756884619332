import {
  HStack,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  useDisclosure
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef } from 'react'
import { PiCaretDown } from 'react-icons/pi'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import WorkflowStateIcon from '@app/next/workflowStateIcon'
import { SearchList, SearchListItem } from '@app/shared/searchList'
import { useStore } from '@app/store'
import type { Entity } from '@graphql/types'
import { EntityWorkflowStateEnum } from '@graphql/types'

interface Props {
  entity: Entity
}

const WorkflowStatePopover: FC<Props> = ({ entity }) => {
  const { user } = useStoreCurrentUser()
  const initialFocusRef = useRef()
  const { isOpen, onToggle, onClose } = useDisclosure()
  const updateObject = useStore.use.updateObject()
  const { id, workflowState } = entity

  if (user?.role !== 'editor') {
    return <WorkflowStateIcon workflowState={workflowState} />
  }

  const handleItemClick = (value: string) => {
    updateObject({ entity: { id, foreignState: value as EntityWorkflowStateEnum } })

    onToggle()
  }

  return (
    <Popover initialFocusRef={initialFocusRef} isLazy isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <HStack cursor="pointer" onClick={onToggle}>
          <WorkflowStateIcon workflowState={workflowState} />
          <Icon as={PiCaretDown} />
        </HStack>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody m={0} p={0}>
          <SearchList
            searchField="status"
            currentValue={workflowState}
            initialFocusRef={initialFocusRef}
            onChange={handleItemClick}
          >
            <SearchListItem
              icon={<WorkflowStateIcon workflowState={EntityWorkflowStateEnum.NotStarted} aria-label="Not started" />}
              text="Not started"
              value={EntityWorkflowStateEnum.NotStarted}
            />
            <SearchListItem
              icon={<WorkflowStateIcon workflowState={EntityWorkflowStateEnum.Started} aria-label="Started" />}
              text="Started"
              value={EntityWorkflowStateEnum.Started}
            />
            <SearchListItem
              icon={<WorkflowStateIcon workflowState={EntityWorkflowStateEnum.Reviewing} aria-label="Reviewing" />}
              text="Reviewing"
              value={EntityWorkflowStateEnum.Reviewing}
            />
            <SearchListItem
              icon={<WorkflowStateIcon workflowState={EntityWorkflowStateEnum.Finished} aria-label="Finished" />}
              text="Finished"
              value={EntityWorkflowStateEnum.Finished}
            />
          </SearchList>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default WorkflowStatePopover
