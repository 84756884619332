import { Show, Stack, Text, VStack } from '@chakra-ui/react'
import pluralize from 'pluralize'
import type { FC } from 'react'

import AssociatedMetricCard from '@app/pages/roadmaps/cards/associatedMetricCard'
import { MIN_COLUMN_WIDTH } from '@app/pages/roadmaps/shared'
import type { MapDomainMetric } from '@app/types'
import type { Metric } from '@graphql/types'

interface MetricsColumnProps {
  metrics: MapDomainMetric[]
  expanded?: boolean
}

const MetricsColumn: FC<MetricsColumnProps> = ({ metrics, expanded = true }) => {
  if (!expanded) {
    return (
      <Stack mr={1} p={4} borderColor="border.emphsized" bgColor="bg.surface">
        <Text color="fg.subtle" fontSize="sm" fontWeight="semibold">
          {metrics.length} {pluralize('metric', metrics.length)}
        </Text>
      </Stack>
    )
  }

  return (
    <VStack
      align="start"
      minW={MIN_COLUMN_WIDTH}
      mr={1}
      p={4}
      bg="bg.surface"
      borderColor="border.emphasized"
      spacing={3}
    >
      <Show below="lg">
        <Text fontSize="md" fontWeight="semibold">
          Metrics / Inputs
        </Text>
      </Show>
      {metrics.map((metric) => (
        <AssociatedMetricCard key={metric.id} domainObject={metric as Metric} />
      ))}
    </VStack>
  )
}

export default MetricsColumn
