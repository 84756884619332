import { Center, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import useGetStrategyObjects from '@app/hooks/useGetStrategyObjects'
import CardList from '@app/pages/roadmaps/cardList'

interface Props {}

const AddCardDrawerEmptyState: FC<Props> = () => {
  const { strategyId } = useParams()
  const objects = useGetStrategyObjects(strategyId)

  if (!objects?.length) {
    return (
      <Center h="50vh" data-testid="existing-cards-list-no-fetched-data">
        <Text align="center" color="subtle">
          Use the filters above to search for existing cards.
        </Text>
      </Center>
    )
  }

  return <CardList objects={objects} />
}

export default AddCardDrawerEmptyState
