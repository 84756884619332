import { Icon, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import type { IconType } from 'react-icons'

import { METRIC_SOURCES } from '@app/lib/globals'
import type { CardSize } from '@app/types'

interface Props {
  sourceName?: string
  icon?: IconType
  color?: string
  size?: CardSize
}

const CardSource: FC<Props> = ({ sourceName, icon: iconOverride, color: colorOverride, size = 'normal' }) => {
  const { icon, color, display } = METRIC_SOURCES[sourceName] || {}

  if (!sourceName) {
    return null
  }

  const boxSize = size === 'large' ? 6 : 4

  return (
    <Tooltip label={display}>
      {/* this span lets the tooltip sit on the element and not push it around on the card */}
      <span style={{ display: 'flex' }}>
        <Icon as={iconOverride || icon} boxSize={boxSize} m={0} p={0} color={colorOverride || color} />
      </span>
    </Tooltip>
  )
}

export default CardSource
