import { Panel } from '@xyflow/react'
import type { FC } from 'react'
import { memo } from 'react'

import ButtonWrapper from './nav/buttonWrapper'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import AddCardMenu from '@app/pages/maps/components/map/addCardMenu'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import Can from '@app/shared/authorization/can'

const MapMenu: FC = () => {
  const { user } = useStoreCurrentUser()
  const { canEdit } = usePermissionsContext()

  if (!canEdit || !user) {
    return null
  }

  return (
    <Can I="update" a="strategy">
      <Panel position="top-left" style={{ marginTop: '24px' }}>
        <ButtonWrapper>
          <AddCardMenu />
        </ButtonWrapper>
      </Panel>
    </Can>
  )
}

MapMenu.displayName = 'MapMenu'

export default memo(MapMenu)
