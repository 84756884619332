import type { TextProps } from '@chakra-ui/react'
import {
  Box,
  Divider,
  HStack,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Portal,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import type { FC } from 'react'
import { useMemo, useRef } from 'react'
import { PiCaretDown, PiPlaceholder } from 'react-icons/pi'

import useCookie from '@app/hooks/useCookie'
import useGetObject from '@app/hooks/useGetObject'
import useGetObjects from '@app/hooks/useGetObjects'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { BASIC_CARD_TYPE_COOKIE } from '@app/lib/globals'
import BasicTypeModal from '@app/pages/maps/components/nodes/components/basicType/basicTypeModal'
import { iconMap } from '@app/pages/maps/components/nodes/components/basicType/customIcons'
import { SearchList, SearchListItem } from '@app/shared/searchList'
import { useStore } from '@app/store'
import type { MapDomainBasicCard } from '@app/types'

interface Props extends TextProps {
  basicCard: Pick<MapDomainBasicCard, 'id' | 'cardTypeId'>
}

const useSortedCardTypes = () => {
  const cardTypes = useGetObjects('cardType')
  return useMemo(() => cardTypes.sort((a, b) => a.name.localeCompare(b.name)), [cardTypes])
}

const BasicCardTypePopover: FC<Props> = ({ basicCard, fontSize = 'md', boxSize = 5 }) => {
  const [, setCardTypeId] = useCookie(BASIC_CARD_TYPE_COOKIE)
  const { user } = useStoreCurrentUser()
  const cardTypes = useSortedCardTypes()
  const initialFocusRef = useRef()
  const { isOpen, onToggle, onClose } = useDisclosure()
  const disclosure = useDisclosure()
  const updateObject = useStore.use.updateObject()
  const { id, cardTypeId } = basicCard
  const cardType = useGetObject(cardTypeId, 'cardType')
  const IconComponent = cardType?.icon ? iconMap[cardType?.icon] : PiPlaceholder
  const elementColor = cardType?.color ? `${cardType.color}.500` : 'gray.500'

  const viewElement = (
    <HStack>
      <Icon as={IconComponent} boxSize={boxSize} color={elementColor} />
      <Text color={elementColor} fontSize={fontSize} fontWeight="semibold">
        {capitalize(cardType?.name || '')}
      </Text>
    </HStack>
  )

  if (user?.role !== 'editor') {
    return viewElement
  }

  const handleItemClick = (value: string) => {
    setCardTypeId(value) // Set the BASIC_CARD_TYPE_COOKIE so we can remember the last selected type

    updateObject({ basicCard: { id, cardTypeId: value } })

    onToggle()
  }

  const handleManageClick = () => {
    disclosure.onOpen()
    onToggle()
  }

  const handleBasicTypeModalClose = () => {
    disclosure.onClose()
  }

  return (
    <>
      <BasicTypeModal cardTypes={cardTypes} {...disclosure} onClose={handleBasicTypeModalClose} />
      <Popover initialFocusRef={initialFocusRef} isLazy isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>
          <HStack cursor="pointer" onClick={onToggle} spacing={1}>
            {viewElement}
            <Icon as={PiCaretDown} color={elementColor} />
          </HStack>
        </PopoverTrigger>
        <Portal>
          <PopoverContent maxW={60}>
            <PopoverArrow />
            <PopoverBody m={0} p={0}>
              <SearchList
                searchField="type"
                currentValue={cardType?.id}
                initialFocusRef={initialFocusRef}
                onChange={handleItemClick}
              >
                <SearchListItem text="Manage types..." value={null} onClick={handleManageClick} />
                <SearchListItem
                  text="None"
                  value={null}
                  icon={<Icon as={PiPlaceholder} boxSize={boxSize} color="gray.500" />}
                />
                <Divider my={2} />
                {cardTypes.map((types) => (
                  <SearchListItem
                    key={types.id}
                    icon={
                      types.icon ? (
                        <Icon
                          as={iconMap[types.icon]}
                          boxSize={boxSize}
                          color={types?.color ? `${types.color}.500` : 'gray.500'}
                        />
                      ) : (
                        <Box pr={boxSize} />
                      )
                    }
                    text={capitalize(types.name)}
                    value={types.id}
                    color={types.color}
                  />
                ))}
              </SearchList>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  )
}

export default BasicCardTypePopover
