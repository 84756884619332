import type { ComponentWithAs, IconButtonProps } from '@chakra-ui/react'
import { Text, Link, forwardRef, IconButton, Stack } from '@chakra-ui/react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import useToast from '@app/shared/toast'
import ICON_MAP from '@app/utils/iconMap'
import { useMapCloneToOrganizationMutation } from '@graphql/queries'

type Props = Omit<IconButtonProps, 'aria-label'>

const CopyMapToDoubleLoopButton: ComponentWithAs<'button', Props> = forwardRef((props, ref) => {
  const [, copyMap] = useMapCloneToOrganizationMutation()
  const { strategyId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const onClick = async () => {
    setIsLoading(true)

    try {
      const resp = await copyMap({
        input: {
          strategyId,
          organizationId: 'doubleloop'
        }
      })

      const { strategyId: newStrategyId, strategyName: newStrategyName } = resp.data.mapCloneToOrganization

      toast({
        title: 'Map copied to DoubleLoop organization',
        status: 'success',
        duration: 10000,
        description: (
          <Stack>
            <Text>The map {newStrategyName} has been created in the DoubleLoop organization.</Text>
            <Link fontSize="xl" href={`/strategy/${newStrategyId}`} isExternal>
              View map
            </Link>
          </Stack>
        )
      })
    } catch (error) {
      toast({
        title: 'Error copying map',
        status: 'error',
        description: error.message
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <IconButton
      aria-label="Copy map to DoubleLoop Organization"
      icon={<ICON_MAP.Copy />}
      {...props}
      ref={ref}
      isLoading={isLoading}
      // the wrapping tooltip in addCardMenu.tsx is sending a silent onClick
      // prop in the ...props spread, so we have to override that
      onClick={onClick}
    />
  )
})

export default CopyMapToDoubleLoopButton
