import { Flex, HStack, Spacer, Stack, Text } from '@chakra-ui/react'
import { $convertFromMarkdownString, TRANSFORMERS } from '@lexical/markdown'
import type { LexicalEditor } from 'lexical'
import type { FC } from 'react'
import { useCallback, useState, memo, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import RichTextInputWithLabel from '@app/next/forms/inline/richTextInputWithLabel'
import ViewerFieldWrapper from '@app/next/forms/inline/support/viewerFieldWrapper'
import TextInput from '@app/next/forms/inline/textInput'
import { displayNameAndType } from '@app/pages/maps/components/nodes/helpers'
import PopulateEdgeInsightsButton from '@app/pages/maps/components/populateEdgeInsightsButton'
import { useDrawerContext } from '@app/pages/maps/drawerContext'
import Drawer from '@app/shared/drawer/drawer'
import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'
import { useStore } from '@app/store'
import type { MapDomainEdge } from '@app/types'

type DetailsProps = {
  edge: MapDomainEdge
}

const InsightsDetails: FC<DetailsProps> = ({ edge }) => {
  const [error, setError] = useState<string>(null)
  const { id, insightsDetails } = edge
  const getNodeById = useStore.use.getNodeById()
  const source = getNodeById(edge?.source)
  const target = getNodeById(edge?.target)

  const detailsRef = useRef<LexicalEditor>(null)
  const showPopulateInsights = source.data.classType === 'metric' && target.data.classType === source.data.classType

  // Update the details when they change in the background, so AI pipelines can update it.
  useEffect(() => {
    if (
      insightsDetails?.length > 0 &&
      detailsRef.current?.isEditable() &&
      // do not update if the editor is focused, as it makes editing it inline nearly impossible.
      detailsRef.current.getRootElement() !== document.activeElement
    ) {
      detailsRef.current.update(() => {
        $convertFromMarkdownString(insightsDetails, TRANSFORMERS)
      })
    }
  }, [insightsDetails])

  const onClick = useCallback(() => {
    setError(null)
  }, [])

  const onError = useCallback((err: Error) => {
    setError(err.message)
  }, [])

  return (
    <ViewerFieldWrapper value={edge.insightsDetails}>
      <Flex justify="space-between">
        <RichTextInputWithLabel
          domainObject={edge}
          name="insightsDetails"
          placeholder="Insights..."
          label="Insights"
          domainObjectUpdateId="edgeId"
          ref={detailsRef}
        />
        {showPopulateInsights && (
          <PopulateEdgeInsightsButton w="fit-content" edgeId={id} onClick={onClick} onRequestError={onError} />
        )}
        {error && (
          <Text color="error" fontSize="xs">
            {error}
          </Text>
        )}
      </Flex>
    </ViewerFieldWrapper>
  )
}

const EdgeDrawer: FC = () => {
  const { edgeId } = useParams()
  const { onDrawerClose } = useDrawerContext()
  const getNodeById = useStore.use.getNodeById()

  const edge = useGetObject(edgeId, 'edge')
  const { insights, color } = edge

  const source = getNodeById(edge?.source)
  const target = getNodeById(edge?.target)

  const sourceDisplay = displayNameAndType(source.data)
  const targetDisplay = displayNameAndType(target.data)

  return (
    <Drawer onClose={onDrawerClose} isOpen>
      <Stack as="section" overflow="auto" h="100%" id="lazyload-container" spacing={0}>
        <Stack p={4}>
          <HStack>
            <Text fontWeight="semibold">
              {sourceDisplay.name} -- {targetDisplay.name}
            </Text>
            <Spacer />
            <DrawerCloseButton variant="ghost" />
          </HStack>
          <ViewerFieldWrapper value={edge.insights}>
            <TextInput
              domainObject={edge}
              name="insights"
              placeholder="Insights title"
              domainObjectUpdateId="edgeId"
              defaultValue={insights || undefined}
              fontWeight="bold"
            />
          </ViewerFieldWrapper>
          <InsightsDetails edge={edge} />
          <ViewerFieldWrapper value={edge.color}>
            <TextInput
              domainObject={edge}
              name="color"
              defaultValue={color || undefined}
              placeholder="Color"
              domainObjectUpdateId="edgeId"
            />
          </ViewerFieldWrapper>
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default memo(EdgeDrawer)
