import { Button, HStack, Icon, Spacer, Stack, StackDivider } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'
import TextAreaInput from '@app/next/forms/inline/textAreaInput'
import WithLabel from '@app/next/forms/withLabel'
import OwnerPopover from '@app/next/ownerPopover'
import { DomainObjectType } from '@app/shared/cards/components/cardTypeSection'
import MarkdownDisplay from '@app/shared/markdownDisplay'
import type { MapDomainObject } from '@app/types'
import ICON_MAP, { COLOR_MAP } from '@app/utils/iconMap'
import type { DomainObject, Entity } from '@graphql/types'

interface Props {
  domainObject: Partial<Entity>
  actionsChildren?: React.ReactNode
}

const EntityCard: FC<Props> = ({ domainObject, actionsChildren, ...rest }) => {
  const { strategyId } = useParams()
  const navigate = useNavigate()
  const route = `/strategy/${strategyId}/roadmap/entity/${domainObject.id}`
  const isAnonymousUser = useIsAnonymousUser(strategyId)

  return (
    <Stack {...rest} divider={<StackDivider />}>
      <>
        <HStack>
          <DomainObjectType icon={ICON_MAP.Work} boxSize={5} color={COLOR_MAP.Entity} title="Work" fontSize="sm" />
          <Spacer />
          {actionsChildren !== null ? actionsChildren : null}
        </HStack>
        <TextAreaInput
          domainObject={domainObject as MapDomainObject}
          color="fg.muted"
          fontWeight="bold"
          wordBreak="break-word"
          name="name"
          fontSize="sm"
          title={domainObject?.name}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.currentTarget.blur()
            }
          }}
        />
        {domainObject?.goalHypothesis && (
          <WithLabel showLabel={!!domainObject?.goalHypothesis} label="Goal / Hypothesis">
            <MarkdownDisplay text={domainObject?.goalHypothesis} fontSize="xs" />
          </WithLabel>
        )}
      </>
      <HStack>
        <OwnerPopover object={domainObject as DomainObject} />
        {!isAnonymousUser && (
          <>
            <Spacer />
            <Button
              color="fg.subtle"
              onClick={() => navigate(route)}
              rightIcon={<Icon as={ICON_MAP.ExternalLink} boxSize={4} color="gray.500" />}
              size="xs"
              variant="ghost"
            >
              Details
            </Button>
          </>
        )}
      </HStack>
    </Stack>
  )
}

export default memo(EntityCard)
