import { Button, HStack, Icon, Spacer, Stack, StackDivider } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'
import TextAreaInput from '@app/next/forms/inline/textAreaInput'
import MetricTypePopover from '@app/next/metricTypePopover'
import OwnerPopover from '@app/next/ownerPopover'
import { DomainObjectType } from '@app/shared/cards/components/cardTypeSection'
import CardMetricGoals from '@app/shared/cards/metric/cardMetricGoals'
import CardStats from '@app/shared/cards/metric/cardStats'
import type { MapDomainMetric } from '@app/types'
import ICON_MAP, { COLOR_MAP } from '@app/utils/iconMap'

interface Props {
  domainObject: MapDomainMetric
}

const AssociatedMetricCard: FC<Props> = ({ domainObject, ...rest }) => {
  const strategyStats = domainObject?.strategyNodeData?.strategyStats
  const hasMetricStats = domainObject?.strategyStats?.some((stat) => stat.total || stat.change) || false
  const hasStrategyStats = strategyStats?.some((stat) => stat.total || stat.change) || false
  const showStats = hasMetricStats || hasStrategyStats
  const { strategyId } = useParams()
  const navigate = useNavigate()
  const route = `/strategy/${strategyId}/roadmap/metric/${domainObject.id}`
  const isAnonymousUser = useIsAnonymousUser(strategyId)

  const goalIds = domainObject?.goals?.map((goal) => goal.id) ?? []

  return (
    <Stack
      w="100%"
      borderWidth={2}
      borderColor="border.default"
      borderRadius="md"
      shadow="md"
      {...rest}
      p={2}
      divider={<StackDivider borderColor="border.default" />}
    >
      <HStack>
        <DomainObjectType icon={ICON_MAP.Metric} boxSize={5} color={COLOR_MAP.Metric} title="Metric /" fontSize="sm">
          <MetricTypePopover metric={domainObject} fontWeight="semibold" fontSize="sm" />
        </DomainObjectType>
        <Spacer />
      </HStack>
      <TextAreaInput
        domainObject={domainObject}
        color="fg.muted"
        fontWeight="bold"
        wordBreak="break-word"
        name="name"
        fontSize="sm"
        title={domainObject?.name}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.currentTarget.blur()
          }
        }}
      />
      {showStats && <CardStats metric={domainObject} size="small" />}
      {goalIds?.length > 0 && <CardMetricGoals metric={domainObject} goalIds={goalIds} size="small" />}
      <HStack>
        <OwnerPopover object={domainObject} />
        {!isAnonymousUser && (
          <>
            <Spacer />
            <Button
              color="fg.subtle"
              onClick={() => navigate(route)}
              rightIcon={<Icon as={ICON_MAP.ExternalLink} boxSize={4} color="gray.500" />}
              size="xs"
              variant="ghost"
            >
              Details
            </Button>
          </>
        )}
      </HStack>
    </Stack>
  )
}

export default memo(AssociatedMetricCard)
