import { HStack, Spacer, Stack } from '@chakra-ui/react'
import type { BoxProps } from '@chakra-ui/react'
import { useViewport } from '@xyflow/react'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import { Card } from '../components'
import CardLabels from '../components/cardLabels'
import { CardProvider, useCardContext } from '../contexts/cardContext'

import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'
import { aiInsightsShadow } from '@app/lib/globals'
import BasicCardTypePopover from '@app/next/basicCardTypePopover'
import ConfidenceRatingPopover from '@app/next/confidenceRatingPopover'
import DomainObjectStatusPopover from '@app/next/domainObjectStatusPopover'
import WithLabel from '@app/next/forms/withLabel'
import OwnerPopover from '@app/next/ownerPopover'
import CollapsedNodes from '@app/pages/maps/components/nodes/components/collapsedNodes'
import { scaleSizeToMapZoom } from '@app/pages/maps/components/nodes/helpers'
import cardAvatarSize from '@app/shared/cards/cardAvatarSize'
import cardFontSize from '@app/shared/cards/cardFontSize'
import cardIconBoxSize from '@app/shared/cards/cardIconBoxSize'
import CardDetailsLink from '@app/shared/cards/components/cardDetailsLink'
import CardSection from '@app/shared/cards/components/cardSection'
import CardTitle from '@app/shared/cards/components/cardTitle'
import CardTypeSection from '@app/shared/cards/components/cardTypeSection'
import MarkdownDisplay from '@app/shared/markdownDisplay'
import PulsingCircle from '@app/shared/pulsingCircle'
import { withBasicProvider } from '@app/shared/utils/withProviders'
import type { MapDomainNode } from '@app/types'
import type { BasicCard as BasicCardType } from '@graphql/types'

interface Props extends BoxProps {
  basicCard?: BasicCardType
  node?: MapDomainNode
  onClick?: () => void
  onDoubleClick?: () => void
}

export const BasicCard: FC<Props> = ({ basicCard, node, ...rest }) => {
  const { strategyId } = useParams()
  const isAnonymousUser = useIsAnonymousUser(strategyId)
  const { size } = useCardContext()
  const { id, description, goalHypothesis, externalUrl, recentActivity, labels, aiGenerated } = basicCard
  const { zoom } = useViewport()

  const route = isAnonymousUser ? null : `/strategy/${strategyId}/map/basicCard/${id}`

  const pulseSize = scaleSizeToMapZoom(24, zoom, 44)
  const fontSize = cardFontSize('md', size)
  const boxSize = cardIconBoxSize(5, size)
  const avatarSize = cardAvatarSize('xs', size)

  const boxProps: BoxProps = {}
  if (aiGenerated) {
    boxProps.boxShadow = aiInsightsShadow
  }

  return (
    <Card {...boxProps} {...rest}>
      <CardTypeSection route={route} size={size}>
        <BasicCardTypePopover basicCard={basicCard} fontSize={fontSize} boxSize={boxSize} />
      </CardTypeSection>
      <CardSection size={size}>
        <PulsingCircle isActive={!!recentActivity} size={pulseSize} link={`basicCard/${id}/events`} />
        <Stack spacing={size === 'large' ? 6 : 2}>
          <CardTitle domainObject={basicCard} name="name" size={size} />
          {description && <MarkdownDisplay text={description} fontSize={fontSize} />}
          {goalHypothesis && (
            <WithLabel showLabel={!!goalHypothesis} label="Goal / Hypothesis">
              <MarkdownDisplay text={goalHypothesis} fontSize={fontSize} />
            </WithLabel>
          )}
          {externalUrl && <CardDetailsLink sourceUrl={externalUrl} size={size} />}
        </Stack>
      </CardSection>
      {labels?.length && <CardLabels fieldName="labels" domainObject={basicCard} size={size} />}
      <CardSection size={size}>
        <HStack>
          <OwnerPopover fontSize={fontSize} object={basicCard} avatarProps={{ size: avatarSize }} showName />
          <Spacer />
          <DomainObjectStatusPopover domainObject={basicCard} size={size} />
          <ConfidenceRatingPopover domainObject={basicCard} fontSize={fontSize} size={size} />
        </HStack>
      </CardSection>
      {node && <CollapsedNodes node={node} />}
    </Card>
  )
}

export const BasicCardWithContext = withBasicProvider(CardProvider)(BasicCard)
