import type { FC } from 'react'

import { SelectInput } from '@app/shared/rawForms'
import type { Props as SelectInputProps } from '@app/shared/rawForms/selectInput'
import { RollUpEnum } from '@graphql/queries'

type Props = Omit<SelectInputProps, 'children'>
const MetricRollUpSelect: FC<Props> = (props) => (
  <SelectInput {...props}>
    <option value={RollUpEnum.Sum}>Sum</option>
    <option value={RollUpEnum.Average}>Average</option>
    <option value={RollUpEnum.Increase}>Amount Increased</option>
    <option value={RollUpEnum.WeightedAverage}>Weighted Average</option>
    <option value={RollUpEnum.LastMonthAvailable}>MoM, month over 3-month average, YoY</option>
  </SelectInput>
)

export default MetricRollUpSelect
