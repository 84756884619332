import { Stack } from '@chakra-ui/react'
import { useCallback } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import Drawer from '@app/shared/drawer/drawer'

const RoadmapDrawer = () => {
  const { strategyId } = useParams()
  const navigate = useNavigate()
  const onDrawerClose = useCallback(() => {
    navigate(`/strategy/${strategyId}/roadmap`)
  }, [])

  const onSuccessHandler = useCallback(() => {
    onDrawerClose()
  }, [onDrawerClose])

  const onCancelHandler = onSuccessHandler

  return (
    <Drawer onClose={onDrawerClose} isOpen>
      <Stack as="section" overflow="auto" h="100%" id="lazyload-container" spacing={0}>
        <Outlet context={[onSuccessHandler, onCancelHandler]} />
      </Stack>
    </Drawer>
  )
}

export default RoadmapDrawer
