import { Stack, Text, useDisclosure } from '@chakra-ui/react'
import type { ChangeEvent, FC } from 'react'
import { useEffect, useState } from 'react'
import { useFetcher } from 'react-router-dom'

import useCookie from '@app/hooks/useCookie'
import { BASIC_CARD_TYPE_COOKIE } from '@app/lib/globals'
import BasicTypeModal from '@app/pages/maps/components/nodes/components/basicType/basicTypeModal'
import { SelectInput } from '@app/shared/rawForms'
import type { CardType } from '@graphql/types'

const MANAGE_KEY = 'd419141f-dc45-4c65-9f9b-4b2b75c5ac84'

type Props = {
  fieldName: string
  type?: string
}

const BasicTypeSelect: FC<Props> = ({ fieldName, type }) => {
  const [, setCardTypeId] = useCookie(BASIC_CARD_TYPE_COOKIE)
  const [value, setValue] = useState(type?.toString() || '')
  const typesFetcher = useFetcher()
  const disclosure = useDisclosure()

  useEffect(() => {
    if (typesFetcher.state === 'idle' && !typesFetcher.data) {
      typesFetcher.load('/card-types')
    }
  }, [typesFetcher])

  // If the user selects the "Manage types..." option, we want to prevent the
  // select element from changing its value. This is because we want to open
  // the type management modal instead.
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === MANAGE_KEY) {
      e.preventDefault()
      e.stopPropagation()

      e.target.value = e.target.getAttribute('data-value')
      disclosure.onOpen()

      return false
    }

    setValue(e.target.value)
    setCardTypeId(e.target.value)

    return true
  }

  // This is a hack to store the current value of the select element so that we
  // can restore it if the user clicks on the "Manage types..." option.
  const storeCurrentValue = (e: ChangeEvent<HTMLSelectElement>) => {
    e.target.setAttribute('data-value', e.target.value)
  }

  const cardTypes = typesFetcher?.data?.cardTypes || []
  const sortedTypes = cardTypes.sort((a: CardType, b: CardType) => {
    const nameOne = (a.name || 'Unnamed type').toLowerCase()
    const nameTwo = (b.name || 'Unnamed type').toLowerCase()

    return nameOne.localeCompare(nameTwo)
  })

  return (
    <Stack>
      <BasicTypeModal cardTypes={cardTypes} {...disclosure} />
      <Text fontSize="sm" fontWeight="bold">
        Type
      </Text>
      <SelectInput
        name={fieldName}
        placeholder={null}
        onChange={handleChange}
        value={value}
        data-value={type?.toString() || ''}
        onFocus={storeCurrentValue}
      >
        <option value="">None</option>
        {sortedTypes.map((cardType: CardType) => (
          <option key={cardType.id} value={cardType.id}>
            {cardType.name || '(Unnamed type)'}
          </option>
        ))}
        <option key={MANAGE_KEY} value={MANAGE_KEY}>
          Manage types...
        </option>
      </SelectInput>
    </Stack>
  )
}

export default BasicTypeSelect
