import { IconButton, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import ColorButton from '@app/pages/shared/colorButton'
import { useStore } from '@app/store'
import type { Color, MapDomainNode } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

interface Props {
  node: Pick<MapDomainNode, 'id' | 'metadata'>
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

const CardColorButton: FC<Props> = ({ node, isOpen, onOpen, onClose }) => {
  const { strategyId } = useParams()
  const updateNode = useStore.use.updateNode()
  const currentColor = node?.metadata?.color || ''

  const { canEdit } = usePermissionsContext()

  if (!canEdit) {
    return null
  }

  const changeColor = (color: Color) => {
    updateNode(strategyId, node.id, { metadata: { ...node.metadata, color } })
    onClose()
  }

  return (
    <ColorButton
      selectedColor={currentColor}
      onColorSelect={changeColor}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      {(props) => (
        <Tooltip label="Card color">
          <IconButton borderRadius="none" aria-label="card color" icon={<ICON_MAP.ColorAction />} {...props} />
        </Tooltip>
      )}
    </ColorButton>
  )
}

export default CardColorButton
