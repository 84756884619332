import type { EdgeProps } from '@xyflow/react'
import { EdgeText } from '@xyflow/react'
import type { FC } from 'react'

interface Props
  extends Pick<
    EdgeProps,
    'labelStyle' | 'labelShowBg' | 'labelBgStyle' | 'labelBgPadding' | 'labelBgBorderRadius' | 'data' | 'style'
  > {
  x: number
  y: number
  insights?: string
  color?: string
}

const InsightLabel: FC<Props> = ({
  x,
  y,
  insights = '',
  color = 'gray',
  labelBgPadding,
  labelBgBorderRadius,
  labelShowBg
}) => {
  const labelStyles = { fill: 'white', fontSize: '16px' }
  const bgStyle = { fill: color }

  if (!insights) {
    return null
  }

  return (
    <EdgeText
      x={x}
      y={y}
      label={insights}
      labelStyle={labelStyles}
      labelShowBg={labelShowBg}
      labelBgStyle={bgStyle}
      labelBgPadding={labelBgPadding}
      labelBgBorderRadius={labelBgBorderRadius}
    />
  )
}

export default InsightLabel
