import { Box, HStack } from '@chakra-ui/react'
import { format } from 'date-fns'
import type { FC } from 'react'

import { DateInput } from '@app/shared/rawForms'
import type { Errors } from '@app/shared/rawForms/useForm'
import type { MapDomainReport } from '@app/types'

interface Props {
  report: MapDomainReport
  errors: Errors
}

const ReportDatesInput: FC<Props> = ({ report, errors }) => {
  const startDateDefault = report?.startDate
  const endDateDefault = report?.endDate || format(new Date(), 'yyyy-MM-dd')

  return (
    <HStack>
      <Box w="50%">
        <DateInput
          labelSize="sm"
          name="startDate"
          label="Start"
          defaultValue={startDateDefault}
          isRequired
          errors={errors}
        />
      </Box>
      <Box w="50%">
        <DateInput labelSize="sm" name="endDate" label="End" defaultValue={endDateDefault} />
      </Box>
    </HStack>
  )
}

export default ReportDatesInput
