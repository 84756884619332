import { Button, FormControl, FormErrorMessage, FormLabel, Input, Stack, VisuallyHiddenInput } from '@chakra-ui/react'
import type { FC } from 'react'
import { useParams, useAsyncValue, useNavigation } from 'react-router-dom'

import AuthProviders from '@app/pages/sessions/components/authProviders'
import PasswordInput from '@app/pages/sessions/components/passwordInput'
import SessionHeader from '@app/pages/sessions/components/sessionHeader'
import { FormAlert } from '@app/shared/forms'
import { Form, TermsAndConditionsToggle, useForm } from '@app/shared/rawForms'
import withAwait from '@app/shared/withAwait'
import type { Invitation } from '@graphql/queries'

type ResolvedInvitation = {
  invitation?: Invitation
}

interface InvitationPromise {
  data?: ResolvedInvitation
}

const SignUp: FC = () => {
  const {
    data: { invitation }
  }: InvitationPromise = useAsyncValue()
  const navigation = useNavigation()
  const { inviteCode } = useParams()
  const pageTitle = inviteCode ? 'Join your Organization' : 'Create your account'
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
  const { errors } = useForm({})

  return (
    <Stack w="full" maxW="md" px={{ base: '4', md: '8' }} py={{ base: '12', md: '48' }} spacing="8">
      <SessionHeader title={pageTitle} subtitle="Already have an account?" linkText="Sign in" linkUrl="/sign_in" />

      <Stack spacing="6">
        <Form method="post" id="sign-up-form">
          <VisuallyHiddenInput defaultValue={inviteCode} name="inviteCode" />
          <VisuallyHiddenInput defaultValue={timeZone} name="timeZone" />

          <Stack spacing="5">
            <FormAlert description={errors?.global?.message} />

            <FormControl id="name" isInvalid={!!errors?.name} isRequired>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input autoComplete="name" id="name" name="name" placeholder="Your name" />
              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id="email" isInvalid={!!errors?.email} isRequired>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                autoComplete="email"
                defaultValue={invitation?.email}
                id="email"
                name="email"
                placeholder="Your work email"
                type="email"
              />
              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            </FormControl>

            <PasswordInput
              name="password"
              label="Password"
              placeholder="Password"
              autoComplete="new-password"
              isRequired
              minLength={8}
              errors={errors}
            />
            <PasswordInput
              name="passwordConfirmation"
              label="Password Confirmation"
              placeholder="Password confirmation"
              autoComplete="new-password"
              isRequired
              minLength={8}
              errors={errors}
            />
            <Stack spacing={4}>
              <TermsAndConditionsToggle name="termsAndConditions" />
            </Stack>
          </Stack>
        </Form>
        <Stack spacing={4}>
          <Button
            form="sign-up-form"
            isLoading={navigation.state === 'submitting' || navigation.state === 'loading'}
            size="md"
            type="submit"
            variant="primary"
          >
            Sign up
          </Button>
          <AuthProviders inviteCode={inviteCode} text="Sign up with" />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default withAwait(SignUp, 'invitation')
