import { Stack, Text } from '@chakra-ui/react'

const CorrelationScoreDescription = ({ ...rest }) => (
  <Stack {...rest} data-testid="correlation-score-description">
    <Text>Correlations are scored from -1 to 1.</Text>
    <Text>Closer to 1 = more correlated,</Text>
    <Text>closer to -1 = more inversely correlated.</Text>
    <Text>0 means that the metrics have no relationship with each other.</Text>
  </Stack>
)

export default CorrelationScoreDescription
