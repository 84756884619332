import type { FC } from 'react'
import { FiArrowLeft } from 'react-icons/fi'

import useChangelogEntity from '../../../../next/changelog/useChangelogEntity'
import LayoutRow from '../../../changelogs/components/layoutRow'
import NavigationButton from '../../../changelogs/components/navigationButton'

import Entity from '@app/shared/entity'
import HeadMetaData from '@app/shared/headMetaData'
import SuspenseLoader from '@app/shared/loaders/suspenseLoader'
import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { pathWithSearchParams } from '@app/utils/routeHelpers'

type Props = {
  routeId?: string
}

const Show: FC<Props> = ({ routeId = 'changelogEntity' }) => {
  const entity = useChangelogEntity(routeId)

  if (!entity) {
    return null
  }

  const entityPath = pathWithSearchParams(`events/${entity.id}`)
  const backButtonPath = pathWithSearchParams('..')

  return (
    <>
      <HeadMetaData
        title={entity.name}
        description={entity.summaryShort}
        url={entityPath}
        imageUrl={entity.thumbnail?.url}
      />

      <LayoutRow pb={8}>
        <Entity entity={entity} />
        <NavigationButton url={backButtonPath} label="Back to overview" leftIcon={<FiArrowLeft />} mt={6} />
      </LayoutRow>
    </>
  )
}

export default withSuspenseWrapper(Show, <SuspenseLoader />)
