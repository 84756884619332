import type { ButtonProps } from '@chakra-ui/react'
import { Icon, MenuItem } from '@chakra-ui/react'
import { type FC } from 'react'
import { FiTrash2 } from 'react-icons/fi'

import ConfirmPopover from '@app/shared/confirmPopover'
import useToast from '@app/shared/toast'
import { useStore } from '@app/store'

interface Props {
  roadmapItemId: string
  strategyId: string
  onDelete?: () => void
  children?: FC<ButtonProps>
}

const DefaultChild: FC<ButtonProps> = (props) => (
  <MenuItem icon={<Icon as={FiTrash2} />} {...props} zIndex={1000000}>
    Delete
  </MenuItem>
)

const RoadmapItemDeleteButton: FC<Props> = ({
  roadmapItemId,
  strategyId,
  onDelete = () => {},
  children = DefaultChild
}) => {
  const toast = useToast()
  const deleteRoadmapItem = useStore.use.deleteRoadmapItem()
  const Child = children || DefaultChild

  const handleDelete = async () => {
    const result = deleteRoadmapItem(strategyId, roadmapItemId)
    toast({ title: 'Deleted swimlane' })
    onDelete()

    return result
  }

  return (
    <ConfirmPopover
      onConfirm={handleDelete}
      header="Delete swimlane"
      body="Are you sure? You can&#39;t undo this action afterwards."
      confirmButtonText="Delete"
    >
      <Child />
    </ConfirmPopover>
  )
}

export default RoadmapItemDeleteButton
