import { createStandaloneToast } from '@chakra-ui/react'
import ahoy from 'ahoy.js'
import isNull from 'lodash/isNull'
import omitBy from 'lodash/omitBy'
import type { RouteObject } from 'react-router-dom'
import { defer, Navigate, Outlet, redirect } from 'react-router-dom'

import Create from '@app/pages/metrics/create'
import List from '@app/pages/metrics/list'
import DataPointCreate from '@app/pages/metrics/metricDataPoints/create'
import DataPointEdit from '@app/pages/metrics/metricDataPoints/edit'
import Show from '@app/pages/metrics/show'
import Tabs from '@app/pages/metrics/tabs'
import MetricCorrelationsTab from '@app/pages/metrics/tabs/metricCorrelationsTab'
import MetricDataList from '@app/pages/metrics/tabs/metricDataList'
import MetricDetails from '@app/pages/metrics/tabs/metricDetails'
import MetricFormTab from '@app/pages/metrics/tabs/metricFormTab'
import credentialsRoutes from '@app/routes/credentials'
import { SharedEventRoutes } from '@app/routes/events'
import goalRoutes from '@app/routes/goals'
import metricSourceRoutes from '@app/routes/metricSources'
import CommentsFetcher from '@app/shared/comments/commentsFetcher'
import { Notification } from '@app/shared/toast'
import extractOrderings from '@app/shared/utils/extractOrderings'
import { useStore } from '@app/store'
import { requiresAuthorization } from '@app/utils/auth'
import { protocolifyLink } from '@app/utils/stringHelpers'
import { Metric, MetricCreate, MetricDelete, MetricUpdate, Metrics } from '@graphql/documents/metric.graphql'
import { MetricDataPoint, MetricDataPoints } from '@graphql/documents/metric_data_point.graphql'
import type {
  MetricDataPointsQuery,
  MetricDataPointsQueryVariables,
  MetricsQuery,
  MetricsQueryVariables
} from '@graphql/queries'

const { toast } = createStandaloneToast()

const parseMetricInput = (input) => {
  const metricInput = { ...input }

  metricInput.minimumDisplayPrecision &&= parseInt(metricInput.minimumDisplayPrecision, 10)
  metricInput.maximumDisplayPrecision &&= parseInt(metricInput.maximumDisplayPrecision, 10)
  metricInput.externalUrl &&= protocolifyLink(metricInput.externalUrl)
  metricInput.labels &&= JSON.parse(metricInput.labels)
  if (metricInput.confidenceRating === '') {
    metricInput.confidenceRating = null
  }

  delete metricInput['slack-channel-autocomplete']

  return metricInput
}

const loadMetrics = async ({ request }) => {
  const url = new URL(request.url)
  const page = parseInt(url.searchParams.get('page'), 10) || 1
  const limit = parseInt(url.searchParams.get('limit'), 10) || null
  const filter = url.searchParams.get('filter')
  const strategyId = url.searchParams.get('strategyId')
  const order = extractOrderings(url.searchParams, ['name', 'metricDataPointsCount', 'sourceName'])
  const { loaderQuery } = useStore.getState()

  const variables: MetricsQueryVariables = omitBy({ page, limit, filter, order, strategyId }, isNull)

  const result = await loaderQuery<MetricsQuery, MetricsQueryVariables>(Metrics, variables)
  const { addObjectPage } = useStore.getState()

  const { collection = [], metadata } = result.data.metrics
  addObjectPage('metric', collection, metadata)

  return defer(result.data)
}

const loadMetric = async ({ params }) => {
  const { strategyId, nodeId } = params
  const { loaderQuery } = useStore.getState()

  loaderQuery(Metric, { id: nodeId, strategyId })

  return { metricId: nodeId }
}

const createMetric = async ({ request }) => {
  const formData = await request.formData()
  const input = parseMetricInput(Object.fromEntries(formData.entries()))
  const { actionMutation } = useStore.getState()

  return actionMutation(MetricCreate, input).then((resp) => {
    ahoy.track('metric:created', input)

    if (resp.error) {
      throw resp
    }

    const { metric, errors } = resp.data.metricCreate

    if (errors.length) {
      throw errors
    }

    toast({
      title: 'Creating your metric',
      position: 'bottom-right',
      status: 'success',
      render: (props) => <Notification {...props} />
    })

    return redirect(`/metrics/${metric.id}`)
  })
}

const updateMetric = async ({ request, params }) => {
  const { nodeId: metricId } = params

  const formData = await request.formData()
  const input = { ...Object.fromEntries(formData.entries()), metricId }
  const parsedInput = parseMetricInput(input)
  const { actionMutation } = useStore.getState()

  return actionMutation(MetricUpdate, parsedInput).then((resp) => {
    ahoy.track('metric:updated', input)

    if (resp.error) {
      throw resp
    }

    const { metric, errors } = resp.data.metricUpdate

    if (errors.length) {
      throw errors
    }

    toast({
      title: 'Updating your metric',
      position: 'bottom-right',
      status: 'success',
      render: (props) => <Notification {...props} />
    })

    return metric
  })
}

const deleteMetric = async ({ request }) => {
  const formData = await request.formData()
  const input = Object.fromEntries(formData.entries())
  const { actionMutation } = useStore.getState()

  return actionMutation(MetricDelete, input).then(() => {
    ahoy.track('metric:deleted', input)

    return null
  })
}

const loadMetricDataPoint = async ({ params }) => {
  const { id } = params
  const { loaderQuery } = useStore.getState()

  const metricDataPointPromise = loaderQuery(MetricDataPoint, {
    metricDataPointId: id
  })

  return defer({ metricDataPoint: metricDataPointPromise })
}

const loadMetricDataPoints = async ({ request, params }) => {
  const { nodeId } = params
  const url = new URL(request.url)
  const page = parseInt(url.searchParams.get('page'), 10) || 1
  const limit = parseInt(url.searchParams.get('limit'), 10) || null

  const { loaderQuery: storeLoaderQuery } = useStore.getState()
  const { addObjectPage } = useStore.getState()

  const resp = await storeLoaderQuery<MetricDataPointsQuery, MetricDataPointsQueryVariables>(MetricDataPoints, {
    metricId: nodeId,
    page,
    limit
  })
  const { collection, metadata } = resp?.data?.metricDataPoints || {}

  addObjectPage('metricDataPoint', collection, metadata)

  return { metricDataPoints: collection || [] }
}

const metricShowTabRoutes = (namespace = null) => [
  {
    path: ':nodeId/*',
    id: namespace ? `${namespace}-metric` : 'metric',
    loader: loadMetric,
    element: <Show />,
    children: [
      {
        index: true,
        element: <Navigate to="details" replace />
      },
      {
        element: <Tabs />,
        children: [
          {
            path: 'details',
            element: <MetricDetails />
          },
          {
            path: 'data',
            element: <MetricDataList />,
            loader: loadMetricDataPoints,
            children: [
              {
                path: 'new',
                loader: async () => requiresAuthorization('create', 'metricDataPoint'),
                element: <DataPointCreate />
              },
              {
                path: ':id/edit',
                loader: async (params) => {
                  await requiresAuthorization('update', 'metricDataPoint')

                  return loadMetricDataPoint(params)
                },
                element: <DataPointEdit />
              }
            ]
          },
          {
            path: 'events',
            children: SharedEventRoutes('metric')
          },
          goalRoutes(namespace),
          metricSourceRoutes(namespace),
          {
            path: 'correlations',
            element: <MetricCorrelationsTab />
          },
          {
            path: 'comments',
            element: <CommentsFetcher commentableType="metric" />
          },
          {
            path: 'settings',
            loader: async () => requiresAuthorization('update', 'metric'),
            element: <MetricFormTab />,
            action: ({ request, params }) => {
              switch (request.method) {
                case 'POST':
                  return updateMetric({ request, params })
                default:
                  return null
              }
            }
          }
        ]
      }
    ]
  }
]

const routes: RouteObject = {
  path: 'metrics',
  element: <Outlet />,
  action: ({ request }) => {
    switch (request.method) {
      case 'DELETE':
        return deleteMetric({ request })
      default:
        return null
    }
  },
  children: [
    {
      index: true,
      loader: loadMetrics,
      element: <List />
    },
    {
      path: 'new',
      loader: async () => requiresAuthorization('create', 'metric'),
      element: <Create />,
      action: ({ request }) => {
        switch (request.method) {
          case 'POST':
            return createMetric({ request })
          default:
            return null
        }
      }
    },
    credentialsRoutes,
    ...metricShowTabRoutes()
  ]
}
export { metricShowTabRoutes, Show }

export default routes
