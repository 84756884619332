import { IconButton, Text, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import { useStore } from '@app/store'
import ICON_MAP from '@app/utils/iconMap'
import type { NodeObjectInput } from '@graphql/types'

interface Props {}

const ToggleInsights: FC<Props> = () => {
  const { strategyId } = useParams()

  const updateObject = useStore.use.updateObject()
  const strategy = useGetObject(strategyId, 'strategy')
  const showAiInsights = strategy?.showAiInsights || false

  const toggleAiInsights = useCallback(() => {
    const updated = { strategy: { id: strategyId, showAiInsights: !showAiInsights } } as NodeObjectInput
    updateObject(updated, true)
  }, [showAiInsights, strategyId, updateObject])

  return (
    <Tooltip hasArrow label={<Text>AI Insights</Text>}>
      <IconButton
        aria-label="Toggle viewing AI Insights"
        colorScheme={showAiInsights ? 'purple' : 'gray'}
        icon={<ICON_MAP.AiInsights />}
        onClick={toggleAiInsights}
        size="2xs"
        variant={showAiInsights ? 'solid' : 'ghost'}
      />
    </Tooltip>
  )
}

ToggleInsights.displayName = 'ToggleInsights'

export default memo(ToggleInsights)
