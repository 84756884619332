import { Box, Drawer, DrawerBody, DrawerCloseButton, HStack, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import RecurringReportConfigurationForm from './components/recurringReportConfigurationForm'

import { ReportContext } from '@app/pages/reports/home'

const Create: FC = () => {
  const backLink = '../..'
  const navigate = useNavigate()
  const onCancel = () => {
    navigate(backLink)
  }

  return (
    <ReportContext.Consumer>
      {({ containerRef }) => (
        <Box>
          <Stack w="3xl" mx="auto" spacing={4}>
            <HStack
              pos="sticky"
              top={0}
              py={4}
              borderBottom="1px"
              borderBottomColor="border.default"
              bgColor="bg.muted"
            >
              <Text color="subtle" fontSize="sm">
                Create Recurring Report
              </Text>
            </HStack>
          </Stack>
          <Drawer
            isOpen
            onClose={() => {
              navigate(backLink)
            }}
            portalProps={{ containerRef }}
            size="sm"
          >
            <DrawerCloseButton />
            <DrawerBody overflowY="auto" h="100%" bgColor="bg.surface">
              <Stack spacing={4}>
                <RecurringReportConfigurationForm onCancel={onCancel} />
              </Stack>
            </DrawerBody>
          </Drawer>
        </Box>
      )}
    </ReportContext.Consumer>
  )
}

export default Create
