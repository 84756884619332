import { Button, Center, Container, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import { Form } from '@app/shared/rawForms'

const ReportsEmpty: FC = () => (
  <Center w="100%">
    <Container maxW="lg">
      <Stack spacing={6}>
        <Text align="center" fontSize="xl" fontWeight="semibold">
          Create your first report
        </Text>
        <Text align="center">
          Reports are snapshots of how your strategy is performing that you can share with your team or stakeholders.
        </Text>
        <Form method="post">
          <Button w="100%" type="submit">
            Create report
          </Button>
        </Form>
      </Stack>
    </Container>
  </Center>
)

export default ReportsEmpty
