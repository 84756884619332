import { useSearchParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'

import { getNodesSelector, getNodesObjectsSelector, useStore } from '@app/store'

const useGetStrategyObjects = (strategyId: string) => {
  const [searchParams] = useSearchParams()

  return useStore(
    useShallow((state) => {
      const nodes = getNodesSelector(state, strategyId, searchParams)

      return getNodesObjectsSelector(state, nodes)
    })
  )
}

export default useGetStrategyObjects
