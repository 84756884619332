import type { DefaultEdgeOptions } from '@xyflow/react'
import { MarkerType } from '@xyflow/react'

// eslint-disable-next-line import/prefer-default-export
export const defaultEdgeOptions: DefaultEdgeOptions = {
  animated: true,
  labelStyle: {
    fill: 'white',
    fontWeight: 700,
    fontSize: '18px',
    fontFamily:
      'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
  },
  labelBgPadding: [12, 8],
  labelBgBorderRadius: 12,
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 10,
    height: 10
  }
}
