import { Center } from '@chakra-ui/react'

import UserForm from './components/userForm'

import { PageHeader, PageStack } from '@app/shared/layout'

export const Edit = () => (
  <Center>
    <PageStack>
      <PageHeader title="Edit your profile" />
      <UserForm />
    </PageStack>
  </Center>
)

export default Edit
