import { useLoaderData } from 'react-router-dom'

import InvitationForm from '@app/pages/settings/organization/components/invitationForm'
import InvitationsList from '@app/pages/settings/organization/components/invitationsList'
import MembersList from '@app/pages/settings/organization/components/membersList'
import Can from '@app/shared/authorization/can'
import Card from '@app/shared/layout/card'
import CardHeader from '@app/shared/layout/cardHeader'
import PageHeader from '@app/shared/layout/pageHeader'
import PageStack from '@app/shared/layout/pageStack'
import type { User, Invitation } from '@graphql/types'

const Members = () => {
  const { members, invitations } = useLoaderData() as { members: User[]; invitations: Invitation[] }

  return (
    <PageStack>
      <PageHeader title="Team members" />
      <Can I="create" a="member">
        <InvitationForm />
      </Can>
      <Card isStack maxW="3xl">
        <CardHeader title="Current members" subtitle="Manage who can access the information in DoubleLoop" />
        <MembersList members={members} />
      </Card>
      <Card isStack maxW="3xl">
        <CardHeader title="Invitations" />
        <InvitationsList invitations={invitations} />
      </Card>
    </PageStack>
  )
}

export default Members
