import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { useStore } from '@app/store'

export const ADD_ROADMAP_ITEM_FORMAT = 'application/roadmap-add'
export const REORDER_ROADMAP_ITEM_FORMAT = 'application/roadmap-reorder'
export const UPDATE_TASK_STATE_FORMAT = 'application/task-state-update'

const useInteractions = () => {
  const { strategyId, roadmapItemId } = useParams()

  const addExistingEntityToRoadmapItem = useStore.use.addExistingEntityToRoadmapItem()
  const addExistingRoadmapItem = useStore.use.addExistingRoadmapItem()
  const updateRoadmapItem = useStore.use.updateRoadmapItem()

  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'move'
  }, [])

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()

      if (event.dataTransfer.getData(ADD_ROADMAP_ITEM_FORMAT)) {
        const object = JSON.parse(event.dataTransfer.getData(ADD_ROADMAP_ITEM_FORMAT))

        if (roadmapItemId) {
          addExistingEntityToRoadmapItem(roadmapItemId, object)
        } else {
          addExistingRoadmapItem(strategyId, object)
        }
      } else if (event.dataTransfer.getData(REORDER_ROADMAP_ITEM_FORMAT)) {
        const object = JSON.parse(event.dataTransfer.getData(REORDER_ROADMAP_ITEM_FORMAT))

        // @ts-expect-error - TS doesn't know about closest
        const closestRoadmapItemRow = event.target.closest('.roadmap-item-row')

        const roadmapItemRow = parseInt(closestRoadmapItemRow?.getAttribute('data-roadmap-item-position') || '0', 10)

        if (object.position !== roadmapItemRow) {
          updateRoadmapItem(strategyId, { ...object, position: roadmapItemRow })
        }
      }
    },
    [roadmapItemId, strategyId, addExistingEntityToRoadmapItem, addExistingRoadmapItem, updateRoadmapItem]
  )

  const onDragEnter = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }, [])

  const onDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }, [])

  const onDragEnd = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }, [])

  return {
    onDragEnd,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDrop
  }
}

export default useInteractions
