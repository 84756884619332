import { HStack, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import CardTypeSection from '@app/shared/cards/components/cardTypeSection'
import ICON_MAP from '@app/utils/iconMap'
import type { ReportStandardQuery } from '@graphql/queries'

interface Props {
  strategy: ReportStandardQuery['report']['strategies'][0]
}

const Strategy: FC<Props> = ({ strategy }) => {
  const { name } = strategy

  return (
    <Stack my={3}>
      <HStack>
        <CardTypeSection title="Map" icon={ICON_MAP.StrategyMap} />
        <Text fontWeight="semibold">{name}</Text>
      </HStack>
    </Stack>
  )
}

export default Strategy
