import { Box } from '@chakra-ui/react'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'

import InvitationsListActions from './invitationsListActions'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import Table from '@app/shared/table'
import type { Column, ColumnCellProps, Data } from '@app/shared/table/types'
import type { Invitation } from '@graphql/types'

interface Props {
  data: Invitation
}

const InvitationActionsCell: FC<Props> = ({ data: invitation }) => <InvitationsListActions invitation={invitation} />

interface InvitationsListProps {
  invitations: Invitation[]
}

const InvitationsList: FC<InvitationsListProps> = ({ invitations }) => {
  const { formatDateTimeInUserTimeZone } = useStoreCurrentUser()

  const sortedInvitations = sortBy(invitations, ['lastSentAt'])

  const columns: Column<Data>[] = [
    {
      header: 'Invited email',
      accessor: 'email'
    },
    {
      header: 'Sent at',
      accessor: 'lastSentAt',
      cell: ({ data: lastSentAt }) => formatDateTimeInUserTimeZone(lastSentAt)
    },
    {
      header: '',
      cell: InvitationActionsCell as unknown as FC<ColumnCellProps<Data>>
    }
  ]

  return (
    <Box overflow="auto">
      <Table columns={columns} data={sortedInvitations as Data[]} />
    </Box>
  )
}

export default InvitationsList
