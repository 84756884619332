import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { useState } from 'react'
import { BiSync } from 'react-icons/bi'

import Can from '@app/shared/authorization/can'
import useToast from '@app/shared/toast'
import { useGoogleSheetImportMutation } from '@graphql/queries'

const GoogleSheetSidebarMenu = ({ googleSheet }) => {
  const [, importSheet] = useGoogleSheetImportMutation()
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const onImport = () => {
    setIsLoading(true)

    importSheet({
      input: {
        googleSheetId: googleSheet?.id
      }
    }).finally(() => {
      setIsLoading(false)
    })

    toast({
      title: 'Importing started',
      description: 'We are importing your data from Google Sheets. This may take a few minutes.'
    })
  }

  return (
    <Menu>
      <MenuButton as={Button} variant="ghost">
        ...
      </MenuButton>
      <MenuList>
        <Can I="update" a="metric">
          <MenuItem icon={<BiSync />} isDisabled={isLoading} onClick={onImport}>
            Import latest data from Google Sheets
          </MenuItem>
        </Can>
      </MenuList>
    </Menu>
  )
}

export default GoogleSheetSidebarMenu
