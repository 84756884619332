import { Link as ChakraLink, Radio, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'

import StrategyFormUrl from '@app/pages/maps/components/strategyFormUrl'
import { RadioInput } from '@app/shared/rawForms'
import type { MapDomainStrategy } from '@app/types'
import { Routes } from '@app/utils/routeHelpers'
import { SharingTypesEnum } from '@graphql/queries'

type Props = {
  strategy: MapDomainStrategy
}

const StrategyFormAccess: FC<Props> = ({ strategy }) => {
  const [sharing, setSharing] = useState(strategy?.sharing || SharingTypesEnum.PrivateAccess)

  return (
    <Stack spacing={4}>
      <RadioInput
        label="Sharing"
        name="sharing"
        defaultValue={strategy?.sharing || SharingTypesEnum.PrivateAccess}
        onChange={setSharing}
        size="sm"
        helperText={
          <>
            Configure how stakeholders can see this strategy.{' '}
            <ChakraLink color="link" href={Routes.docsSharing} rel="noopener noreferrer" target="_blank">
              Learn more.
            </ChakraLink>
          </>
        }
      >
        <Stack direction={['column', 'column', 'row']} spacing={3}>
          <Radio name="sharing" value={SharingTypesEnum.PrivateAccess}>
            Private
          </Radio>
          <Radio name="sharing" value={SharingTypesEnum.TokenAccess}>
            Private w/token access
          </Radio>
          <Radio name="sharing" value={SharingTypesEnum.PublicAccess}>
            Public
          </Radio>
        </Stack>
      </RadioInput>
      <StrategyFormUrl strategy={strategy} sharing={sharing} />
    </Stack>
  )
}

export default StrategyFormAccess
