import { Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo } from 'react'

import AddCardDrawerEmptyState from '@app/pages/roadmaps/addCardDrawerEmptyState'
import CardList from '@app/pages/roadmaps/cardList'
import Card from '@app/shared/layout/card'
import type { MapDomainObject } from '@app/types'

type Props = {
  query: string
  objects: (MapDomainObject & { rfId: string })[]
}

const ExistingCardsList: FC<Props> = ({ query, objects }) => {
  const resultsCount = objects?.length || 0
  const noSearch = !query
  const noResults = !noSearch && resultsCount === 0

  const headline = noSearch ? 'Items currently on your map:' : `${resultsCount} found`

  return (
    <Stack pb={4} data-testid="existing-cards-list">
      <Text color="muted" fontSize="sm" data-testid="number-results">
        {headline}
      </Text>
      {noSearch ? <AddCardDrawerEmptyState /> : <CardList objects={objects} />}

      {noResults && <Card data-testid="no-results-text">No results found</Card>}
    </Stack>
  )
}

export default memo(ExistingCardsList)
