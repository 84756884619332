import { HStack, Icon, Link, Stack, TableContainer, Tbody, Td, Text, Tr } from '@chakra-ui/react'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import BasicCardTeamTypePopover from '@app/next/basicCardTeamTypePopover'
import ConfidenceRatingPopover from '@app/next/confidenceRatingPopover'
import DomainObjectStatusPopover from '@app/next/domainObjectStatusPopover'
import LabelsInput from '@app/next/forms/inline/labelsInput'
import SelectInput from '@app/next/forms/inline/selectInput'
import ViewerFieldWrapper from '@app/next/forms/inline/support/viewerFieldWrapper'
import TextInput from '@app/next/forms/inline/textInput'
import OwnerPopover from '@app/next/ownerPopover'
import DetailsTable from '@app/pages/shared/detailsTable'
import Can from '@app/shared/authorization/can'
import { CardDueDate } from '@app/shared/cards/components'
import ICON_MAP from '@app/utils/iconMap'

interface Props {}

const BasicCardDetails: FC<Props> = () => {
  const { nodeId } = useParams()
  const basicCard = useGetObject(nodeId, 'basicCard')
  const { externalUrl, aiGenerated, ownerId } = basicCard
  const owner = useGetObject(ownerId, 'user')

  return (
    <Stack p={4} spacing={4}>
      <TableContainer>
        <DetailsTable variant="unstyled">
          <Tbody>
            <ViewerFieldWrapper value={!!externalUrl}>
              <Tr>
                <Td pt={0} pl={0} fontWeight="semibold">
                  {externalUrl ? (
                    <Link
                      color="link"
                      fontSize="sm"
                      whiteSpace="pre-wrap"
                      wordBreak="break-all"
                      href={externalUrl}
                      isExternal
                    >
                      External URL
                    </Link>
                  ) : (
                    <Text>External URL</Text>
                  )}
                </Td>
                <Td pt={0} pr={0}>
                  <Stack spacing={0}>
                    <TextInput
                      domainObject={basicCard}
                      name="externalUrl"
                      defaultValue={externalUrl || ''}
                      placeholder="External url..."
                      type="url"
                    />
                  </Stack>
                </Td>
              </Tr>
            </ViewerFieldWrapper>
            <ViewerFieldWrapper value={basicCard.dueDate}>
              <Tr>
                <Td pl={0} fontWeight="semibold">
                  Due date
                </Td>
                <Td pr={0}>
                  <CardDueDate domainObject={basicCard} fieldName="dueDate" showLabel={false} />
                </Td>
              </Tr>
            </ViewerFieldWrapper>
            <ViewerFieldWrapper value={owner}>
              <Tr>
                <Td pt={0} pl={0} fontWeight="semibold">
                  Owner
                </Td>
                <Td pt={0} pr={0}>
                  <OwnerPopover object={basicCard} showName />
                </Td>
              </Tr>
            </ViewerFieldWrapper>
            <ViewerFieldWrapper value={basicCard.teamType}>
              <Tr>
                <Td pl={0} fontWeight="semibold">
                  Team
                </Td>
                <Td pr={0}>
                  <HStack>
                    <Icon as={ICON_MAP.Team} color="fg.muted" />
                    <BasicCardTeamTypePopover basicCard={basicCard} />
                  </HStack>
                </Td>
              </Tr>
            </ViewerFieldWrapper>
            <ViewerFieldWrapper value={basicCard.labels}>
              <Tr>
                <Td pl={0} fontWeight="semibold">
                  Labels
                </Td>
                <Td pr={0}>
                  <LabelsInput domainObject={basicCard} name="labels" />
                </Td>
              </Tr>
            </ViewerFieldWrapper>
            <ViewerFieldWrapper value={typeof basicCard.confidenceRating === 'number'}>
              <Tr>
                <Td pb={0} pl={0} fontWeight="semibold">
                  Confidence
                </Td>
                <Td pr={0} pb={0}>
                  <ConfidenceRatingPopover domainObject={basicCard} />
                </Td>
              </Tr>
            </ViewerFieldWrapper>
            <ViewerFieldWrapper value={basicCard.status}>
              <Tr>
                <Td pb={0} pl={0} fontWeight="semibold">
                  Status
                </Td>
                <Td pr={0} pb={0}>
                  <DomainObjectStatusPopover domainObject={basicCard} />
                </Td>
              </Tr>
            </ViewerFieldWrapper>
            <Can I="view" an="admin">
              <Tr>
                <Td pb={0} pl={0} fontWeight="semibold">
                  AI Generated
                </Td>
                <Td pr={0} pb={0}>
                  <SelectInput
                    domainObject={basicCard}
                    name="aiGenerated"
                    defaultValue={aiGenerated?.toString()}
                    placeholder={null}
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </SelectInput>
                </Td>
              </Tr>
            </Can>
          </Tbody>
        </DetailsTable>
      </TableContainer>
    </Stack>
  )
}

export default BasicCardDetails
