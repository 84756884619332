import type { IconButtonProps } from '@chakra-ui/react'
import { IconButton, Tooltip, VStack } from '@chakra-ui/react'
import type { FC } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useDrawerContext } from '@app/pages/maps/drawerContext'
import ICON_MAP from '@app/utils/iconMap'

const DrawerCloseButton: FC<Omit<IconButtonProps, 'aria-label'>> = ({ ...rest }) => {
  const { strategyId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { onDrawerClose } = useDrawerContext()

  let drawerCloseAction = onDrawerClose

  // If we're on the roadmap, don't go back to the canvas, go back to the roadmap
  if (location.pathname.includes('roadmap')) {
    drawerCloseAction = () => navigate(`/strategy/${strategyId}/roadmap`)
  }

  return (
    <Tooltip
      hasArrow
      label={
        <VStack spacing={0}>
          <div>Close</div>
          <div>(esc)</div>
        </VStack>
      }
    >
      <IconButton
        aria-label="close drawer"
        colorScheme="gray"
        icon={<ICON_MAP.Close />}
        onClick={drawerCloseAction}
        {...rest}
      />
    </Tooltip>
  )
}

export default DrawerCloseButton
