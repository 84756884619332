import { Box, Button, Divider, Heading, Spacer, Stack, Text } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import debounce from 'lodash/debounce'
import set from 'lodash/set'
import type { ChangeEventHandler, FC } from 'react'
import { useState, Suspense, memo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useParams, useNavigate } from 'react-router-dom'
import type { AnyVariables } from 'urql'

import ExistingCardsList from './existingCardsList'
import QueryExistingCardForm from './queryExisting/queryExistingCardForm'

import { deriveType, showAddButton } from '@app/pages/maps/components/addCards/helpers'
import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'
import SuspenseLoader from '@app/shared/loaders/suspenseLoader'
import { useStore } from '@app/store'
import type { Nodes, RoadmapDomainObjects } from '@app/types'
import generateRandomName from '@app/utils/uniqueNameGenerator'
import { loaderQuery } from '@graphql/client'
import { StrategyExistingObjectAutocomplete } from '@graphql/documents/strategy.graphql'
import type {
  StrategyExistingObjectAutocompleteQuery,
  StrategyExistingObjectAutocompleteQueryVariables
} from '@graphql/queries'
import type { Entity } from '@graphql/types'

const NAME_GEN_OVERRIDES = {
  basicCard: generateRandomName,
  entity: generateRandomName
}

type Props = {
  cardType?: Nodes
  headerText?: string
  buttonText?: string
  mutationVariables?: AnyVariables
}

const AddCardDrawer: FC<Props> = ({ cardType = null, headerText = null, buttonText = null, mutationVariables }) => {
  const { strategyId, type: paramType, roadmapItemId } = useParams()
  const navigate = useNavigate()
  const type = cardType || paramType
  let transformedVariables = mutationVariables

  if (paramType === 'entity') {
    transformedVariables = { ...transformedVariables, sourceName: 'web' }
  }

  const [searchResults, setSearchResults] = useState(null)
  const [query, setQuery] = useState('')

  const displayType = type === 'entity' ? 'Work' : capitalize(type)
  const displayAddButton = showAddButton(type)

  const onDrawerClose = () => {
    navigate(`/strategy/${strategyId}/roadmap`)
  }

  useHotkeys('esc', () => onDrawerClose())

  const addNewEntityToRoadmapItem = useStore.use.addNewEntityToRoadmapItem()
  const addNewRoadmapItem = useStore.use.addNewRoadmapItem()

  const handleAddNew = () => {
    const name = NAME_GEN_OVERRIDES[type]?.() || (`${generateRandomName()} ${type}` as string)

    if (roadmapItemId) {
      const object = { name, ...transformedVariables } as Entity
      addNewEntityToRoadmapItem(roadmapItemId, object)
    } else {
      const object = { name, ...transformedVariables }

      addNewRoadmapItem(strategyId, type as RoadmapDomainObjects, object)
    }

    onDrawerClose()
  }

  const onChange = debounce<ChangeEventHandler<HTMLFormElement>>(async (e) => {
    e.preventDefault()

    const formData = new FormData(e.target.form)
    const formObject = Object.fromEntries(formData.entries())

    const variables = {
      id: strategyId,
      type: deriveType(type),
      query: '',
      scope: {}
    }

    Object.entries(formObject).forEach(([key, value]) => {
      set(variables, key, value)
    })

    setQuery(variables.query)

    const { data } = await loaderQuery<
      StrategyExistingObjectAutocompleteQuery,
      StrategyExistingObjectAutocompleteQueryVariables
    >(StrategyExistingObjectAutocomplete, variables)

    setSearchResults(data?.strategy?.existingObjectAutocomplete || [])
  }, 100)

  let subHeader
  // if (type === 'metric') {
  //   subHeader = <Link color="link" fontSize="sm" href={`/${type}s`} isExternal>{`Manage ${type}s library`}</Link>
  // }

  if (roadmapItemId) {
    subHeader = <Text>Add work to a roadmap item</Text>
  } else {
    subHeader = <Text>Add a new swimlane</Text>
  }

  return (
    <Stack
      pos="fixed"
      zIndex="popover"
      top="72px"
      right="16px"
      bottom={8}
      w="385px"
      py={6}
      borderWidth={1}
      borderStyle="solid"
      borderColor="border.emphasized"
      borderRadius="md"
      shadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
      bgColor="bg.surface"
      data-testid="add-existing-drawer"
      divider={<Divider />}
    >
      <Stack as="section" px={4} spacing={4}>
        <Stack direction="row">
          <Stack>
            <Heading size="xs">{headerText || `${displayType} Cards`}</Heading>
            {subHeader}
          </Stack>
          <Spacer />
          <DrawerCloseButton variant="ghost" onClick={() => onDrawerClose()} />
        </Stack>
        <Box w="100%" py={displayAddButton ? 4 : 0}>
          {displayAddButton && (
            <Button w="100%" data-testid="button-add-existing" onClick={handleAddNew} variant="primary">
              + {buttonText || `New ${displayType}`}
            </Button>
          )}
        </Box>
      </Stack>
      <Stack as="section" px={4} spacing={0}>
        <Suspense fallback={<SuspenseLoader />}>
          <QueryExistingCardForm onChange={onChange} type={type} />
        </Suspense>
      </Stack>
      <Stack as="section" overflow="auto" px={4} spacing={0}>
        <ExistingCardsList objects={searchResults} query={query} />
      </Stack>
    </Stack>
  )
}

export default memo(AddCardDrawer)
