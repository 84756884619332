import { Stack, Text } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

interface Props {
  showLabel?: boolean
  label: string
  children: ReactNode
}
const WithLabel: FC<Props> = ({ showLabel = false, label, children }) => (
  <Stack spacing={1}>
    {showLabel && (
      <Text fontSize="sm" fontWeight="semibold">
        {label}
      </Text>
    )}
    {children}
  </Stack>
)

export default WithLabel
