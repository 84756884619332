import { Box, Stack } from '@chakra-ui/react'
import type { FC } from 'react'

import BasicCard from '@app/pages/reports/components/basicCard/basicCard'
import Entity from '@app/pages/reports/components/entities/entity'
import Metric from '@app/pages/reports/components/metrics/metric'
import Strategy from '@app/pages/reports/components/strategies/strategy'
import type { ReportStandardQuery } from '@graphql/queries'

type ReportType<T extends keyof ReportStandardQuery['report']> = ReportStandardQuery['report'][T][0]
type Incomer = ReportType<'basicCards'> | ReportType<'metrics'> | ReportType<'entities'> | ReportType<'strategies'>

interface DirectIncomerProps {
  incomer: Incomer
}

const DirectIncomer: FC<DirectIncomerProps> = ({ incomer }) => {
  switch (incomer.classType) {
    case 'basicCard':
      return <BasicCard basicCard={incomer as ReportType<'basicCards'>} />
    case 'metric':
      return <Metric metric={incomer as ReportType<'metrics'>} />
    case 'entity':
      return <Entity entity={incomer as ReportType<'entities'>} />
    case 'strategy':
      return <Strategy strategy={incomer as ReportType<'strategies'>} />
    default:
      return null
  }
}

interface Props {
  directIncomers: ReportType<'basicCards'>['directIncomers']
}

const DirectIncomers: FC<Props> = ({ directIncomers }) => (
  <Stack mt={3} ml={4}>
    {directIncomers.map((incomer: Incomer) => (
      <Box key={incomer.id} px={4} borderWidth="1px" borderColor="border.default">
        <DirectIncomer incomer={incomer as Incomer} />
      </Box>
    ))}
  </Stack>
)

export default DirectIncomers
