import type { FC, ReactNode } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import type { UseQueryArgs, UseQueryResponse } from 'urql'

import { usePage } from '@app/shared/pagination'
import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { useStore } from '@app/store'
import type { MetricEntitiesQueryVariables, StrategyMetricEntitiesQueryVariables } from '@graphql/queries'
import { useStrategyStrategyEntitiesQuery } from '@graphql/queries'
import type { CollectionMetadata, Entity, EntityCollection } from '@graphql/types'

type Variables = StrategyMetricEntitiesQueryVariables | MetricEntitiesQueryVariables

type Props = Variables & {
  id: string
  children: (collection: Entity[], metadata: CollectionMetadata | Record<string, never>) => ReactNode
}

const StrategyEntitiesFetcher: FC<Props> = ({ id, children, ...rest }) => {
  const { strategyId } = useParams()
  const page = usePage()
  const updateObject = useStore.use.updateObject()

  const query: (options: Omit<UseQueryArgs, 'query'>) => UseQueryResponse = useStrategyStrategyEntitiesQuery
  const variables: Variables = { id: strategyId, metricId: id, page, ...rest }

  const [{ data }] = query({ variables })

  const { collection, metadata } = (data?.strategy?.strategyEntities ||
    data?.strategyEntities ||
    {}) as EntityCollection

  useEffect(() => {
    // @ts-expect-error containee_ids is not a valid field for updateObject, do use without skipMutation
    updateObject({ strategy: { id, containeeIds: collection?.map((containment) => containment.id) } }, true)
  }, [])

  return children(collection, metadata)
}

export default withSuspenseWrapper(StrategyEntitiesFetcher)
