import { Icon, IconButton, Tooltip } from '@chakra-ui/react'
import type { FC, MouseEventHandler } from 'react'

import { useStore } from '@app/store'
import ICON_MAP from '@app/utils/iconMap'

type Props = {
  favoritableType: string
  favoritableId: string
  favoriteId?: string
  iconBoxSize?: number
  show?: boolean
}
const FavoriteToggleButton: FC<Props> = ({
  favoritableType,
  favoritableId,
  favoriteId = null,
  iconBoxSize = null,
  show = true,
  ...rest
}) => {
  const favoriteObject = useStore.use.favoriteObject()
  const unfavoriteObject = useStore.use.unfavoriteObject()

  const favorited = !!favoriteId

  if (!show) {
    return null
  }

  const icon = favorited ? (
    <Icon as={ICON_MAP.Favorited} boxSize={iconBoxSize} color="yellow.500" data-cy="favorited" />
  ) : (
    <Icon as={ICON_MAP.Favorite} boxSize={iconBoxSize} data-cy="not-favorited" />
  )

  const onClick: MouseEventHandler = (e) => {
    e.stopPropagation()
    const favoriteableObject = { id: favoritableId, classType: favoritableType }

    if (favorited) {
      unfavoriteObject(favoriteableObject)
    } else {
      favoriteObject(favoriteableObject)
    }
  }

  return (
    <Tooltip label={favorited ? 'Added to favorites' : 'Add to favorites'}>
      <IconButton
        aria-label="Toggle as a favorite"
        colorScheme="gray"
        icon={icon}
        onClick={onClick}
        variant="ghost"
        {...rest}
      />
    </Tooltip>
  )
}

export default FavoriteToggleButton
