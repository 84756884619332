import { useReactFlow } from '@xyflow/react'
import { useCallback, useMemo } from 'react'

import useCookie from '@app/hooks/useCookie'
import useMousePosition from '@app/hooks/useMousePosition'
import { BASIC_CARD_TYPE_COOKIE, METRIC_CARD_TYPE_COOKIE } from '@app/lib/globals'
import useImages from '@app/pages/maps/useImages'
import { useStore } from '@app/store'
import type { MousePosition } from '@app/types'
import generateRandomName from '@app/utils/uniqueNameGenerator'
import type { BasicCardCreateInput, EntityCreateInput, MetricCreateInput } from '@graphql/types'

type UseMapNodeCreators = (strategyId: string) => {
  createCommentThread: () => void
  createNote: () => void
  createSection: () => void
  createImage: (file: File, mousePosition?: MousePosition) => void
  createMetric: (attrs?: Partial<MetricCreateInput>) => void
  createWork: (attrs?: Partial<EntityCreateInput>) => void
  createBasicCard: (attrs?: Partial<BasicCardCreateInput>) => void
}

const useMapNodeCreators: UseMapNodeCreators = (strategyId) => {
  const { screenToFlowPosition } = useReactFlow()
  const addNode = useStore.use.addNode()
  const getLatestMousePosition = useMousePosition()

  const { createImageNodes } = useImages()
  const [cardTypeId] = useCookie(BASIC_CARD_TYPE_COOKIE)
  const [strategyType] = useCookie(METRIC_CARD_TYPE_COOKIE)

  const createNote = useCallback(() => {
    const mousePosition = getLatestMousePosition()
    const position = screenToFlowPosition(mousePosition)

    addNode({
      strategyId,
      nodeData: {
        position,
        dimensions: {
          height: 120,
          width: 275
        }
      },
      objectData: {},
      objectType: 'note'
    })
  }, [screenToFlowPosition, getLatestMousePosition, addNode, strategyId])

  const createSection = useCallback(() => {
    const mousePosition = getLatestMousePosition()

    const position = screenToFlowPosition(mousePosition)

    addNode({
      strategyId,
      nodeData: {
        position,
        selected: true,
        dimensions: {
          height: 300,
          width: 500
        }
      },
      objectData: {},
      objectType: 'section'
    })
  }, [screenToFlowPosition, getLatestMousePosition, addNode, strategyId])

  const createBasicCard = useCallback(
    (attrs: Partial<BasicCardCreateInput> = {}) => {
      const mousePosition = getLatestMousePosition()

      const position = screenToFlowPosition(mousePosition)

      addNode({
        strategyId,
        nodeData: {
          position,
          selected: true,
          dimensions: {
            height: 300,
            width: 500
          }
        },
        objectData: {
          name: generateRandomName(),
          cardTypeId,
          ...attrs
        },
        objectType: 'basicCard'
      })
    },
    [addNode, cardTypeId, getLatestMousePosition, screenToFlowPosition, strategyId]
  )

  const createMetric = useCallback(
    (attrs: Partial<MetricCreateInput> = {}) => {
      const mousePosition = getLatestMousePosition()
      const position = screenToFlowPosition(mousePosition)

      addNode({
        strategyId,
        nodeData: {
          position,
          selected: true,
          dimensions: {
            height: 300,
            width: 500
          }
        },
        objectData: {
          name: generateRandomName(),
          strategyType,
          ...attrs
        },
        objectType: 'metric'
      })
    },
    [addNode, getLatestMousePosition, screenToFlowPosition, strategyId]
  )

  const createWork = useCallback(
    (attrs: Partial<EntityCreateInput> = {}) => {
      const mousePosition = getLatestMousePosition()

      const position = screenToFlowPosition(mousePosition)

      addNode({
        strategyId,
        nodeData: {
          position,
          selected: true,
          dimensions: {
            height: 300,
            width: 500
          }
        },
        objectData: {
          name: generateRandomName(),
          sourceName: 'web',
          ...attrs
        },
        objectType: 'entity'
      })
    },
    [addNode, getLatestMousePosition, screenToFlowPosition, strategyId]
  )

  const createImage = useCallback(
    (file: File, position: MousePosition = getLatestMousePosition()) => createImageNodes(file, position),
    [createImageNodes, getLatestMousePosition]
  )

  const createCommentThread = useCallback(() => {
    const mousePosition = getLatestMousePosition()
    const position = screenToFlowPosition(mousePosition)

    addNode({
      strategyId,
      nodeData: {
        position,
        selected: true,
        dimensions: {
          height: 300,
          width: 500
        }
      },
      objectData: {},
      objectType: 'commentThread'
    })
  }, [addNode, getLatestMousePosition, screenToFlowPosition, strategyId])

  return useMemo(
    () => ({
      createCommentThread,
      createNote,
      createSection,
      createImage,
      createMetric,
      createWork,
      createBasicCard
    }),
    [createCommentThread, createNote, createSection, createImage, createMetric, createWork, createBasicCard]
  )
}

export default useMapNodeCreators
