import type { ReactNode } from 'react'
import { createContext, useContext, useMemo, useRef, useState } from 'react'

import type { CardSize } from '@app/types'

const CardContext = createContext(undefined)

interface Props {
  // This feels really weird. Its a ReactNode which *should* have the concept of props?
  children: ReactNode & { props: { data: { size?: CardSize; color?: string } } }
}

const CardProvider = ({ children }: Props) => {
  const { data } = children.props
  let size = 'normal'
  let color = null

  if (data) {
    size = data?.size || 'normal'
    color = data?.color
  }

  const [editing, setEditing] = useState(false)
  const submitRef = useRef()

  const value = useMemo(() => {
    const startEditing = () => setEditing(true)
    const stopEditing = () => setEditing(false)

    return { editing, setEditing, stopEditing, startEditing, submitRef, size, color }
  }, [editing, setEditing, submitRef, size, color])

  return <CardContext.Provider value={value}>{children}</CardContext.Provider>
}

const useCardContext = () => {
  const context = useContext(CardContext)

  if (context === undefined) {
    throw new Error('useCardContext must be used within CardContext.Provider')
  }

  return context
}

export { CardProvider, useCardContext }
