import { useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import type { MapDomainStrategy } from '@app/types'

type UseGetStrategyDateFilters = (
  strategyId?: string | null
) => Pick<MapDomainStrategy, 'startDate' | 'endDate' | 'range'>
const useGetStrategyDateFilters: UseGetStrategyDateFilters = (strategyId = null) => {
  const { strategyId: paramId } = useParams()

  const strategy = useGetObject(strategyId || paramId, 'strategy')

  return {
    startDate: strategy?.startDate,
    endDate: strategy?.endDate,
    range: strategy?.range
  }
}

export default useGetStrategyDateFilters
