import type { FC } from 'react'
import { memo } from 'react'
import { useParams } from 'react-router-dom'

import AiAssistantButton from './components/aiAssistantButton'
import CommentsButton from './components/commentsButton'
import CommonButtons from './components/commonButtons'
import DetailsButton from './components/detailsButton'
import EventsButton from './components/eventsButton'
import SettingsButton from './components/settingsButton'
import Toolbar from './components/toolbar'

import type { MapDomainNode } from '@app/types'

interface Props {
  node: MapDomainNode
}

const BasicCardToolbar: FC<Props> = ({ node }) => {
  const { strategyId } = useParams()
  const route = `/strategy/${strategyId}/map/basicCard/${node.data.id}`

  return (
    <Toolbar>
      <DetailsButton path={route} />
      <SettingsButton path={`${route}/settings`} />
      <EventsButton path={`${route}/events`} />
      <CommentsButton path={`${route}/comments`} />
      <AiAssistantButton node={node} />
      <CommonButtons node={node} />
    </Toolbar>
  )
}

export default memo(BasicCardToolbar)
