import type { FC } from 'react'
import { FiArrowLeft } from 'react-icons/fi'

import useChangelogEntity from '../../../../next/changelog/useChangelogEntity'

import LayoutRow from '@app/pages/changelogs/components/layoutRow'
import NavigationButton from '@app/pages/changelogs/components/navigationButton'
import EntityForm from '@app/pages/shared/entities/components/entityForm'
import { FormContainer } from '@app/shared/forms'
import HeadMetaData from '@app/shared/headMetaData'
import { pathWithSearchParams } from '@app/utils/routeHelpers'

type Props = {
  routeId?: string
}

const Edit: FC<Props> = ({ routeId = 'changelogEntity' }) => {
  const entity = useChangelogEntity(routeId)

  if (!entity) {
    return null
  }

  const entityPath = pathWithSearchParams(`events/${entity.id}`)
  const backButtonPath = pathWithSearchParams('..')

  return (
    <>
      <HeadMetaData
        title={entity.name}
        description={entity.summaryShort}
        url={entityPath}
        imageUrl={entity.thumbnail?.url}
      />

      <LayoutRow pb={8}>
        <FormContainer>
          <EntityForm entity={entity} variant="drawer" />
        </FormContainer>
        <NavigationButton url={backButtonPath} label="Back to overview" leftIcon={<FiArrowLeft />} mt={6} />
      </LayoutRow>
    </>
  )
}

export default Edit
