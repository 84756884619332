import { IconButton, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import useGetIncomingCollapsedNodes from '@app/hooks/useGetIncomingCollapsedNodes'
import { useStore } from '@app/store'
import type { MapDomainNode } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

interface Props {
  node: MapDomainNode
}

const ExpandCollapsedIncomersButton: FC<Props> = ({ node }) => {
  const { strategyId } = useParams()
  const { nodes: collapsedNodes } = useGetIncomingCollapsedNodes(node)
  const expandNodes = useStore.use.expandNodes()

  const handleExpand = () => {
    expandNodes(strategyId, collapsedNodes)
  }

  if (!collapsedNodes.length) {
    return null
  }

  return (
    <Tooltip label={`Uncollapse all cards (${collapsedNodes.length})`}>
      <IconButton
        borderRadius="none"
        aria-label="Uncollapse cards"
        icon={<ICON_MAP.ExpandAction />}
        onClick={handleExpand}
      />
    </Tooltip>
  )
}

export default ExpandCollapsedIncomersButton
