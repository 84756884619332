import type { BoxProps } from '@chakra-ui/react'
import { HStack, Icon, IconButton, Spacer, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { PiLockKeyFill } from 'react-icons/pi'

import { Card, CardTitle } from '../components'
import { CardProvider, useCardContext } from '../contexts/cardContext'

import ConfidenceRatingPopover from '@app/next/confidenceRatingPopover'
import OwnerPopover from '@app/next/ownerPopover'
import CollapsedNodes from '@app/pages/maps/components/nodes/components/collapsedNodes'
import cardAvatarSize from '@app/shared/cards/cardAvatarSize'
import cardFontSize from '@app/shared/cards/cardFontSize'
import CardLabels from '@app/shared/cards/components/cardLabels'
import CardSection from '@app/shared/cards/components/cardSection'
import CardTypeSection from '@app/shared/cards/components/cardTypeSection'
import { withBasicProvider } from '@app/shared/utils/withProviders'
import type { MapDomainNode } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'
import type { Strategy } from '@graphql/types'
import { SharingTypesEnum } from '@graphql/types'

interface Props extends BoxProps {
  strategy?: Strategy
  node?: MapDomainNode
}

export const SubMapCard: FC<Props> = ({ strategy, node, ...rest }) => {
  const { size } = useCardContext()
  const { id, sharing } = strategy
  const fontSize = cardFontSize('sm', size)
  const avatarSize = cardAvatarSize('xs', size)

  return (
    <Card {...rest}>
      <CardTypeSection title="Map" icon={ICON_MAP.StrategyMap} route={`preview/${id}`} size={size} />
      <CardSection size={size}>
        <CardTitle domainObject={strategy} name="name" size={size} />
      </CardSection>
      <CardLabels fieldName="labels" domainObject={strategy} size={size} />
      <CardSection size={size}>
        <HStack>
          <OwnerPopover fontSize={fontSize} object={strategy} avatarProps={{ size: avatarSize }} showName />
          <Spacer />
          {sharing !== SharingTypesEnum.PublicAccess && (
            <Tooltip
              hasArrow
              label="This card will not display to logged out users unless you make it public."
              shouldWrapChildren
            >
              <IconButton
                cursor="default"
                aria-label="Sharing"
                icon={<Icon as={PiLockKeyFill} boxSize={size === 'normal' ? 5 : 8} color="yellow.700" />}
                size="2xs"
                variant="ghost"
              />
            </Tooltip>
          )}
          <ConfidenceRatingPopover domainObject={strategy} size={size} />
        </HStack>
      </CardSection>
      {node && <CollapsedNodes node={node} />}
    </Card>
  )
}

export const SubMapCardWithContext = withBasicProvider(CardProvider)(SubMapCard)
