import { Box, FormControl, FormLabel, HStack, Spacer, Switch } from '@chakra-ui/react'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'
import { useState } from 'react'

import NameCell from '@app/pages/settings/organization/components/columns/nameCell'
import RoleCell from '@app/pages/settings/organization/components/columns/roleCell'
import UserActionsCell from '@app/pages/settings/organization/components/columns/userActionsCell'
import Table from '@app/shared/table'
import type { Column, ColumnCellProps, Data } from '@app/shared/table/types'
import type { User } from '@graphql/types'

const columns: Column<Data>[] = [
  {
    header: 'Name',
    cell: NameCell as unknown as FC<ColumnCellProps<Data>>
  },
  {
    header: 'Role',
    cell: RoleCell
  },
  {
    header: '',
    cell: UserActionsCell as unknown as FC<ColumnCellProps<Data>>
  }
]

interface Props {
  members: User[]
}

const MembersList: FC<Props> = ({ members }) => {
  const [showInactive, setShowInactive] = useState(false)

  let sortedMembers = sortBy(members || [], ['disabledAt', 'name']).reverse()
  if (showInactive === false) {
    sortedMembers = sortedMembers.filter((member) => !member.disabledAt)
  }

  return (
    <>
      <Box overflow="auto">
        <Table columns={columns} data={sortedMembers as Data[]} />
      </Box>
      <FormControl>
        <HStack mt={4}>
          <FormLabel mb="0" fontSize="sm" htmlFor="show-inactive">
            Show disabled users
          </FormLabel>
          <Spacer />
          <Switch
            id="show-inactive"
            isChecked={showInactive}
            onChange={() => {
              setShowInactive(!showInactive)
            }}
          />
        </HStack>
      </FormControl>
    </>
  )
}

export default MembersList
