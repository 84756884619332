import { getObjectById, useStore } from '@app/store'
import type { Strategy } from '@graphql/types'

type UseIsAnonymousUser = (strategyId?: string) => boolean
const useIsAnonymousUser: UseIsAnonymousUser = (strategyId) => {
  const { user } = useStore.use.currentUser()
  const strategy = useStore((state) => (strategyId ? getObjectById(state, 'strategy', strategyId) : null)) as Strategy

  if (!user || !strategyId || !strategy) {
    return true
  }

  return user.organization?.id !== strategy?.organization?.id
}

export default useIsAnonymousUser
