import { Outlet, redirect } from 'react-router-dom'
import type { LoaderFunction, RouteObject } from 'react-router-dom'

import RecurringCreate from '@app/pages/reports/recurring/create'
import RecurringEdit from '@app/pages/reports/recurring/edit'
import { actionMutation, loaderQuery } from '@graphql/client'
import {
  RecurringReportConfiguration,
  RecurringReportConfigurationCreate,
  RecurringReportConfigurationDelete,
  RecurringReportConfigurationUpdate
} from '@graphql/documents/recurring_report_configuration.graphql'
import type { RecurringReportConfigurationCreateInput, RecurringReportConfigurationUpdateInput } from '@graphql/types'

const loadRecurringReportConfiguration: LoaderFunction = async ({ params }) => {
  const { recurringReportConfigurationId: id } = params
  const variables = { id }

  const resp = await loaderQuery(RecurringReportConfiguration, variables)

  if (resp.error) {
    throw resp
  }

  return { recurringReportConfiguration: resp?.data?.recurringReportConfiguration }
}

const createRecurringReportConfiguration: LoaderFunction = async ({ params, request }) => {
  const { strategyId } = params
  const formData = await request.formData()
  const input: Partial<RecurringReportConfigurationCreateInput> = {
    ...Object.fromEntries(formData.entries()),
    strategyId
  }
  if (typeof input.filters === 'string') {
    input.filters = JSON.parse(input.filters)
  }

  const resp = await actionMutation(RecurringReportConfigurationCreate, input)

  if (resp.error) {
    throw resp
  }

  const { recurringReportConfiguration, errors } = resp.data.recurringReportConfigurationCreate

  if (errors.length) {
    throw errors
  }

  return redirect(`../${recurringReportConfiguration.id}`)
}

const updateRecurringReportConfiguration: LoaderFunction = async ({ params, request }) => {
  const { recurringReportConfigurationId } = params
  const formData = await request.formData()
  const input: Partial<RecurringReportConfigurationUpdateInput> = {
    ...Object.fromEntries(formData.entries()),
    recurringReportConfigurationId
  }
  if (typeof input.filters === 'string') {
    input.filters = JSON.parse(input.filters)
  }

  const resp = await actionMutation(RecurringReportConfigurationUpdate, input)

  if (resp.error) {
    throw resp
  }

  const { errors } = resp.data.recurringReportConfigurationUpdate

  if (errors.length) {
    throw errors
  }

  return redirect('..')
}

const deleteRecurringReportConfiguration: LoaderFunction = async ({ params, request }) => {
  const formData = await request.formData()
  const { recurringReportConfigurationId: paramId } = params
  const formId = formData.get('recurringReportConfigurationId')
  const input = {
    recurringReportConfigurationId: formId
  }

  const resp = await actionMutation(RecurringReportConfigurationDelete, input)

  if (resp.error) {
    throw resp
  }

  if (paramId === formId) {
    // if we're looking at the deleted one, go back to the list
    return redirect('..')
  }

  return true
}

const action = ({ request, params }) => {
  switch (request.method) {
    case 'POST':
      return updateRecurringReportConfiguration({ request, params })
    case 'DELETE':
      return deleteRecurringReportConfiguration({ request, params })
    default:
      return null
  }
}

const routes: RouteObject = {
  path: 'recurring',
  element: <Outlet />,
  children: [
    {
      index: true,
      element: <Outlet />
    },
    {
      path: 'create',
      element: <RecurringCreate />,
      action: ({ request, params }) => {
        switch (request.method) {
          case 'POST':
            return createRecurringReportConfiguration({ request, params })
          default:
            return null
        }
      }
    },
    {
      path: ':recurringReportConfigurationId',
      element: <Outlet />,
      action,
      children: [
        {
          index: true,
          // loader: loadRecurringReportConfiguration,
          // element: <Show />
          element: <Outlet />,
          action
        },
        {
          path: 'edit',
          loader: loadRecurringReportConfiguration,
          element: <RecurringEdit />,
          action
        }
      ]
    }
  ]
}

export default routes
