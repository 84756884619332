import {
  Button,
  ButtonGroup,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef } from 'react'

import { useStore } from '@app/store'
import type { Report } from '@graphql/types'

interface Props {
  report: Pick<Report, 'id' | 'emails'> & {
    slackChannel?: Pick<Report['slackChannel'], 'name'>
  }
  onPublish?: () => void
}

const PublishReportButton: FC<Props> = ({ report, onPublish = () => {} }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const publishReport = useStore.use.publishReport()
  let confirmEl = <Text>Are you sure you want to publish this report?</Text>

  const handlePublish = () => {
    publishReport(report.id)
    onPublish()

    onClose()
  }

  if (report.emails || report.slackChannel) {
    confirmEl = (
      <Stack>
        <Text>This will publish the report and send to the following recipients:</Text>
        {report.emails && (
          <Stack spacing={0}>
            <Text fontWeight="semibold">{report.emails.length > 1 ? 'Emails' : 'Email'}</Text>
            <Text>{report.emails.split(',').join(', ')}</Text>
          </Stack>
        )}
        {report.slackChannel && (
          <Stack spacing={0}>
            <Text fontWeight="semibold">Slack channel</Text>
            <Text>{report.slackChannel.name}</Text>
          </Stack>
        )}
      </Stack>
    )
  }

  return (
    <>
      <Button onClick={onOpen}>Publish</Button>

      <AlertDialog isCentered isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Publish report
            </AlertDialogHeader>

            <AlertDialogBody>{confirmEl}</AlertDialogBody>

            <AlertDialogFooter>
              <ButtonGroup>
                <Button ref={cancelRef} onClick={onClose} variant="secondary">
                  Cancel
                </Button>
                <Button onClick={handlePublish} variant="primary">
                  Publish
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default PublishReportButton
