import {
  Center,
  Spacer,
  HStack,
  Stack,
  Text,
  Menu,
  Divider,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react'
import type { FC } from 'react'
import { GrFormAdd } from 'react-icons/gr'
import { useParams, Link, useNavigate, useRevalidator } from 'react-router-dom'

import ReportsList from './components/drawerList'
import ReportsEmpty from './components/reportsEmpty'
import RecurringReportConfigurationsList from './recurring/components/drawerList'

import useGetObjectPage from '@app/hooks/useGetObjectPage'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import Pagination from '@app/shared/pagination'
import { useStore } from '@app/store'
import { ReportCreate } from '@graphql/documents/report.graphql'
import type { ReportCreateMutation } from '@graphql/queries'

const List: FC = () => {
  const { strategyId } = useParams()
  const navigate = useNavigate()
  const { revalidate } = useRevalidator()

  const actionMutation = useStore.use.actionMutation()
  const { collection, metadata } = useGetObjectPage('report')

  const recurrings = useGetObjectsByProperties('recurringReportConfiguration', {
    strategyId
  })

  if (!collection || collection.length === 0) {
    return <ReportsEmpty />
  }

  const createReport = async () => {
    // Generate a date field like month-day-year I can then interpolate into the report name

    const date = new Date()
    const formattedDate = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`

    const resp = await actionMutation<ReportCreateMutation>(ReportCreate, {
      name: `New report - ${formattedDate}`,
      strategyId
    })

    if (resp) {
      // Need to get the pagination data updated so it queries properly to update the left column
      revalidate()
      navigate(`/strategy/${strategyId}/reports/${resp?.data?.reportCreate?.report?.id}`)
    }
  }

  return (
    <Stack
      w="xs"
      py={2}
      borderRight="1px"
      borderRightColor="border.default"
      bgColor="bg.surface"
      divider={<Divider />}
      id="report-drawer"
    >
      <HStack px={4}>
        <Text variant="section-header">Reports</Text>
        <Spacer />
        <Menu>
          <MenuButton
            as={IconButton}
            fontSize="sm"
            aria-label="Create new report"
            icon={<GrFormAdd />}
            variant="tertiary"
          />
          <MenuList>
            <MenuItem onClick={createReport}>Create report</MenuItem>
            <MenuItem>
              <Link to="recurring/create">Create recurring report</Link>
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
      {recurrings.length > 0 && <RecurringReportConfigurationsList recurringReportConfigurations={recurrings} />}
      <ReportsList reports={collection} />
      <Center>
        <Pagination px={0} py={4} {...{ ...metadata }} scrollContainer="report-drawer" simple />
      </Center>
    </Stack>
  )
}

export default List
