import type { DocumentNode } from 'graphql'
import { produce } from 'immer'
import camelCase from 'lodash/camelCase'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'
import uniqBy from 'lodash/uniqBy'
import uniqueId from 'lodash/uniqueId'
import type { AnyVariables, DocumentInput, OperationContext, OperationResult, TypedDocumentNode } from 'urql'
import { type StateCreator } from 'zustand'

import type { ApplicationState, MapSliceState } from '@app/store/types'
import type { JSONValue, MapDomainObject } from '@app/types'
import { actionMutation, makeClient } from '@graphql/client'
import { CommentCreate, CommentDelete } from '@graphql/documents/comment.graphql'
import { DomainObjectsBulkLabel, DomainObjectsDelete } from '@graphql/documents/domain_object.graphql'
import { EntityCreate } from '@graphql/documents/entity.graphql'
import { EntityContainmentCreate, EntityContainmentDelete } from '@graphql/documents/entity_containment.graphql'
import { FavoriteCreate, FavoriteDelete } from '@graphql/documents/favorite.graphql'
import { IntegrationCreate, IntegrationUpdate } from '@graphql/documents/integration.graphql'
import {
  MetricDataPointCreate,
  MetricDataPointDelete,
  MetricDataPointUpdate
} from '@graphql/documents/metric_data_point.graphql'
import { RoadmapItemCreate, RoadmapItemDelete, RoadmapItemUpdate } from '@graphql/documents/roadmap.graphql'
import { DomainObjectUpdate } from '@graphql/documents/strategy.graphql'
import type {
  CommentCreateMutation,
  DomainObjectsBulkLabelMutation,
  DomainObjectsDeleteMutation,
  IntegrationCreateMutation,
  IntegrationUpdateMutation
} from '@graphql/queries'
import type { Comment, DomainObject, Favorite, Label, NodeObjectInput, RoadmapItem } from '@graphql/types'

export const initialAppState: Omit<
  ApplicationState,
  | 'actionMutation'
  | 'loaderQuery'
  | 'bulkAdd'
  | 'bulkDelete'
  | 'bulkLabel'
  | 'addObject'
  | 'addObjectPage'
  | 'setObject'
  | 'updateObject'
  | 'updateObjects'
  | 'deleteObject'
  | 'setCurrentUser'
  | 'setFilteredNodeIds'
  | 'favoriteObject'
  | 'unfavoriteObject'
  | 'addComment'
  | 'deleteComment'
  | 'updateCommentsCount'
  | 'createIntegration'
  | 'updateIntegration'
  | 'updateLabels'
  | 'addExistingRoadmapItem'
  | 'addNewRoadmapItem'
  | 'updateRoadmapItem'
  | 'deleteRoadmapItem'
  | 'addExistingEntityToRoadmapItem'
  | 'addNewEntityToRoadmapItem'
  | 'addEntityContainment'
  | 'deleteEntityContainment'
  | 'addMetricDataPoint'
  | 'deleteMetricDataPoint'
  | 'updateMetricDataPoint'
> = {
  node: {},
  basicCard: {},
  cardType: {},
  chat: {},
  comment: {},
  commentThread: {},
  correlationPair: {},
  credential: {},
  entity: {},
  favorite: {},
  goal: {},
  googleSheet: {},
  integration: {},
  mapImage: {},
  message: {},
  metric: {},
  metricDataPoint: {},
  metricSource: {},
  note: {},
  roadmapItem: {},
  playbook: {},
  section: {},
  strategy: {},
  user: {},
  currentUser: null,
  page: { basicCard: {}, entity: {}, metric: {}, metricDataPoint: {}, report: {}, strategy: {} },
  filteredNodeIds: []
}

// This can go down to just ApplicationState once the get().map call is removed
type AppState = ApplicationState & MapSliceState

type AppSliceFunction = StateCreator<AppState, [['zustand/devtools', 'never']], [], ApplicationState>

// Bust out this function so it can be optimized by the compiler instead of redefined each loop.
const partialObjectEquality = (partialObject, existingObject): boolean =>
  Object.keys(partialObject).some((key) => !Object.is(partialObject[key], existingObject[key]))

export const appSlice: AppSliceFunction = (set, get) => ({
  ...initialAppState,
  actionMutation: <
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    T = any,
    V extends AnyVariables & { input: Record<string, JSONValue> } = { input: Record<string, JSONValue> }
  >(
    mutation: DocumentInput<T, V>,
    input = {},
    context = {},
    variables = {}
  ): Promise<OperationResult<T>> => {
    const client = makeClient(get())
    return client.mutation<T, V>(mutation, { input, ...variables } as V, context).toPromise()
  },
  loaderQuery: <
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    T = any,
    V extends AnyVariables = AnyVariables
  >(
    query: string | DocumentNode | TypedDocumentNode<T, V>,
    variables: V = undefined,
    context: Partial<OperationContext> = {}
  ): Promise<OperationResult<T>> => {
    const client = makeClient(get())

    return client.query<T, V>(query, variables, context).toPromise()
  },
  // This needs to stay distinct from just wrapping addObject. The set/produce call produces a store update that causes
  // re-renders of all listening components. Doing set/produce N times can easily cause the app to freeze.
  bulkAdd: (objects) => {
    set(
      produce((draft) => {
        objects.forEach((object) => {
          const type = camelCase(object.classType)
          const existingObject = draft[type][object.id] || {}

          // only merge update if data has been added or changed
          const dataChanged = partialObjectEquality(object, existingObject)

          if (dataChanged) {
            draft[type][object.id] = { ...existingObject, ...object }
          }
        })
      })
    )
  },
  bulkDelete: async (objects, options = {}) => {
    const groupedObjects = objects.reduce(
      (acc, object) => {
        const [type, ids] = Object.entries(object)[0]

        acc[type] ||= new Set()
        ids.forEach((id) => acc[type].add(id))

        return acc
      },
      {} as Record<string, Set<string | number>>
    )

    let result = null

    if (!options.skipMutation) {
      const inputs = Object.entries(groupedObjects).map(([classType, ids]) => ({ [classType]: [...ids] }))
      result = await get().actionMutation<DomainObjectsDeleteMutation>(DomainObjectsDelete, { objects: inputs })
    }

    set(
      produce((draft) => {
        Object.entries(groupedObjects).forEach(([classType, ids]) => {
          draft[classType] = omit(draft[classType], Array.from(ids))

          if (options.updatePageCounts) {
            const page = draft.page[classType]
            if (page?.metadata) {
              const newCount = (page.metadata?.totalCount || 0) - ids.size
              page.metadata.totalCount = newCount
            }
          }
        })
      })
    )

    return result
  },
  bulkLabel: async (objects, add, remove) => {
    const groupedObjects = objects.reduce(
      (acc, object) => {
        const { id, classType } = object
        const type = camelCase(classType)

        acc[type] ||= new Set()
        acc[type].add(id)

        return acc
      },
      {} as Record<string, Set<string | number>>
    )

    const inputObjects = Object.entries(groupedObjects).map(([classType, ids]) => ({
      [classType]: [...ids]
    }))

    const addedLabels = add.map((name) => ({ name }))

    inputObjects.forEach((inputObject) => {
      const type = Object.keys(inputObject)[0]
      const ids = inputObject[type]
      const removeNames = new Set(remove.map((r) => r.toLowerCase()))
      const typeStore = get()[type]
      const updates = {}

      ids.forEach((id) => {
        const typeStoreObject = typeStore[id]

        if (!typeStoreObject) {
          return
        }

        const mdo = { ...typeStoreObject } // needs to be a new object
        const existingLabels = mdo?.labels || []

        mdo.labels = uniqBy([...addedLabels, ...existingLabels], 'name').filter(
          (label: Label) => !removeNames.has(label.name.toLowerCase())
        )

        updates[id.toString()] = mdo
      })

      set(
        produce((draft) => {
          draft[type] = {
            ...draft[type],
            ...updates
          }
        })
      )
    })

    const input = {
      objects: inputObjects,
      add,
      remove
    }

    return actionMutation<DomainObjectsBulkLabelMutation>(DomainObjectsBulkLabel, input)
  },
  addObject: (object, options = null) => {
    const type = options?.typeOverride || camelCase(object.classType)

    set(
      produce((draft) => {
        const existingObject = draft[type][object.id] || {}

        // only merge update if data has been added or changed
        const dataChanged = partialObjectEquality(object, existingObject)

        if (dataChanged) {
          draft[type][object.id] = { ...existingObject, ...object }
        }
      })
    )
  },
  addObjectPage: (objectType, collection, metadata) => {
    const { bulkAdd } = get()

    const order = collection.map((object) => object.id)

    bulkAdd(collection)

    set(
      produce((draft: Pick<AppState, 'page'>) => {
        draft.page[objectType] = { metadata, order }
      })
    )
  },
  updateObject: async (object, skipMutation = false) => get().updateObjects([object], skipMutation),
  updateObjects: async (objects, skipMutation = false) => {
    objects.map(async (object) => {
      const key = Object.keys(object)[0]
      const data = Object.values(object)[0] as MapDomainObject

      get().addObject({ ...data, classType: data.classType || key })
    })

    if (!skipMutation) {
      const input = { objects }

      // How does error handling get unpacked anymore around specific fields?
      return actionMutation(DomainObjectUpdate, input)
    }

    return new Promise((resolve) => {
      resolve(null)
    })
  },
  deleteObject: async (object, options = {}) => get().bulkDelete([{ [object.classType]: [object.id] }], options),
  setCurrentUser: (currentUser) => {
    set({ currentUser })
  },
  setFilteredNodeIds: (setter) => {
    const filteredNodeIds = setter(get().filteredNodeIds)

    set({ filteredNodeIds })
  },
  favoriteObject: async (object) => {
    const favoritableType = object.classType
    const favoritableId = object.id
    const input = { favoritableType, favoritableId }

    const optimisticUpdateObject = { metric: { ...object, favoriteId: 'optimistic-favorite' } }

    get().updateObject(optimisticUpdateObject, true)

    const resp = await actionMutation(FavoriteCreate, input)

    const favorite = resp?.data?.favoriteCreate?.favorite

    if (favorite) {
      get().addObject(favorite)

      const updatedObject = {} as NodeObjectInput
      updatedObject[favoritableType] = { ...object, favoriteId: favorite.id }
      get().updateObject(updatedObject, true)
    }
  },
  unfavoriteObject: async (object) => {
    const favoritableType = object.classType
    const favoritableId = object.id
    const input = { favoritableType, favoritableId }

    const optimisticUpdateObject = {} as NodeObjectInput
    optimisticUpdateObject[favoritableType] = { ...object, favoriteId: null }

    get().updateObject(optimisticUpdateObject, true)

    set(
      produce((draft) => {
        const favorites: Favorite[] = Object.values(draft.favorite)
        const favoriteId = favorites.find((f) => f.favoritableId === favoritableId)?.id

        if (favoriteId) {
          delete draft.favorite[favoriteId]
        }
      })
    )

    return actionMutation(FavoriteDelete, input)
  },
  addComment: async (comment, creator) => {
    const id = uniqueId('comment-')
    get().addObject({ ...comment, classType: 'comment', createdAt: new Date().toString(), id, creator } as Comment)

    const resp = await actionMutation<CommentCreateMutation>(CommentCreate, comment)
    const createdComment = resp?.data?.commentCreate?.comment

    if (createdComment) {
      await get().deleteObject({ id, classType: 'comment' }, { skipMutation: true })
      get().addObject(createdComment)
      get().updateCommentsCount(createdComment.commentableType, createdComment.commentableId, 'add')
    }
  },
  deleteComment: (comment) => {
    get().deleteObject(comment, { skipMutation: true })
    get().updateCommentsCount(comment.commentableType, comment.commentableId, 'delete')

    return actionMutation(CommentDelete, { commentId: comment.id })
  },
  updateCommentsCount: (commentableType, commentableId, action) => {
    const domainObjectType = camelCase(commentableType)
    const commentable = get()[domainObjectType][commentableId]
    let commentsCount = commentable?.commentsCount || 0

    if (!commentable) {
      return
    }

    if (action === 'add') {
      commentsCount += 1
    } else if (action === 'delete') {
      commentsCount -= 1
    }

    get().updateObject(
      {
        [domainObjectType]: {
          id: commentableId,
          classType: domainObjectType,
          commentsCount
        }
      } as unknown as NodeObjectInput,
      true
    )
  },
  createIntegration: async (attributes) => {
    const response = await actionMutation<IntegrationCreateMutation>(IntegrationCreate, attributes)
    const result = response?.data?.integrationCreate
    const integration = result?.integration

    if (integration) {
      get().addObject(integration)
    }

    return result
  },
  updateIntegration: async (integrationId, attributes) => {
    const response = await actionMutation<IntegrationUpdateMutation>(IntegrationUpdate, {
      integrationId,
      ...attributes
    })
    const result = response?.data?.integrationUpdate
    const integration = result?.integration

    if (integration) {
      get().addObject(integration)
    }

    return result
  },
  updateLabels: async (domainObject, labels = []) => {
    // Optimistically update the object.
    get().addObject({ ...domainObject, labels })

    // Send the mutation. Note: Whatever you pass as labels will be the new labels
    const results = await actionMutation(DomainObjectUpdate, {
      objects: {
        [domainObject.classType]: { id: domainObject.id, labels: labels.map((label) => label.name) }
      }
    })

    // Push the response back on
    const updated = results?.data?.domainObjectUpdate?.nodeObjects[0]?.labels
    get().addObject({ ...domainObject, labels: updated })
  },
  addExistingRoadmapItem: async (strategyId, object) => {
    // The roadmap item might not already be in the store, adding it here ensures it is.
    get().addObject(object)

    const id = uniqueId('roadmapItem_') // we'll use this after the data return to fix the id
    get().addObject({
      id,
      classType: 'roadmapItem',
      strategyId,
      domainObjectId: object.id,
      domainObjectType: object.classType,
      position: 0
    })

    const roadmapItems = get().roadmapItem || []
    const sorted = Object.entries(roadmapItems).sort((a, b) => a[1].position - b[1].position)

    const reindexRoadmapItems = {}
    const positions = []

    let itemIndex = 1
    sorted.forEach((roadmapItemPair) => {
      const [key, value] = roadmapItemPair
      const roadmapItem = { ...value } as undefined as RoadmapItem

      if (roadmapItem.strategyId === strategyId) {
        if (roadmapItem.id === id) {
          roadmapItem.position = 0
        } else {
          roadmapItem.position = itemIndex
          positions.push({ id: roadmapItem.id, position: itemIndex })

          itemIndex += 1
        }
      }

      reindexRoadmapItems[key] = roadmapItem
    })

    // Set the ordering of roadmap items
    set(
      produce((draft) => {
        draft.roadmapItem = reindexRoadmapItems
      })
    )

    const response = await actionMutation(RoadmapItemCreate, {
      positions,
      strategyId,
      object: {
        existing: {
          id: object.id,
          type: object.classType
        }
      }
    })

    const result = response?.data?.roadmapItemCreate
    if (result) {
      set(
        produce((draft) => {
          draft.roadmapItem[result.roadmapItem.id] = result.roadmapItem
          draft.roadmapItem = omitBy(draft.roadmapItem, { id })
        })
      )
    }
  },
  addNewRoadmapItem: async (strategyId, domainObjectType, domainObjectData) => {
    const { addObject } = get()

    const domainObjectUniqueId = uniqueId('domainObject_')
    const roadmapItemUniqueId = uniqueId('roadmapItem_')

    // Add the new domainObject with a temp id
    addObject({
      ...domainObjectData,
      id: domainObjectUniqueId,
      classType: domainObjectType,
      name: domainObjectData?.name
    })

    // Add the new roadmapItem with a temp id
    addObject({
      id: roadmapItemUniqueId,
      classType: 'roadmapItem',
      strategyId,
      domainObjectId: domainObjectUniqueId,
      domainObjectType,
      position: 0
    })

    const roadmapItems = get().roadmapItem || []
    const sorted = Object.entries(roadmapItems).sort((a, b) => a[1].position - b[1].position)

    const reindexRoadmapItems = {}
    const positions = []

    let itemIndex = 1
    sorted.forEach((roadmapItemPair) => {
      const [key, value] = roadmapItemPair
      const roadmapItem = { ...value } as undefined as RoadmapItem

      if (roadmapItem.strategyId === strategyId) {
        if (roadmapItem.id === roadmapItemUniqueId) {
          roadmapItem.position = 0
        } else {
          roadmapItem.position = itemIndex
          positions.push({ id: roadmapItem.id, position: itemIndex })

          itemIndex += 1
        }
      }

      reindexRoadmapItems[key] = roadmapItem
    })

    // Set the ordering of roadmap items
    set(
      produce((draft) => {
        draft.roadmapItem = reindexRoadmapItems
      })
    )

    const input = {
      strategyId,
      object: {
        [domainObjectType]: domainObjectData
      },
      positions
    }

    const response = await actionMutation(RoadmapItemCreate, input)

    const result = response?.data?.roadmapItemCreate
    if (result) {
      // Update the store with the new roadmapItem and domainObject, delete the old ones.
      set(
        produce((draft) => {
          draft[domainObjectType][result.roadmapItem.domainObject.id] = result.roadmapItem.domainObject
          draft.roadmapItem[result.roadmapItem.id] = result.roadmapItem
          draft.roadmapItem = omitBy(draft.roadmapItem, { id: roadmapItemUniqueId })
          draft[domainObjectType] = omitBy(draft[domainObjectType], { id: domainObjectUniqueId })
        })
      )
    }
  },
  updateRoadmapItem: (strategyId, object) => {
    const roadmapItems = get().roadmapItem as unknown as RoadmapItem[]
    const updatedIndex = object.position

    const keys = Object.keys(roadmapItems)
    const reindexRoadmapItems = {}
    const oldPosition = roadmapItems[object.id].position

    keys.forEach((key) => {
      const roadmapItem = { ...roadmapItems[key] } as undefined as RoadmapItem

      if (roadmapItem.strategyId === strategyId) {
        if (roadmapItem.id === object.id) {
          roadmapItem.position = updatedIndex
        } else if (roadmapItem.position === object.position) {
          roadmapItem.position = oldPosition
        }
      }

      reindexRoadmapItems[key] = roadmapItem
    })

    set(
      produce((draft) => {
        draft.roadmapItem = reindexRoadmapItems
      })
    )

    const positions = Object.entries(reindexRoadmapItems).map((roadmapItem: [string, RoadmapItem]) => ({
      id: roadmapItem[1].id,
      position: roadmapItem[1].position
    }))

    return actionMutation(RoadmapItemUpdate, {
      roadmapItemId: object.id,
      positions
    })
  },
  deleteRoadmapItem: (strategyId, roadmapItemId) => {
    const roadmapItems = get().roadmapItem as unknown as RoadmapItem[]
    const keys = Object.keys(roadmapItems)
    const reindexRoadmapItems = {}
    const object = roadmapItems[roadmapItemId]

    keys.forEach((key) => {
      const roadmapItem = { ...roadmapItems[key] } as undefined as RoadmapItem

      if (roadmapItem.strategyId === strategyId) {
        if (roadmapItem.position > object.position) {
          roadmapItem.position -= 1
        }
      }

      reindexRoadmapItems[key] = roadmapItem
    })

    delete reindexRoadmapItems[roadmapItemId]

    set(
      produce((draft) => {
        draft.roadmapItem = reindexRoadmapItems
      })
    )

    const positions = Object.entries(reindexRoadmapItems).map((roadmapItem: [string, RoadmapItem]) => ({
      id: roadmapItem[1].id,
      position: roadmapItem[1].position
    }))

    return actionMutation(RoadmapItemDelete, { roadmapItemId, positions })
  },
  addNewEntityToRoadmapItem: async (roadmapItemId, entity) => {
    const roadmapItem = get().roadmapItem[roadmapItemId] as unknown as RoadmapItem
    const container = get()[camelCase(roadmapItem.domainObjectType)][roadmapItem.domainObjectId]
    const temporaryEntityId = uniqueId('entity-')

    get().addObject({ ...entity, id: temporaryEntityId, foreignState: 'not_started', classType: 'entity' })

    set(
      produce((draft) => {
        const object = draft[container.classType][container.id]
        const { containeeIds } = object

        draft[container.classType][container.id].containeeIds = [...containeeIds, temporaryEntityId]
      })
    )

    const response = await actionMutation(EntityCreate, {
      ...entity,
      foreignState: 'not_started',
      containerId: container.id,
      containerType: container.classType
    })

    const result = response?.data?.entityCreate?.entity

    if (result) {
      set(
        produce((draft) => {
          const { containeeIds } = draft[container.classType][container.id] || []

          draft.entity[result.id] = result
          draft[container.classType][container.id].containeeIds = [
            ...containeeIds.filter((id) => id !== temporaryEntityId),
            result.id
          ]
          draft.entity = omitBy(draft.entity, { id: temporaryEntityId })
        })
      )
    }
  },
  addExistingEntityToRoadmapItem: (roadmapItemId, entity) => {
    const roadmapItem = get().roadmapItem[roadmapItemId] as unknown as RoadmapItem
    const container = get()[camelCase(roadmapItem.domainObjectType)][roadmapItem.domainObjectId]

    get().addObject(entity)
    get().addEntityContainment(container as DomainObject, entity.id)
  },
  addEntityContainment: (container, entityId) => {
    set(
      produce((draft) => {
        const object = draft[container.classType][container.id]
        const { containeeIds } = object

        draft[container.classType][container.id].containeeIds = [...containeeIds, entityId]
      })
    )

    return actionMutation(EntityContainmentCreate, {
      containerId: container.id,
      containerType: container.classType,
      entityId
    })
  },
  deleteEntityContainment: (container, entityId) => {
    set(
      produce((draft) => {
        const object = draft[container.classType][container.id]
        const containeeIds = object.containeeIds.filter((id) => id !== entityId)

        draft[container.classType][container.id].containeeIds = containeeIds
      })
    )

    return actionMutation(EntityContainmentDelete, {
      containerId: container.id,
      containerType: container.classType,
      entityId
    })
  },
  addMetricDataPoint: async (metricId, date, value, strategyId = null) => {
    const temporaryId = uniqueId('metricDataPoint-')

    set(
      produce((draft) => {
        draft.metricDataPoint[temporaryId] = { metricId, date, value }
      })
    )

    const res = await get().actionMutation(
      MetricDataPointCreate,
      { metricId, date, value: parseFloat(value) },
      {},
      { strategyId }
    )
    const metricDataPoint = res?.data?.metricDataPointCreate?.metricDataPoint

    if (metricDataPoint) {
      set(
        produce((draft) => {
          draft.metricDataPoint[metricDataPoint.id] = metricDataPoint
          draft.page.metricDataPoint.order.unshift(metricDataPoint.id)
          delete draft.metricDataPoint[temporaryId]
        })
      )
    }
  },
  updateMetricDataPoint: (metricDataPointId, date, value, strategyId = null) => {
    set(
      produce((draft) => {
        draft.metricDataPoint[metricDataPointId] = { ...draft.metricDataPoint[metricDataPointId], date, value }
      })
    )

    return get().actionMutation(
      MetricDataPointUpdate,
      {
        metricDataPointId,
        date,
        value: parseFloat(value)
      },
      {},
      { strategyId }
    )
  },
  deleteMetricDataPoint: (metricDataPointId) => {
    set(
      produce((draft) => {
        draft.page.metricDataPoint.order = draft.page.metricDataPoint.order.filter((id) => id !== metricDataPointId)
      })
    )

    get().deleteObject({ classType: 'metricDataPoint', id: metricDataPointId }, { skipMutation: true })

    return get().actionMutation(MetricDataPointDelete, { metricDataPointId })
  }
})
