import { HStack, Progress, Stack, StackDivider, Text, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'

import useGetObjectsByIds from '@app/hooks/useGetObjectsByIds'
import type { CardSize, MapDomainMetric } from '@app/types'
import { formatTotal } from '@app/utils/metricHelpers'
import type { Goal } from '@graphql/queries'
import { GoalStatusEnum } from '@graphql/types'

interface Props {
  metric: MapDomainMetric
  goalIds?: string[]
  size?: CardSize
  showTitle?: boolean
}

export const getProgressColor = (
  percentageComplete: number,
  startDate: Date,
  currentDate: Date,
  endDate: Date,
  oldestMetricDate: Date,
  newestMetricDate: Date
) => {
  const initialDate = startDate ?? oldestMetricDate
  const lastDate = endDate ?? newestMetricDate

  const now = new Date(currentDate)
  const start = new Date(initialDate)
  const end = new Date(lastDate)

  const timeFromBeginning = now.getTime() - start.getTime()

  const totalDays = end.getTime() - start.getTime()

  if (totalDays === 0) {
    return 'yellow'
  }

  const pseudoPercentageComplete = (timeFromBeginning / totalDays) * 100

  if (percentageComplete >= pseudoPercentageComplete) {
    return 'green'
  }

  return 'yellow'
}
const CardMetricGoals: FC<Props> = ({ metric, goalIds = [], size = 'normal', showTitle = true }) => {
  const goals = useGetObjectsByIds('goal', goalIds) as unknown as Goal[]
  const currentGoals = goals ? goals.filter((goal) => goal.status === GoalStatusEnum.Current) : []

  if (!currentGoals.length) {
    return null
  }

  let fontSize

  switch (size) {
    case 'normal':
      fontSize = 'md'
      break
    case 'large':
      fontSize = 'lg'
      break
    case 'small':
      fontSize = 'sm'
      break
    default:
      fontSize = 'md'
      break
  }

  return (
    <>
      {showTitle && (
        <Text mb={2} fontSize={fontSize} fontWeight="semibold">
          Key results
        </Text>
      )}
      <Stack divider={<StackDivider />}>
        {currentGoals.map((goal) => (
          <Stack key={goal.id} pb={1}>
            <HStack spacing={2}>
              <Text fontSize={fontSize}>{formatTotal(goal.targetValue, metric.displayFormat, metric)}</Text>
              <Text>•</Text>
              <Text fontSize={fontSize}>{goal.label}</Text>
            </HStack>
            <Tooltip
              aria-label="Progress"
              hasArrow
              label={
                <Text>
                  {`Starting: ${formatTotal(goal.startValue, metric.displayFormat, metric)}`}
                  <br />
                  {`Target: ${formatTotal(goal.targetValue, metric.displayFormat, metric)}`}
                  <br />
                  {`Actual: ${formatTotal(goal.currentValue, metric.displayFormat, metric)}`}
                  <br />
                  {Intl.NumberFormat(undefined, { style: 'percent' }).format(goal.percentageComplete / 100)} complete
                </Text>
              }
            >
              <HStack>
                <Progress
                  w="100%"
                  borderRadius="md"
                  colorScheme={getProgressColor(
                    goal.percentageComplete,
                    goal.startDate,
                    new Date(),
                    goal.endDate,
                    goal.oldestMetricDate,
                    goal.newestMetricDate
                  )}
                  value={goal.percentageComplete}
                />
                <Text fontSize="sm" fontWeight="semibold">
                  {Intl.NumberFormat(undefined, { style: 'percent' }).format(goal.percentageComplete / 100)}
                </Text>
              </HStack>
            </Tooltip>
          </Stack>
        ))}
      </Stack>
    </>
  )
}

export default CardMetricGoals
