import type { ChakraProps } from '@chakra-ui/react'
import { Avatar, HStack, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import profileImage from '@app/utils/profileImage'
import type { User } from '@graphql/queries'

interface Props {
  user?: Partial<User>
  displayEmail?: boolean
}

const overflowEllipsisProps: ChakraProps = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
}

const UserProfile: FC<Props> = ({ user = null, displayEmail = true, ...rest }) => {
  if (!user) {
    return null
  }

  return (
    <HStack flexShrink={0} px="2" spacing="4" {...rest}>
      <Avatar name={user.name} size="sm" src={profileImage(user.email)} />
      <Stack direction="column" spacing={0}>
        <Text color={user.disabledAt ? 'subtle' : 'emphasized'} fontSize="md" {...overflowEllipsisProps}>
          {user.name}
        </Text>
        {displayEmail && (
          <Text
            color="subtle"
            fontSize="sm"
            lineHeight="shorter"
            wordBreak="break-all"
            {...overflowEllipsisProps}
            maxW={96}
          >
            {user.email}
          </Text>
        )}
      </Stack>
    </HStack>
  )
}

export default UserProfile
