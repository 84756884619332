import type { ButtonProps } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/react'
import type { FC } from 'react'
import { BiAnalyse, BiPlay } from 'react-icons/bi'

import SubmitForReviewButton from './submitForReviewButton'
import UsePlaybookButton from './usePlaybookButton'

import type { Playbook } from '@graphql/queries'

interface Props extends ButtonProps {
  playbook: Playbook
}

const PlaybookButtons: FC<Props> = ({ playbook, ...rest }) => {
  const { id } = playbook

  return (
    <>
      <UsePlaybookButton
        playbookId={id}
        aria-label="Use this playbook"
        variant="primary"
        leftIcon={<Icon as={BiPlay} boxSize="5" />}
        {...rest}
      />

      <SubmitForReviewButton
        playbook={playbook}
        aria-label="Submit for review"
        leftIcon={<Icon as={BiAnalyse} boxSize="5" />}
        {...rest}
      >
        Publish
      </SubmitForReviewButton>
    </>
  )
}

export default PlaybookButtons
