import sortBy from 'lodash/sortBy'

import SelectInput from './selectInput'

import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { useMembersQuery } from '@graphql/queries'

const OwnerSelect = (props) => {
  const [
    {
      data: { members }
    }
  ] = useMembersQuery()

  return (
    <SelectInput {...props}>
      {sortBy(members, 'name').map((member) => (
        <option key={member.id} value={member.id}>
          {member.name}
        </option>
      ))}
    </SelectInput>
  )
}

export default withSuspenseWrapper(OwnerSelect, null)
