import { Heading, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useFetcher } from 'react-router-dom'

import EmptyState from '@app/shared/emptyState/emptyState'
import AggregatedEntities from '@app/shared/entities/aggregatedEntities'
import Loader from '@app/shared/loader'
import Pagination from '@app/shared/pagination'
import type { MapDomainReport } from '@app/types'
import type { ReportChangelogQuery } from '@graphql/queries'

interface Props {
  report: MapDomainReport
}

const ChangelogSection: FC<Props> = ({ report }) => {
  const fetcher = useFetcher()
  const [reportId, setReportId] = useState(report?.id)
  const [page, setPage] = useState(1)

  useEffect(() => {
    if ((fetcher.state === 'idle' && !fetcher.data) || report?.id !== reportId) {
      fetcher.load(`${window.location.pathname}/changelog/${page}`)
      setReportId(report.id)
    }
  }, [fetcher, page, report?.id, reportId])

  const onPaginate = (newPage: number) => setPage(newPage)

  const response: ReportChangelogQuery['report']['changelog'] = fetcher?.data?.report?.changelog || {}
  const { collection: changelogEntities = [], metadata } = response

  if (report.hideChangelog) {
    return null
  }

  if (changelogEntities.length === 0) {
    return (
      <Stack my={3}>
        <Heading size="xs">Changelog</Heading>
        <EmptyState title="No changelog entries found" />
      </Stack>
    )
  }

  return (
    <Stack my={3}>
      <Heading size="xs">Changelog</Heading>
      {fetcher.state === 'loading' ? (
        <Loader />
      ) : (
        <>
          <AggregatedEntities entities={changelogEntities} />
          <Pagination px={6} pb={4} {...{ ...metadata }} page={page} onPaginate={onPaginate} />
        </>
      )}
    </Stack>
  )
}

export default ChangelogSection
