import { Box, Button, Stack, Th } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import MetricCreateButton from './metricCreateButton'
import MetricCSVModal from './metricCSVModal'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import MetricDate from '@app/pages/metrics/components/columns/metricDate'
import MetricValue from '@app/pages/metrics/components/columns/metricValue'
import MetricDataPointListActions, {
  MetricDataPointListActionsHeader
} from '@app/pages/metrics/metricDataPoints/components/metricDataPointListActions'
import Can from '@app/shared/authorization/can'
import { abilitiesFor } from '@app/shared/authorization/caslProvider'
import EmptyState from '@app/shared/emptyState/emptyState'
import Pagination from '@app/shared/pagination'
import Table from '@app/shared/table'
import type { Column } from '@app/shared/table/types'
import type { MapDomainMetric } from '@app/types'
import type { User as UserType, MetricDataPoint as MetricDataPointType } from '@graphql/types'

type MetricDataPoint = Required<
  Pick<MetricDataPointType, 'id' | 'date' | 'value' | 'segmentFilters' | 'metric' | 'metricId'>
>

type User = Pick<UserType, 'abilities'>

const columns = (metric: MapDomainMetric, user: User) => {
  const basic: Column<MetricDataPoint>[] = [
    {
      header: 'Date',
      cell: MetricDate
    },
    {
      header: 'Value',
      cell: MetricValue
    }
  ]

  const abilities = abilitiesFor(user)

  if (abilities.can('update', 'metricDataPoint')) {
    basic.push({
      header: (
        <Th>
          <MetricDataPointListActionsHeader metric={metric} />
        </Th>
      ),
      cell: MetricDataPointListActions
    })
  }

  return basic
}

interface Props {
  metric?: MapDomainMetric
  collection?: MetricDataPoint[]
  metadata?: object
}

const DataTable: FC<Props> = ({ metric = null, collection = [], metadata = {} }) => {
  const { user } = useStoreCurrentUser()

  if (!collection.length) {
    return (
      <Box p={4}>
        <EmptyState title="No recorded data points yet">
          <Can I="create" a="metricDataPoint">
            <Stack>
              <MetricCreateButton text="Create your first one" />
              <MetricCSVModal metric={metric} />

              <Button as={Link} relative="path" to="../source">
                Connect a data source
              </Button>
            </Stack>
          </Can>
        </EmptyState>
      </Box>
    )
  }

  const data = collection
    .map((mdp) => ({ ...mdp, metric }))
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())

  return (
    <Stack spacing={4}>
      <Table columns={columns(metric, user)} data={data} />
      <Pagination px={6} pb={4} {...{ ...metadata }} />
    </Stack>
  )
}

export default DataTable
