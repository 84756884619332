import { Box, Button, Divider, Flex, Spacer } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo, useState, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'

import ChatDeleteButton from '@app/pages/maps/components/aiChat/components/chatDeleteButton'
import ChatsListMessage from '@app/pages/maps/components/aiChat/components/chatsListMessage'
import useGetPipelineChats from '@app/pages/maps/components/aiChat/useGetPipelineChats'
import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'
import type { PipelineEnum } from '@graphql/types'

type Props = {
  pipelineId: PipelineEnum
  strategyId?: string | null
}

const Item = ({ chat }) => {
  const [hovered, setHovered] = useState(false)

  const onMouseEnter = () => {
    setHovered(true)
  }

  const onMouseLeave = () => {
    setHovered(false)
  }

  return (
    <Box key={chat.id} pos="relative" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Link to={chat.id}>
        <ChatsListMessage messageId={chat.mostRecentPrompt.id} />
      </Link>
      {hovered && (
        <Flex pos="absolute" zIndex="1" top="0" right="0" bottom="0" align="center">
          <ChatDeleteButton chat={chat} />
        </Flex>
      )}
    </Box>
  )
}

const MemoItem = memo(Item)

const List: FC<Props> = ({ pipelineId, strategyId: propStrategyId }) => {
  const { strategyId: paramStrategyId } = useParams()
  const strategyId = propStrategyId || paramStrategyId
  const chats = useGetPipelineChats(pipelineId, strategyId)
  const sorted = useMemo(
    () =>
      chats.sort((a, b) => {
        const aDate = new Date(a.mostRecentPrompt.createdAt)
        const bDate = new Date(b.mostRecentPrompt.createdAt)

        return aDate > bDate ? 1 : -1
      }),
    [chats]
  )

  return (
    <>
      <Flex>
        <Spacer />
        <DrawerCloseButton variant="ghost" />
      </Flex>
      <Box overflowY="auto">
        {sorted.map((chat) => (
          <MemoItem key={chat.id} chat={chat} />
        ))}
      </Box>
      <Divider />
      <Button as={Link} m={4} to="new">
        Start a new conversation
      </Button>
    </>
  )
}

export default List
