import type { BoxProps } from '@chakra-ui/react'
import {
  Box,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  LightMode,
  Skeleton,
  Spacer,
  Stack,
  Text,
  Tooltip
} from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { BiAnalyse } from 'react-icons/bi'
import { Link } from 'react-router-dom'

import SubmitForReviewButton from '@app/pages/playbooks/components/submitForReviewButton'
import type { Playbook } from '@graphql/types'

type ImageWithOverlayProps = BoxProps & {
  playbook?: Playbook
  title: string
  description?: string
  url?: string
  alt?: string
  src?: string
  spacing?: string
  headingSize?: string
  cta?: string | ReactNode
  wrapWithLink?: boolean
}

const sample = (arr) => arr[Math.floor(Math.random() * arr.length)]

const ImageWithOverlay = (props: ImageWithOverlayProps) => {
  const {
    title,
    description,
    url,
    src = null,
    alt,
    spacing = '8',
    objectPosition = 'cover',
    headingSize = 'sm',
    cta = null,
    wrapWithLink = true,
    playbook = null,
    ...rest
  } = props

  const colors = ['accent', 'green', 'orange', 'purple', 'teal', 'cyan', 'yellow']

  const headingPadding = headingSize === 'sm' ? { base: '6', md: '10' } : { base: '2', md: '4' }
  const stackSpacing = headingSize === 'sm' ? 4 : 2

  const stack = (
    <Stack spacing={stackSpacing}>
      <Flex>
        <Box flexGrow={1}>
          <Heading color="white" _hover={{ color: 'link' }} size={headingSize}>
            {title}
          </Heading>
        </Box>
        <Box flexShrink={1}>
          {playbook && (
            <Tooltip aria-label="Submit for review" hasArrow label="Submit for review">
              <span>
                <SubmitForReviewButton
                  size="xs"
                  as={IconButton}
                  variant="primary"
                  aria-label="Submit for review"
                  icon={<Icon as={BiAnalyse} />}
                  playbook={playbook}
                />
              </span>
            </Tooltip>
          )}
        </Box>
      </Flex>
      {description && (
        <Text maxW="sm" color="white" fontSize="lg">
          {description}
        </Text>
      )}
    </Stack>
  )

  return (
    <Box pos="relative" overflow="hidden" w="100%" minW="sm" h="100%" minH="10rem" borderRadius="md" {...rest}>
      {src && (
        <Image
          boxSize="full"
          maxH={{ base: '240px', md: '100%' }}
          objectFit="cover"
          objectPosition={objectPosition}
          alt={alt}
          fallback={<Skeleton />}
          src={src}
        />
      )}
      <Box
        pos="absolute"
        w="100%"
        h="100%"
        px={headingPadding}
        pt={headingPadding}
        bgGradient="linear(to-t, blackAlpha.300 20%, blackAlpha.700)"
        bgColor={src ? null : sample(colors)}
        inset="0"
      >
        <Stack w="100%" h="100%" pb={6} spacing={spacing}>
          {wrapWithLink ? (
            <Link color="link" to={url}>
              {stack}
            </Link>
          ) : (
            stack
          )}
          <Spacer />
          {url && <LightMode>{cta}</LightMode>}
        </Stack>
      </Box>
    </Box>
  )
}

export default ImageWithOverlay
