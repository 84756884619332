import { Container } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import { useAsyncValue } from 'react-router-dom'

import LayoutRow from '@app/next/changelog/components/layoutRow'
import NavigationButton from '@app/pages/changelogs/components/navigationButton'
import EntityForm from '@app/pages/shared/entities/components/entityForm'
import { FormContainer } from '@app/shared/forms'
import HeadMetaData from '@app/shared/headMetaData'
import withAwait from '@app/shared/withAwait'
import { pathWithSearchParams } from '@app/utils/routeHelpers'
import type { ReportChangelogEntityQuery } from '@graphql/queries'

const Edit: FC = () => {
  const entity = useAsyncValue() as ReportChangelogEntityQuery['entity']

  if (!entity) {
    return null
  }

  const entityPath = pathWithSearchParams(`events/${entity.id}`)
  const backButtonPath = pathWithSearchParams('..')

  return (
    <Container>
      <HeadMetaData
        title={entity.name}
        description={entity.summaryShort}
        url={entityPath}
        imageUrl={entity.thumbnail?.url}
      />

      <LayoutRow pb={8}>
        <FormContainer>
          <EntityForm entity={entity} variant="drawer" />
        </FormContainer>
        <NavigationButton url={backButtonPath} label="Back to overview" leftIcon={<FiArrowLeft />} mt={6} />
      </LayoutRow>
    </Container>
  )
}

export default withAwait(Edit, 'entity')
