import type { FC } from 'react'
import { useEffect } from 'react'

import { useMatrixScoresContext } from '@app/pages/correlations/components/matrixScoresContext'
import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { useBatchOrganizationCorrelationScoresQuery } from '@graphql/queries'

interface Props {
  idPairs: Array<{
    sourceId: string
    targetId: string
  }>
}

const CorrelationScoreBatchLoader: FC<Props> = ({ idPairs }) => {
  const [{ data }] = useBatchOrganizationCorrelationScoresQuery({
    variables: {
      idPairs
    }
  })

  const { appendScores } = useMatrixScoresContext()

  useEffect(() => {
    if (data) {
      appendScores(data.organization.correlationPairs)
    }
  }, [appendScores, data])

  return null
}

export default withSuspenseWrapper(CorrelationScoreBatchLoader)
