import { Button, ButtonGroup } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'

import fetchDownload from '@app/lib/fetchDownload'
import type { MapDomainStrategy } from '@app/types'
import { Paths } from '@app/utils/routeHelpers'

type Props = {
  strategy: Pick<MapDomainStrategy, 'id'>
}

const DownloadDataButtons: FC<Props> = ({ strategy }) => {
  const [isDownloadingPlaybook, setIsDownloadingPlaybook] = useState(false)
  const [isDownloadingWorks, setIsDownloadingWorks] = useState(false)
  const [isDownloadingMetrics, setIsDownloadingMetrics] = useState(false)

  if (!strategy) {
    return null
  }

  const strategyId = strategy.id

  const downloadPlaybook = async () => {
    setIsDownloadingPlaybook(true)

    try {
      return await fetchDownload(Paths.downloadStrategyPlaybookPath({ strategyId }))
    } finally {
      setIsDownloadingPlaybook(false)
    }
  }

  const downloadWorks = async () => {
    setIsDownloadingWorks(true)

    try {
      return await fetchDownload(Paths.downloadStrategyWorksCSVPath({ strategyId }))
    } finally {
      setIsDownloadingWorks(false)
    }
  }

  const downloadMetrics = async () => {
    setIsDownloadingMetrics(true)

    try {
      return await fetchDownload(Paths.downloadStrategyMetricsCSVPath({ strategyId }))
    } finally {
      setIsDownloadingMetrics(false)
    }
  }

  return (
    <ButtonGroup isAttached>
      <Button colorScheme="blue" isLoading={isDownloadingPlaybook} onClick={downloadPlaybook}>
        Download Playbook
      </Button>
      <Button colorScheme="blue" isLoading={isDownloadingWorks} onClick={downloadWorks}>
        Download Work
      </Button>
      <Button colorScheme="blue" isLoading={isDownloadingMetrics} onClick={downloadMetrics}>
        Download Metric Data
      </Button>
    </ButtonGroup>
  )
}

export default DownloadDataButtons
