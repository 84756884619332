import { HStack, Spacer, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import CollapsedCommentsCount from './collapsedCommentsCount'

import CardConfidenceRating from '@app/next/domainObjects/confidenceRating/cardConfidenceRating'
import cardFontSize from '@app/shared/cards/cardFontSize'
import CardSection from '@app/shared/cards/components/cardSection'
import { useCardContext } from '@app/shared/cards/contexts/cardContext'
import CardEntityContributorsAndStatus, {
  shouldRenderCardEntityContributorsAndStatus
} from '@app/shared/cards/entity/cardEntityContributorsAndStatus'
import CardEntityStatsAndProgress, {
  shouldRenderCardEntityStatsAndProgress
} from '@app/shared/cards/entity/cardEntityStatsAndProgress'
import CardEntityStatus from '@app/shared/cards/entity/cardEntityStatus'
import type { CardSize, MapDomainEntity } from '@app/types'
import type { Entity } from '@graphql/queries'

type Props = {
  entity: MapDomainEntity
  size?: CardSize
}

const CollapsedEntity: FC<Props> = ({ entity, size = 'normal' }) => {
  const { editing } = useCardContext()
  const { commentsCount, confidenceRating, name, foreignState, workflowState } = entity
  const fontSize = cardFontSize('md', size)

  return (
    <Stack spacing={3}>
      <HStack>
        {confidenceRating && (
          <CardConfidenceRating type={confidenceRating} fieldName="confidenceRating" collapse size={size} />
        )}
        <Text fontSize={fontSize} fontWeight="semibold">
          {name}
        </Text>
        <Spacer />
        <CollapsedCommentsCount commentsCount={commentsCount} />
      </HStack>
      {shouldRenderCardEntityContributorsAndStatus(entity) && (
        <CardSection collapse>
          <CardEntityStatsAndProgress entity={entity as Entity} size={size} />
        </CardSection>
      )}
      {(shouldRenderCardEntityStatsAndProgress(entity) || foreignState) && (
        <CardSection collapse>
          <CardEntityContributorsAndStatus entity={entity as Entity} size={size}>
            <CardEntityStatus status={foreignState} workflowState={workflowState} editing={editing} size={size} />
          </CardEntityContributorsAndStatus>
        </CardSection>
      )}
    </Stack>
  )
}

export default CollapsedEntity
