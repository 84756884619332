import type { TextProps } from '@chakra-ui/react'
import {
  HStack,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef } from 'react'
import { PiCaretDown } from 'react-icons/pi'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { SearchList, SearchListItem } from '@app/shared/searchList'
import { useStore } from '@app/store'
import { basicCardDisplayName } from '@app/utils/basicCardHelpers'
import type { BasicCard } from '@graphql/types'
import { TeamTypeEnum } from '@graphql/types'

interface Props extends TextProps {
  basicCard: Pick<BasicCard, 'id' | 'teamType'>
}

const BasicCardTeamPopover: FC<Props> = ({ basicCard, ...rest }) => {
  const { user } = useStoreCurrentUser()
  const initialFocusRef = useRef()
  const { isOpen, onToggle, onClose } = useDisclosure()
  const updateObject = useStore.use.updateObject()
  const { id, teamType } = basicCard

  const displayType = basicCardDisplayName(teamType)

  if (user?.role !== 'editor') {
    return <Text {...rest}>{displayType}</Text>
  }

  const handleItemClick = (value: string) => {
    updateObject({ basicCard: { id, teamType: value as TeamTypeEnum } })

    onToggle()
  }

  return (
    <Popover initialFocusRef={initialFocusRef} isLazy isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <HStack cursor="pointer" onClick={onToggle}>
          <Text {...rest}>{displayType}</Text>
          <Icon as={PiCaretDown} />
        </HStack>
      </PopoverTrigger>
      <PopoverContent maxW={36}>
        <PopoverArrow />
        <PopoverBody m={0} p={0}>
          <SearchList
            searchField="team"
            currentValue={teamType}
            initialFocusRef={initialFocusRef}
            onChange={handleItemClick}
          >
            <SearchListItem text="Product" value={TeamTypeEnum.Product} />
            <SearchListItem text="Marketing" value={TeamTypeEnum.Marketing} />
            <SearchListItem text="Sales" value={TeamTypeEnum.Sales} />
            <SearchListItem text="Engineering" value={TeamTypeEnum.Engineering} />
            <SearchListItem text="Support" value={TeamTypeEnum.Support} />
          </SearchList>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default BasicCardTeamPopover
