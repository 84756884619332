import { Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import { ToggleInput } from '@app/shared/rawForms'
import type { Report } from '@graphql/types'

interface Props {
  report?: Pick<Report, 'hideBasicCards' | 'hideMetrics' | 'hideStrategies' | 'hideChangelog' | 'hideEntities'>
}

const HideSectionsInput: FC<Props> = ({ report }) => (
  <Stack spacing={2}>
    <Text mb={2} variant="section-header">
      Section Visibility
    </Text>
    <ToggleInput
      data-cy="hide-basic-cards-section"
      labelSize="sm"
      label="Hide basic cards section"
      name="hideBasicCards"
      defaultValue={report?.hideBasicCards}
    />
    <ToggleInput
      data-cy="hide-strategies-section"
      labelSize="sm"
      label="Hide maps"
      name="hideStrategies"
      defaultValue={report?.hideStrategies}
    />
    <ToggleInput
      data-cy="hide-metrics-section"
      labelSize="sm"
      label="Hide metrics section"
      name="hideMetrics"
      defaultValue={report?.hideMetrics}
    />
    <ToggleInput
      data-cy="hide-entities-section"
      labelSize="sm"
      label="Hide work section"
      name="hideEntities"
      defaultValue={report?.hideEntities}
    />
    <ToggleInput
      data-cy="hide-changelog-section"
      labelSize="sm"
      label="Hide changelog section"
      name="hideChangelog"
      defaultValue={report?.hideChangelog}
    />
  </Stack>
)

export default HideSectionsInput
