import type { SuccessResponse, UppyFile, UppyOptions } from '@uppy/core'
import type { FC, ReactNode } from 'react'

import DoubleloopUploader from './doubleloopUploader'
import UploaderModal from './uploaderModal'

type Props = {
  title?: string
  children?: ReactNode
  uppyOptions?: UppyOptions
  onUploadSuccess?: (file: UppyFile, response: SuccessResponse) => void
}

const DoubleloopUploaderModal: FC<Props> = ({
  title = 'Upload Files',
  children = null,
  uppyOptions = {},
  onUploadSuccess = () => {}
}) => {
  const uploaderBody = <DoubleloopUploader onUploadSuccess={onUploadSuccess} uppyOptions={uppyOptions} />

  return (
    <UploaderModal title={title} uploaderBody={uploaderBody}>
      {children}
    </UploaderModal>
  )
}

export default DoubleloopUploaderModal
