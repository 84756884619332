import lowerFirst from 'lodash/lowerFirst'
import type { FC } from 'react'
import { memo } from 'react'

import useGetObject from '@app/hooks/useGetObject'
import useGetObjectsByIds from '@app/hooks/useGetObjectsByIds'
import TaskColumn from '@app/pages/roadmaps/taskColumn'
import { groupEntitiesBy } from '@app/shared/entities/utils'
import type { DomainObject, Entity, RoadmapItem } from '@graphql/types'

interface Dictionary<T> {
  [index: string]: T
}

interface Props {
  roadmapItem: RoadmapItem
  expanded?: boolean
}

const WorkColumns: FC<Props> = ({ roadmapItem, expanded = true }) => {
  const object = useGetObject(
    roadmapItem.domainObjectId,
    lowerFirst(roadmapItem.domainObjectType) as 'entity' | 'metric' | 'strategy' | 'basicCard'
  ) as DomainObject
  // @ts-expect-error containeeIds is not a valid field for updateObject, do use without skipMutation
  const containeeIds = object?.containeeIds || []
  const entities = useGetObjectsByIds('entity', containeeIds)
  const groups: Dictionary<Array<Partial<Entity>>> = groupEntitiesBy(entities, 'workflowState')

  // Some entities may not have a workflow state, so we need to include them in the finished group
  const finishedGroup = (groups?.finished || []).concat(groups.null || [])

  return (
    <>
      <TaskColumn
        roadmapItemId={roadmapItem.id}
        container={object as DomainObject}
        tasks={groups?.not_started || []}
        state="not_started"
        expanded={expanded}
      />
      <TaskColumn
        roadmapItemId={roadmapItem.id}
        container={object as DomainObject}
        tasks={groups?.started || []}
        state="started"
        expanded={expanded}
      />
      <TaskColumn
        roadmapItemId={roadmapItem.id}
        container={object as DomainObject}
        tasks={groups?.reviewing || []}
        state="reviewing"
        expanded={expanded}
      />
      <TaskColumn
        roadmapItemId={roadmapItem.id}
        container={object as DomainObject}
        tasks={finishedGroup}
        state="finished"
        expanded={expanded}
      />
    </>
  )
}

export default memo(WorkColumns)
