import { useEffect } from 'react'
import { useFetcher } from 'react-router-dom'

import type { CustomerSubscription } from '@graphql/types'

type UseCustomerSubscription = () => CustomerSubscription | null

const useCustomerSubscription: UseCustomerSubscription = () => {
  const fetcher = useFetcher()

  useEffect(() => {
    fetcher.load('/customer-subscription')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return fetcher.data?.customerSubscription ?? null
}

export default useCustomerSubscription
