import { Box, Button, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import get from 'lodash/get'
import truncate from 'lodash/truncate'
import uniqBy from 'lodash/uniqBy'
import { useAsyncValue, useNavigate, Link } from 'react-router-dom'

import BatchLoadScores from './components/batchLoadScores'
import CorrelationPopover from './components/correlationPopover'
import { useMatrixScoresContext } from './components/matrixScoresContext'

import EmptyState from '@app/shared/emptyState/emptyState'
import { Card, PageHeader, PageStack } from '@app/shared/layout'
import withAwait from '@app/shared/withAwait'
import { correlationScoreColor } from '@app/utils/metricHelpers'
import type { CorrelationPair } from '@graphql/queries'

const makeIndex = (pairs, scores) => {
  const hasScores = pairs.reduce((acc, pair) => {
    const ids = [pair.source.id, pair.target.id]
    const scoreId = ids.sort().join(':')

    if (typeof scores[scoreId] === 'number' || typeof scores[ids.reverse().join(':')] === 'number') {
      acc[pair.source.id] = true
      acc[pair.target.id] = true
    }

    return acc
  }, {})

  return pairs.reduce((acc, pair) => {
    // index by sorted ids, so that only one side of the matrix populates
    const [source, target] = [pair.source.id, pair.target.id].sort()

    if (!hasScores[pair.source.id] || !hasScores[pair.target.id]) {
      return acc
    }

    acc[source] ||= {}
    acc[source][target] = pair

    return acc
  }, {})
}

const Matrix = ({ ...rest }) => {
  const {
    data: {
      organization: { correlationPairs }
    }
  } = useAsyncValue() as { data: { organization: { correlationPairs: CorrelationPair[] } } }
  const navigate = useNavigate()
  const { scores } = useMatrixScoresContext()

  const index = makeIndex(correlationPairs, scores)
  const pairs = correlationPairs

  const displayPairs = pairs.filter((pair) => {
    const [source, target] = [pair.source.id, pair.target.id].sort()

    return index[source]?.[target]
  })

  const displayData = uniqBy(
    displayPairs.flatMap((pair) => [
      { id: pair.source.id, name: pair.source.name },
      { id: pair.target.id, name: pair.target.name }
    ]),
    'id'
  )

  const emptyState = (
    <EmptyState title="To see the matrix, you must add metrics and populate them with data.">
      <Button as={Link} to="/metrics" variant="primary">
        Add metrics
      </Button>
    </EmptyState>
  )

  const withDataDisplay = (
    <Table {...rest}>
      <Thead>
        <Tr>
          <Th key="corner-space" pos="sticky" top={0} color="muted" bg="bg.muted" scope="col" />
          {displayData.map(({ id, name }) => (
            <Th key={id} pos="sticky" top={0} bg="bg.muted" scope="col">
              {truncate(name, { length: 40 })}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {displayData.map(({ id: rowId, name: rowName }, rowIdx) => (
          <Tr key={rowId}>
            <Td key={`${rowId}-${rowName}`} pos="sticky" left={0} bg="bg.muted">
              {truncate(rowName, { length: 40 })}
            </Td>
            {displayData.map(({ id: colId }, colIdx) => {
              const pairId = [rowId, colId].sort()
              const pair = get(index, pairId)
              const { source = {}, target = {} } = pair || {}
              const score = get(scores, pairId.join(':')) || get(scores, pairId.reverse().join(':'))

              let color
              let bgColor = 'bg.subtle'

              if (rowIdx >= colIdx) {
                color = 'bg.subtle'
              } else {
                const flipColors = source.positiveDirection !== target.positiveDirection
                bgColor = 'bg.surface'
                color = correlationScoreColor(score, flipColors).color
              }

              const url = `/correlations/graph/${source.id}/${target.id}`

              return (
                <Td key={colId} color={color} bgColor={bgColor}>
                  <CorrelationPopover onClick={() => navigate(url)}>
                    <Text>{score}</Text>
                  </CorrelationPopover>
                </Td>
              )
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
  return (
    <PageStack>
      <PageHeader
        title="Correlation matrix"
        subtitle="The correlation matrix shows the correlation for all pairs of metrics in the system. Be ready for surprises!"
      />
      <BatchLoadScores correlationPairs={pairs} />
      <Card>
        <Box overflow="auto" maxH="4xl">
          {displayData.length ? withDataDisplay : emptyState}
        </Box>
      </Card>
    </PageStack>
  )
}

export default withAwait(Matrix, 'pairs')
