import { Button, ButtonGroup } from '@chakra-ui/react'
import type { FC } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { BiFilter } from 'react-icons/bi'

import CardFiltersModal from '@app/pages/maps/components/map/filters/cardFilters/cardFiltersModal'
import { inputToFilters, isArrayFilter } from '@app/pages/maps/components/map/filters/cardFilters/helpers'
import MapCardFilter from '@app/pages/maps/components/map/filters/cardFilters/mapCardFilter'
import type { Props as MapNodeFilterProps } from '@app/pages/maps/components/map/filters/cardFilters/mapCardFilter'
import type { MapCardFiltersModalContextType as FiltersModalContextType } from '@app/pages/maps/components/map/filters/cardFilters/mapCardFiltersModalContext'
import type { NodeFiltersInput } from '@graphql/types'

interface Props {
  defaultFilters?: NodeFiltersInput
}

const Filters: FC<Props> = ({ defaultFilters = {} }) => {
  const [input, setInput] = useState<NodeFiltersInput>(defaultFilters)
  const [isOpen, setIsOpen] = useState(false)

  const onNewFacetClick = useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const onFilter: FiltersModalContextType['onFilter'] = useCallback((field, value) => {
    setInput((prev) => ({ ...prev, [field]: value }))
  }, [])

  const onRemoveClick: MapNodeFilterProps['onRemoveClick'] = useCallback((nodeFilter) => {
    setInput((prev) => {
      const next = { ...prev }

      if (isArrayFilter(nodeFilter.name)) {
        ;(next[nodeFilter.name] as string[]) = (next[nodeFilter.name] as string[]).filter((v) => v !== nodeFilter.value)
      } else {
        delete next[nodeFilter.name]
      }

      return next
    })
  }, [])

  const cardFilters = useMemo(() => inputToFilters(input), [input])

  return (
    <>
      <input type="hidden" value={JSON.stringify(input)} name="filters" />
      <ButtonGroup size="xs">
        <>
          <Button
            aria-label="Filter Data"
            colorScheme="gray"
            leftIcon={<BiFilter />}
            onClick={onNewFacetClick}
            variant="ghost"
          >
            Filter Data
          </Button>
          <CardFiltersModal isOpen={isOpen} onClose={onClose} onFilter={onFilter} cardFilters={cardFilters} />
        </>

        {cardFilters.map((filter) => (
          <MapCardFilter key={`${filter.name}:${filter.value}`} canEdit filter={filter} onRemoveClick={onRemoveClick} />
        ))}
      </ButtonGroup>
    </>
  )
}

export default Filters
