import { Link, Stack, TableContainer, Tbody, Td, Text, Tr } from '@chakra-ui/react'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import ConfidenceRatingPopover from '@app/next/confidenceRatingPopover'
import LabelsInput from '@app/next/forms/inline/labelsInput'
import SelectInput from '@app/next/forms/inline/selectInput'
import ViewerFieldWrapper from '@app/next/forms/inline/support/viewerFieldWrapper'
import TextInput from '@app/next/forms/inline/textInput'
import OwnerPopover from '@app/next/ownerPopover'
import MetricGraph from '@app/pages/metrics/components/metricGraph'
import DetailsTable from '@app/pages/shared/detailsTable'
import Can from '@app/shared/authorization/can'

interface Props {}

const MetricDetails: FC<Props> = () => {
  const { nodeId } = useParams()
  const metric = useGetObject(nodeId, 'metric')
  const { externalUrl, slug, aiGenerated } = metric

  return (
    <Stack spacing={4}>
      <MetricGraph metric={metric} />
      <Stack p={4} spacing={4}>
        <TableContainer>
          <DetailsTable variant="unstyled">
            <Tbody>
              <Tr>
                <Td pt={0} pl={0} fontWeight="semibold">
                  <Text>ID</Text>
                </Td>
                <Td pt={0} pr={0}>
                  <Text>{slug}</Text>
                </Td>
              </Tr>
              <ViewerFieldWrapper value={!!externalUrl}>
                <Tr>
                  <Td pt={0} pl={0} fontWeight="semibold">
                    {externalUrl ? (
                      <Link
                        color="link"
                        fontSize="sm"
                        whiteSpace="no"
                        wordBreak="break-all"
                        href={externalUrl}
                        isExternal
                      >
                        External URL
                      </Link>
                    ) : (
                      <Text>External URL</Text>
                    )}
                  </Td>
                  <Td pt={0} pr={0}>
                    <Stack spacing={0}>
                      <TextInput
                        domainObject={metric}
                        name="externalUrl"
                        defaultValue={externalUrl || ''}
                        placeholder="External url..."
                        type="url"
                      />
                    </Stack>
                  </Td>
                </Tr>
              </ViewerFieldWrapper>
              <ViewerFieldWrapper value={metric.owner}>
                <Tr>
                  <Td pl={0} fontWeight="semibold">
                    Owner
                  </Td>
                  <Td pr={0}>
                    <OwnerPopover object={metric} showName />
                  </Td>
                </Tr>
              </ViewerFieldWrapper>
              <ViewerFieldWrapper value={metric.labels}>
                <Tr>
                  <Td pl={0} fontWeight="semibold">
                    Labels
                  </Td>
                  <Td pr={0}>
                    <LabelsInput domainObject={metric} name="labels" />
                  </Td>
                </Tr>
              </ViewerFieldWrapper>
              <ViewerFieldWrapper value={typeof metric.confidenceRating === 'number'}>
                <Tr>
                  <Td pb={0} pl={0} fontWeight="semibold">
                    Confidence
                  </Td>
                  <Td pr={0} pb={0}>
                    <ConfidenceRatingPopover domainObject={metric} />
                  </Td>
                </Tr>
              </ViewerFieldWrapper>
              <Can I="view" an="admin">
                <Tr>
                  <Td pb={0} pl={0} fontWeight="semibold">
                    AI Generated
                  </Td>
                  <Td pr={0} pb={0}>
                    <SelectInput
                      domainObject={metric}
                      name="aiGenerated"
                      defaultValue={aiGenerated?.toString()}
                      placeholder={null}
                    >
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </SelectInput>
                  </Td>
                </Tr>
              </Can>
            </Tbody>
          </DetailsTable>
        </TableContainer>
      </Stack>
    </Stack>
  )
}

export default MetricDetails
