import type { ButtonProps } from '@chakra-ui/react'
import { Alert, AlertDescription, Button, SimpleGrid, Spinner, Stack, VisuallyHiddenInput } from '@chakra-ui/react'
import type { FC, FormEventHandler, MouseEventHandler } from 'react'
import { useCallback, useRef, useState } from 'react'
import { Outlet, useParams, useRevalidator } from 'react-router-dom'

import CredentialInput from './components/credentialInput'
import ImportTimeInput from './components/importTimeInput'
import ConfigureMetricSource from './configureMetricSource'
import MetricSourceDeleteButton from './metricSourceDeleteButton'
import MetricSourceImportStatus from './metricSourceImportStatus'
import MetricSourceRunButton from './metricSourceRunButton'
import TestMetricSourceButton from './testMetricSourceButton'

import useGetObject from '@app/hooks/useGetObject'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import { createMetricSource, updateMetricSource } from '@app/pages/metrics/components/metricSource/utils'
import { RawForm } from '@app/shared/rawForms/form'
import useToast from '@app/shared/toast'
import type { MetricSourceFormApi } from '@app/types'
import type { MetricSourceQuery } from '@graphql/queries'

type MetricSource = Omit<MetricSourceQuery['metricSource'], 'metric'> & {
  metric: Pick<MetricSourceQuery['metricSource']['metric'], 'id' | 'name'>
}

type EditingProps = ButtonProps & {
  metricSource: MetricSource | null
}

type ViewingProps = {
  onEditClick: MouseEventHandler<HTMLButtonElement>
  metricSource: MetricSource | null
  onDelete: () => void
}

export type CredentialSelectApi = {
  clear: () => void
}

const Editing: FC<EditingProps> = ({ metricSource, ...rest }) => {
  const columns = metricSource ? 2 : 1

  return (
    <SimpleGrid columns={columns} spacing={2}>
      {metricSource && (
        <Button type="reset" variant="secondary">
          Discard changes
        </Button>
      )}
      <Button type="submit" variant="primary" {...rest}>
        Save changes
      </Button>
    </SimpleGrid>
  )
}

const Viewing: FC<ViewingProps> = ({ onDelete: propOnDelete, metricSource, onEditClick }) => {
  const { revalidate } = useRevalidator()
  const onDelete = () => {
    propOnDelete()
    revalidate()
  }

  return (
    <Stack>
      <Button onClick={onEditClick} variant="secondary">
        Edit
      </Button>
      <TestMetricSourceButton metricSource={metricSource} variant="secondary" />
      <MetricSourceRunButton metricSource={metricSource} variant="secondary" width="100%" />
      {metricSource && <MetricSourceImportStatus metricSource={metricSource} />}
      {metricSource && <MetricSourceDeleteButton metricSourceId={metricSource.id} onDelete={onDelete} />}
      {/* <MetricSourceActivities metricSource={metricSource} /> */}
    </Stack>
  )
}

const MetricSourceShow = () => {
  const { nodeId: metricId } = useParams()
  const filter = useCallback((ms: MetricSource) => ms.metric?.id === metricId, [metricId])
  const metricSource = useGetObjectsByProperties('metricSource', filter)[0]
  const defaultCredential = metricSource?.credential
  const defaultSourceName = defaultCredential?.sourceName || metricSource?.sourceName
  const [credential, setCredential] = useState(defaultCredential)
  const [sourceName, setSourceName] = useState(defaultSourceName)
  const [editing, setEditing] = useState(!metricSource)
  const [isDisabled, setIsDisabled] = useState(false)
  const configureSourceRef = useRef<MetricSourceFormApi>(null)
  const credentialSelectRef = useRef<CredentialSelectApi>(null)
  const toast = useToast()

  const metric = useGetObject(metricId, 'metric')
  const processing = !!metric?.indicatorCounter

  const onEditCancel = () => {
    configureSourceRef.current?.reset?.()
    setEditing(false)
    setSourceName(defaultSourceName)
    setCredential(defaultCredential)
  }

  const onDelete = () => {
    configureSourceRef.current?.clear?.()
    credentialSelectRef.current?.clear()
    setEditing(true)
    setSourceName('')
    setCredential(null)
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    try {
      setIsDisabled(true)
      const formData = new FormData(e.currentTarget)
      const method = metricSource ? updateMetricSource : createMetricSource

      await method({ metricId, formData })
      setEditing(false)
    } catch (err) {
      toast({
        title: 'Error saving metric source',
        description: `There was an error saving this metric source: ${err.error.message}.`,
        status: 'error'
      })
    } finally {
      setIsDisabled(false)
    }
  }

  const invalidConfig = configureSourceRef.current?.isValid?.() === false

  return (
    <Stack p={4}>
      {processing && (
        <Alert borderRadius="md" status="warning">
          <Spinner mr={2} size="sm" />
          <AlertDescription fontSize="sm">
            Fetching and processing your metric data, this may take a minute.
          </AlertDescription>
        </Alert>
      )}

      <RawForm onSubmit={onSubmit} onReset={onEditCancel} variant="drawer">
        {metricSource && <VisuallyHiddenInput defaultValue={metricSource.id} name="metricSourceId" />}
        <Stack>
          <CredentialInput
            credential={credential}
            defaultSourceName={sourceName}
            width="100%"
            isDisabled={isDisabled || !!metricSource}
            onSelect={(newSourceName, newCredential) => {
              setSourceName(newSourceName)
              setCredential(newCredential)
            }}
            apiRef={credentialSelectRef}
          />
          <ConfigureMetricSource
            apiRef={configureSourceRef}
            metricSource={metricSource}
            sourceName={sourceName}
            isDisabled={isDisabled || !editing}
            credentialId={credential?.id}
          />
          {(metricSource || sourceName) && (
            <ImportTimeInput metricSource={metricSource} isDisabled={isDisabled || !editing} />
          )}
          {editing ? (
            <Editing metricSource={metricSource} isDisabled={isDisabled || !sourceName || invalidConfig} />
          ) : (
            <Viewing metricSource={metricSource} onEditClick={() => setEditing(true)} onDelete={onDelete} />
          )}
        </Stack>
      </RawForm>
      <Outlet />
    </Stack>
  )
}

export default MetricSourceShow
