import type { FC } from 'react'
import { useState } from 'react'

import CardColorButton from './cardColorButton'
import CollapseNodeButton from './collapseNodeButton'
import DeleteButton from './deleteButton'
import DuplicateButton from './duplicateButton'
import ExpandCollapsedIncomersButton from './expandCollapsedIncomersButton'
import SizeButton from './sizeButton'

import type { MapDomainNode } from '@app/types'

interface Props {
  node: MapDomainNode
}

const CommonButtons: FC<Props> = ({ node }) => {
  const [moreOptionsOpen, setMoreOptionsOpen] = useState('none')

  return (
    <>
      <DuplicateButton nodes={node} />
      <CardColorButton
        node={node}
        isOpen={moreOptionsOpen === 'color'}
        onOpen={() => setMoreOptionsOpen('color')}
        onClose={() => setMoreOptionsOpen('none')}
      />
      <SizeButton
        node={node}
        isOpen={moreOptionsOpen === 'size'}
        onOpen={() => setMoreOptionsOpen('size')}
        onClose={() => setMoreOptionsOpen('none')}
      />
      <CollapseNodeButton node={node} />
      <ExpandCollapsedIncomersButton node={node} />
      <DeleteButton node={node} />
    </>
  )
}

export default CommonButtons
