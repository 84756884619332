import { HStack, Text, VisuallyHiddenInput, Box } from '@chakra-ui/react'
import type { FC, MutableRefObject } from 'react'
import { useImperativeHandle, useRef, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import MetricsListField from './metricListField'

import useGetObject from '@app/hooks/useGetObject'
import { SelectInput } from '@app/shared/rawForms'
import type { MetricSourceFormApi } from '@app/types'
import type { MetricSource as BaseMetricSource } from '@graphql/types'

type CalculatedMetricInputBase = BaseMetricSource['calculatedMetricInputs'][0]
type CalculatedMetricInput = Pick<CalculatedMetricInputBase, 'id' | 'metricId'> & {
  metric: Pick<Metric, 'id' | 'name'>
}

type Metric = BaseMetricSource['metric']
type MetricSource = Pick<BaseMetricSource, 'id' | 'configuration'> & {
  metric: Pick<Metric, 'id'>
  calculatedMetricInputs?: CalculatedMetricInput[]
}
export interface Props {
  metricSource: MetricSource | null
  isDisabled: boolean
  apiRef: MutableRefObject<MetricSourceFormApi>
}

type MetricFieldsProps = Props & {
  calculation: string
}

const CALCULATION_FIELDS = {
  addition: {
    op: '+'
  },
  subtraction: {
    op: '-'
  },
  multiplication: {
    op: 'x'
  },
  division: {
    op: '÷'
  }
}

const MetricFields: FC<MetricFieldsProps> = ({ apiRef, metricSource, calculation, isDisabled }) => {
  const { metricId } = useParams()
  const field1Ref = useRef<MetricSourceFormApi>(null)
  const field2Ref = useRef<MetricSourceFormApi>(null)

  // exclude the current (output) metric, to avoid infinite looping
  const exclusions = useMemo(() => [metricId, metricSource?.metric?.id].filter((id) => id), [metricSource?.metric?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  const firstInputId = metricSource?.calculatedMetricInputs?.[0]?.id
  const secondInputId = metricSource?.calculatedMetricInputs?.[1]?.id

  const firstMetric = useGetObject(metricSource?.calculatedMetricInputs?.[0]?.metricId, 'metric')
  const firstMetricOption = firstMetric && { value: firstMetric.id, label: firstMetric.name }

  const secondMetric = useGetObject(metricSource?.calculatedMetricInputs?.[1]?.metricId, 'metric')
  const secondMetricOption = secondMetric && { value: secondMetric.id, label: secondMetric.name }

  useImperativeHandle(
    apiRef,
    () => ({
      reset: () => {
        field1Ref.current?.reset()
        field2Ref.current?.reset()
      },
      clear: () => {
        field1Ref.current?.clear()
        field2Ref.current?.clear()
      }
    }),
    []
  )

  return (
    <HStack alignItems="center" opacity={isDisabled ? 0.4 : 1.0}>
      <Box w="100%">
        <MetricsListField
          apiRef={field1Ref}
          name="calculatedMetricInputsAttributes[0].metricId"
          placeholder="Select a metric"
          exclusions={exclusions}
          isDisabled={isDisabled}
          isRequired
          defaultValue={firstMetricOption}
        />
      </Box>
      {firstInputId && (
        <VisuallyHiddenInput name="calculatedMetricInputsAttributes[0].id" readOnly value={firstInputId} />
      )}
      <VisuallyHiddenInput
        name="calculatedMetricInputsAttributes[0].metricSourceId"
        readOnly
        value={metricSource?.id}
      />
      <VisuallyHiddenInput name="calculatedMetricInputsAttributes[0].position" readOnly value={0} />
      <Text fontSize="4xl">{CALCULATION_FIELDS[calculation].op}</Text>
      <Box w="100%">
        <MetricsListField
          apiRef={field2Ref}
          name="calculatedMetricInputsAttributes[1].metricId"
          placeholder="Select a metric"
          exclusions={exclusions}
          isDisabled={isDisabled}
          isRequired
          defaultValue={secondMetricOption}
        />
      </Box>
      {secondInputId && (
        <VisuallyHiddenInput name="calculatedMetricInputsAttributes[1].id" readOnly value={secondInputId} />
      )}
      <VisuallyHiddenInput
        name="calculatedMetricInputsAttributes[1].metricSourceId"
        readOnly
        value={metricSource?.id}
      />
      <VisuallyHiddenInput name="calculatedMetricInputsAttributes[1].position" readOnly value={1} />
    </HStack>
  )
}

const Calculated: FC<Props> = ({ apiRef, metricSource, isDisabled }) => {
  const [calculation, setCalculation] = useState(metricSource?.configuration?.calculation || 'division')

  const onChange = (e) => {
    setCalculation(e.target.value)
  }

  return (
    <>
      <SelectInput
        name="configuration.calculation"
        label="Calculation"
        placeholder=""
        isRequired
        isDisabled={isDisabled}
        defaultValue={calculation}
        onChange={onChange}
      >
        <option value="addition">Addition</option>
        <option value="subtraction">Subtraction</option>
        <option value="multiplication">Multiplication</option>
        <option value="division">Division</option>
      </SelectInput>
      <MetricFields apiRef={apiRef} metricSource={metricSource} calculation={calculation} isDisabled={isDisabled} />
    </>
  )
}

export default Calculated
