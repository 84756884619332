import { createStandaloneToast, ChakraProvider, ColorModeScript, CSSReset } from '@chakra-ui/react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import router from '@app/router'
import Loader from '@app/shared/loader'
import '@fontsource-variable/open-sans'
import '@fontsource-variable/spline-sans'
import theme from '@app/theme'

const container = document.getElementById('react-root')
const root = createRoot(container)
const { ToastContainer } = createStandaloneToast()

root.render(
  <ChakraProvider theme={theme}>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <CSSReset />
    <RouterProvider router={router} fallbackElement={<Loader />} />
    <ToastContainer />
  </ChakraProvider>
)
