import { matchPath, redirect } from 'react-router-dom'

import { fetchCurrentUser } from '@app/hooks/useCurrentUser'
import { abilitiesFor } from '@app/shared/authorization/caslProvider'

const currentUser = async () => fetchCurrentUser()

const requiresUserConfig = async (fn) => {
  const { user, identityId } = await currentUser()

  if (!fn(user, identityId)) {
    return redirect('/')
  }

  return {}
}

const requireOrganization = async ({ request }) => {
  const { user, identityId } = await currentUser()
  const url = new URL(request.url)

  if (!user && !identityId) {
    throw new Response('Unauthorized', { status: 401, statusText: 'Unauthorized' })
  }

  // These might be the wrong place for handling onboarding redirects. Just not sure where might be better?
  if (!user && identityId && !matchPath('onboarding/user', url.pathname)) {
    return redirect('/onboarding/user')
  }

  if (!user.confirmedAt && !matchPath('onboarding/verify_email', url.pathname)) {
    return redirect('/onboarding/verify_email')
  }

  if (!user.organization && !matchPath('onboarding/organization', url.pathname)) {
    return redirect('/onboarding/organization')
  }

  return { user }
}

const requiresNoUser = async () => {
  const { user } = await fetchCurrentUser()

  if (user) {
    return redirect('/')
  }

  return {}
}

const requiresNoUserOrValidated = async () => {
  const userObject = await currentUser()
  const { user } = userObject || {}

  if (user) {
    if (!user.confirmedAt || !user.organization) {
      return redirect('/')
    }
  }

  return {}
}

const requiresAuthorization = async (action, subject) => {
  const userObject = await currentUser()
  const { user } = userObject || {}

  if (!user) {
    throw new Response('Unauthorized', { status: 401, statusText: 'Unauthorized' })
  }

  const abilities = abilitiesFor(user)

  if (!abilities.can(action, subject)) {
    throw new Response('Unauthorized', { status: 401, statusText: 'Unauthorized' })
  }

  return { authorization: 'ok', user }
}

export {
  currentUser,
  requiresUserConfig,
  requireOrganization,
  requiresNoUser,
  requiresAuthorization,
  requiresNoUserOrValidated
}
