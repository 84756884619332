import type { IconProps } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/react'
import type { FC } from 'react'

const WordmarkColor: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 512 96" {...props}>
    <g fill="currentColor">
      <path
        d="M60 30C60 26.6863 62.6863 24 66 24C69.3137 24 72 26.6863 72 30C72 33.3137 69.3137 36 66 36V44C73.732 44 80 37.732 80 30C80 22.268 73.732 16 66 16C58.268 16 52 22.268 52 30V66C52 78.1503 42.1503 88 30 88C17.8497 88 8 78.1503 8 66C8 53.8497 17.8497 44 30 44V36C13.4315 36 0 49.4315 0 66C0 82.5685 13.4315 96 30 96C46.5685 96 60 82.5685 60 66V30Z"
        fill="url(#paint0_linear_115_29434)"
      />
      <path
        d="M30 52C22.268 52 16 58.268 16 66C16 73.732 22.268 80 30 80C37.732 80 44 73.732 44 66V30C44 17.8497 53.8497 8 66 8C78.1503 8 88 17.8497 88 30C88 42.1503 78.1503 52 66 52V60C82.5685 60 96 46.5685 96 30C96 13.4315 82.5685 0 66 0C49.4315 0 36 13.4315 36 30V66C36 69.3137 33.3137 72 30 72C26.6863 72 24 69.3137 24 66C24 62.6863 26.6863 60 30 60V52Z"
        fill="url(#paint1_linear_115_29434)"
      />
      <path
        d="M125.456 69.8162V62.6205H138.303C142.079 62.6205 145.302 61.8595 147.973 60.3373C150.644 58.8151 152.67 56.6933 154.051 53.9719C155.478 51.2504 156.192 48.1369 156.192 44.6313C156.192 41.1719 155.478 38.1276 154.051 35.4984C152.67 32.8692 150.644 30.8396 147.973 29.4097C145.348 27.9337 142.125 27.1957 138.303 27.1957H125.595V20H138.303C143.737 20 148.479 21.0148 152.532 23.0443C156.584 25.0739 159.715 27.9568 161.925 31.693C164.181 35.3831 165.309 39.742 165.309 44.7697C165.309 49.7975 164.181 54.2025 161.925 57.9849C159.669 61.7211 156.514 64.627 152.462 66.7027C148.456 68.7784 143.76 69.8162 138.372 69.8162H125.456ZM120 69.8162V20H128.979V69.8162H120Z"
        fill="black"
      />
      <path
        d="M188.306 70.5081C184.576 70.5081 181.284 69.724 178.429 68.1557C175.62 66.5874 173.433 64.4195 171.868 61.6519C170.302 58.8382 169.519 55.6324 169.519 52.0346C169.519 48.4368 170.302 45.2771 171.868 42.5557C173.433 39.8342 175.62 37.7124 178.429 36.1903C181.284 34.622 184.576 33.8378 188.306 33.8378C192.082 33.8378 195.374 34.622 198.183 36.1903C200.992 37.7124 203.179 39.8342 204.744 42.5557C206.31 45.2771 207.093 48.4368 207.093 52.0346C207.093 55.6324 206.287 58.8382 204.675 61.6519C203.11 64.4195 200.923 66.5874 198.114 68.1557C195.305 69.724 192.036 70.5081 188.306 70.5081ZM188.306 63.52C190.148 63.52 191.806 63.0587 193.279 62.1362C194.752 61.1676 195.904 59.8299 196.732 58.1232C197.561 56.3705 197.976 54.3178 197.976 51.9654C197.976 48.5059 197.055 45.7845 195.213 43.8011C193.417 41.8177 191.115 40.8259 188.306 40.8259C185.497 40.8259 183.172 41.8177 181.33 43.8011C179.488 45.7845 178.567 48.5059 178.567 51.9654C178.567 54.3178 178.982 56.3705 179.811 58.1232C180.685 59.8299 181.837 61.1676 183.264 62.1362C184.737 63.0587 186.418 63.52 188.306 63.52Z"
        fill="black"
      />
      <path
        d="M224.89 70.5081C222.45 70.5081 220.332 70.0007 218.536 68.9859C216.786 67.925 215.428 66.4951 214.461 64.6962C213.54 62.8973 213.08 60.8677 213.08 58.6076V34.5297H221.782V56.8086C221.782 58.9766 222.312 60.6141 223.371 61.7211C224.476 62.8281 226.019 63.3816 227.999 63.3816C229.794 63.3816 231.36 62.9665 232.695 62.1362C234.077 61.3059 235.159 60.1528 235.941 58.6768C236.77 57.1546 237.185 55.4479 237.185 53.5568L238.083 61.9978C236.931 64.5809 235.205 66.6566 232.902 68.2249C230.646 69.747 227.976 70.5081 224.89 70.5081ZM237.461 69.8162V61.5135H237.185V34.5297H245.957V69.8162H237.461Z"
        fill="black"
      />
      <path
        d="M275.277 70.5081C271.916 70.5081 269.153 69.724 266.989 68.1557C264.871 66.5413 263.559 64.3272 263.052 61.5135L263.674 61.4443V69.8162H255.178V20H263.881V42.4865L263.259 42.3481C263.858 39.7189 265.262 37.6432 267.473 36.1211C269.729 34.5989 272.515 33.8378 275.83 33.8378C278.961 33.8378 281.678 34.5989 283.98 36.1211C286.328 37.5971 288.147 39.6959 289.437 42.4173C290.726 45.1387 291.37 48.3445 291.37 52.0346C291.37 55.7708 290.703 59.0227 289.367 61.7903C288.032 64.5578 286.167 66.7027 283.773 68.2249C281.378 69.747 278.547 70.5081 275.277 70.5081ZM273.067 63.3124C275.876 63.3124 278.109 62.3438 279.767 60.4065C281.425 58.4231 282.253 55.6555 282.253 52.1038C282.253 48.5521 281.401 45.8076 279.698 43.8703C278.04 41.933 275.807 40.9643 272.998 40.9643C270.235 40.9643 268.002 41.956 266.298 43.9395C264.595 45.8768 263.743 48.6213 263.743 52.173C263.743 55.7247 264.595 58.4692 266.298 60.4065C268.002 62.3438 270.258 63.3124 273.067 63.3124Z"
        fill="black"
      />
      <path d="M298.077 69.8162V20H306.779V69.8162H298.077Z" fill="black" />
      <path
        d="M331.835 70.5081C328.105 70.5081 324.836 69.724 322.027 68.1557C319.265 66.5874 317.1 64.4195 315.535 61.6519C314.015 58.8843 313.256 55.7016 313.256 52.1038C313.256 48.5059 314.015 45.3463 315.535 42.6249C317.1 39.8573 319.265 37.7124 322.027 36.1903C324.79 34.622 328.013 33.8378 331.697 33.8378C335.197 33.8378 338.236 34.5759 340.814 36.0519C343.393 37.4818 345.396 39.5114 346.823 42.1405C348.251 44.7697 348.964 47.8371 348.964 51.3427C348.964 51.9885 348.941 52.5881 348.895 53.1416C348.849 53.6951 348.78 54.2486 348.688 54.8022H318.643V48.7135H341.988L340.193 50.3741C340.193 47.053 339.433 44.5622 337.913 42.9016C336.394 41.2411 334.276 40.4108 331.559 40.4108C328.612 40.4108 326.264 41.4256 324.514 43.4551C322.81 45.4847 321.958 48.4137 321.958 52.2422C321.958 56.0245 322.81 58.9305 324.514 60.96C326.264 62.9434 328.727 63.9351 331.904 63.9351C333.746 63.9351 335.358 63.5892 336.739 62.8973C338.12 62.2054 339.133 61.1445 339.778 59.7146H347.997C346.846 63.0357 344.889 65.6649 342.126 67.6022C339.41 69.5395 335.979 70.5081 331.835 70.5081Z"
        fill="black"
      />
      <path
        d="M355.131 69.8162V20H364.11V69.8162H355.131ZM359.414 69.8162V62.6897H388.561V69.8162H359.414Z"
        fill="black"
      />
      <path
        d="M409.408 70.5081C405.678 70.5081 402.386 69.724 399.531 68.1557C396.722 66.5874 394.535 64.4195 392.969 61.6519C391.404 58.8382 390.621 55.6324 390.621 52.0346C390.621 48.4368 391.404 45.2771 392.969 42.5557C394.535 39.8342 396.722 37.7124 399.531 36.1903C402.386 34.622 405.678 33.8378 409.408 33.8378C413.184 33.8378 416.476 34.622 419.285 36.1903C422.093 37.7124 424.281 39.8342 425.846 42.5557C427.412 45.2771 428.195 48.4368 428.195 52.0346C428.195 55.6324 427.389 58.8382 425.777 61.6519C424.212 64.4195 422.024 66.5874 419.216 68.1557C416.407 69.724 413.137 70.5081 409.408 70.5081ZM409.408 63.52C411.25 63.52 412.907 63.0587 414.381 62.1362C415.854 61.1676 417.005 59.8299 417.834 58.1232C418.663 56.3705 419.077 54.3178 419.077 51.9654C419.077 48.5059 418.156 45.7845 416.315 43.8011C414.519 41.8177 412.217 40.8259 409.408 40.8259C406.599 40.8259 404.274 41.8177 402.432 43.8011C400.59 45.7845 399.669 48.5059 399.669 51.9654C399.669 54.3178 400.083 56.3705 400.912 58.1232C401.787 59.8299 402.938 61.1676 404.366 62.1362C405.839 63.0587 407.52 63.52 409.408 63.52Z"
        fill="black"
      />
      <path
        d="M450.62 70.5081C446.89 70.5081 443.598 69.724 440.743 68.1557C437.934 66.5874 435.747 64.4195 434.181 61.6519C432.616 58.8382 431.833 55.6324 431.833 52.0346C431.833 48.4368 432.616 45.2771 434.181 42.5557C435.747 39.8342 437.934 37.7124 440.743 36.1903C443.598 34.622 446.89 33.8378 450.62 33.8378C454.396 33.8378 457.688 34.622 460.497 36.1903C463.305 37.7124 465.493 39.8342 467.058 42.5557C468.624 45.2771 469.407 48.4368 469.407 52.0346C469.407 55.6324 468.601 58.8382 466.989 61.6519C465.424 64.4195 463.236 66.5874 460.428 68.1557C457.619 69.724 454.35 70.5081 450.62 70.5081ZM450.62 63.52C452.462 63.52 454.119 63.0587 455.593 62.1362C457.066 61.1676 458.217 59.8299 459.046 58.1232C459.875 56.3705 460.289 54.3178 460.289 51.9654C460.289 48.5059 459.369 45.7845 457.527 43.8011C455.731 41.8177 453.429 40.8259 450.62 40.8259C447.811 40.8259 445.486 41.8177 443.644 43.8011C441.802 45.7845 440.881 48.5059 440.881 51.9654C440.881 54.3178 441.295 56.3705 442.124 58.1232C442.999 59.8299 444.15 61.1676 445.578 62.1362C447.051 63.0587 448.732 63.52 450.62 63.52Z"
        fill="black"
      />
      <path
        d="M495.907 70.5081C492.546 70.5081 489.806 69.724 487.688 68.1557C485.616 66.5413 484.326 64.3272 483.82 61.5135L484.511 61.4443V84H475.808V34.5297H484.303V42.4865L483.613 42.3481C484.257 39.7189 485.731 37.6432 488.033 36.1211C490.335 34.5989 493.144 33.8378 496.46 33.8378C499.591 33.8378 502.307 34.5989 504.61 36.1211C506.958 37.5971 508.777 39.6959 510.066 42.4173C511.355 45.1387 512 48.3445 512 52.0346C512 55.7708 511.332 59.0227 509.997 61.7903C508.662 64.5578 506.797 66.7027 504.402 68.2249C502.008 69.747 499.176 70.5081 495.907 70.5081ZM493.697 63.3124C496.506 63.3124 498.739 62.3438 500.396 60.4065C502.054 58.4231 502.883 55.6555 502.883 52.1038C502.883 48.5521 502.031 45.8076 500.327 43.8703C498.67 41.933 496.436 40.9643 493.628 40.9643C490.865 40.9643 488.632 41.956 486.928 43.9395C485.224 45.8768 484.372 48.6213 484.372 52.173C484.372 55.7247 485.224 58.4692 486.928 60.4065C488.632 62.3438 490.888 63.3124 493.697 63.3124Z"
        fill="black"
      />
      <defs>
        <linearGradient id="paint0_linear_115_29434" x1="84" y1="12" x2="12" y2="84" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E500AC" />
          <stop offset="0.479006" stopColor="#9933FF" />
          <stop offset="1" stopColor="#008FF5" />
        </linearGradient>
        <linearGradient id="paint1_linear_115_29434" x1="84" y1="12" x2="12" y2="84" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E500AC" />
          <stop offset="0.479006" stopColor="#9933FF" />
          <stop offset="1" stopColor="#008FF5" />
        </linearGradient>
      </defs>
    </g>
  </Icon>
)

export default WordmarkColor
