import { Stack } from '@chakra-ui/react'
import type { ChangeEvent, FC, FocusEventHandler } from 'react'
import { useState } from 'react'
import { useSubmit } from 'react-router-dom'

import { EmailInput, SlackAutocompleteInput, ToggleInput } from '@app/shared/rawForms'

interface ReportFacade {
  sendToEmail: boolean
  sendToSlack: boolean
  emails: string
  slackChannel?: {
    value: string
  }
}

interface Props {
  report?: ReportFacade
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

const ChannelInput: FC<Props> = ({ report, onChange }) => {
  const [showEmail, setShowEmail] = useState<boolean>(report?.sendToEmail)
  const [showSlack, setShowSlack] = useState<boolean>(report?.sendToSlack)
  const submit = useSubmit()

  const changeHandler =
    onChange ||
    ((e) => {
      submit(e.target.form)
    })

  const blurHandler: FocusEventHandler<HTMLInputElement> = changeHandler

  return (
    <Stack spacing={6}>
      <Stack spacing={2}>
        <ToggleInput
          labelSize="sm"
          label="Send email"
          name="sendToEmail"
          defaultValue={showEmail}
          onChange={setShowEmail}
        />
        {showEmail && (
          <EmailInput
            labelSize="sm"
            label="Email"
            name="emails"
            type="email"
            placeholder="Add an email or multiple emails seperated by a comma"
            onChange={(e) => {
              e.preventDefault()
              e.stopPropagation()

              return false
            }}
            onBlur={blurHandler}
            multiple
            defaultValue={report?.emails || ''}
          />
        )}
      </Stack>
      <Stack spacing={2}>
        <ToggleInput
          labelSize="sm"
          label="Send slack"
          name="sendToSlack"
          defaultValue={showSlack}
          onChange={setShowSlack}
        />
        {showSlack && (
          <SlackAutocompleteInput
            labelSize="sm"
            name="slackChannelId"
            label="Slack channel"
            defaultValue={report?.slackChannel}
            onChange={changeHandler}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default ChannelInput
