import { createStandaloneToast } from '@chakra-ui/react'
import ahoy from 'ahoy.js'
import type { RouteObject } from 'react-router-dom'
import { Navigate, Outlet } from 'react-router-dom'

import Billing from '@app/pages/settings/billing/billing'
import Plan from '@app/pages/settings/billing/plan'
import EditOrganization from '@app/pages/settings/organization/edit'
import Members from '@app/pages/settings/organization/members'
import EditUser from '@app/pages/settings/user/edit'
import integrationRoutes from '@app/routes/integrations'
import { Notification } from '@app/shared/toast'
import { requiresAuthorization } from '@app/utils/auth'
import { loaderQuery, actionMutation } from '@graphql/client'
import { Invitations } from '@graphql/documents/invitation.graphql'
import { Members as MembersQuery } from '@graphql/documents/member.graphql'
import { Organization, OrganizationUpdate } from '@graphql/documents/organization.graphql'
import { UserUpdate } from '@graphql/documents/user.graphql'

const { toast } = createStandaloneToast()

const loadOrganization = async () => {
  const { data } = await loaderQuery(Organization)

  return { organization: data?.organization }
}

const loadMembersData = async () => {
  const {
    data: { members }
  } = await loaderQuery(MembersQuery, { includeDisabled: true })

  const {
    data: { invitations }
  } = await loaderQuery(Invitations)

  return { members, invitations }
}

const editUser = async ({ request }) => {
  const formData = await request.formData()
  const input = Object.fromEntries(formData)

  // Coerce the input to a boolean
  input.optedInToAnalytics = input.optedInToAnalytics === 'true'

  return actionMutation(UserUpdate, input)
    .then((resp) => {
      ahoy.track('user:edited', input)

      if (resp.error) {
        throw resp
      }

      const { user, errors } = resp.data.userUpdate

      if (errors.length) {
        throw errors
      }

      toast({
        title: 'Updating your profile',
        position: 'bottom-right',
        status: 'success',
        render: (props) => <Notification {...props} />
      })

      return { user }
    })
    .catch((resp) => ({ error: resp }))
}

const editOrganization = async ({ request }) => {
  const formData = await request.formData()
  const input = Object.fromEntries(formData)

  // when no logo the field returns an empty string.
  if (input.logo === '') {
    delete input.logo
  }

  return actionMutation(OrganizationUpdate, input)
    .then((resp) => {
      ahoy.track('organization:edited', input)

      if (resp.error) {
        throw resp
      }

      const { organization, errors } = resp.data.organizationUpdate

      if (errors.length) {
        throw errors
      }

      toast({
        title: 'Updating your organization',
        position: 'bottom-right',
        status: 'success',
        render: (props) => <Notification {...props} />
      })

      return { organization }
    })
    .catch((resp) => ({ error: resp }))
}

const routes: RouteObject = {
  path: 'settings',
  element: <Outlet />,
  children: [
    {
      index: true,
      element: <Navigate to="integrations" replace />
    },
    integrationRoutes,
    {
      path: 'billing',
      loader: () => requiresAuthorization('update', 'payment'),
      element: <Billing />
    },
    {
      path: 'plan',
      loader: () => requiresAuthorization('update', 'payment'),
      element: <Plan />
    },
    {
      path: 'organization',
      loader: () => requiresAuthorization('update', 'organization'),
      children: [
        {
          path: 'edit',
          element: <EditOrganization />,
          loader: loadOrganization,
          action: editOrganization
        }
      ]
    },
    {
      path: 'organization/members',
      loader: loadMembersData,
      element: <Members />
    },
    {
      path: 'user/edit',
      element: <EditUser />,
      action: editUser
    }
  ]
}

export default routes
