import type { FC, MutableRefObject } from 'react'
import { useImperativeHandle, useCallback, useState } from 'react'

import type { ChakraAsyncSelectProps, ReactSelectAutocompleteValue } from '@app/shared/autocomplete/chakraAutocompletes'
import { ChakraAsyncSelect } from '@app/shared/autocomplete/chakraAutocompletes'
import type { MetricSourceFormApi } from '@app/types'
import { loaderQuery } from '@graphql/client'
import { MetricsAutocomplete } from '@graphql/documents/metric.graphql'
import type { MetricsAutocompleteQuery } from '@graphql/queries'
import type { Metric } from '@graphql/types'

interface Props extends Omit<Partial<ChakraAsyncSelectProps>, 'defaultValue'> {
  exclusions?: Metric['id'][]
  defaultValue?: ReactSelectAutocompleteValue
  apiRef: MutableRefObject<MetricSourceFormApi>
}

const MetricsListField: FC<Props> = ({ apiRef, exclusions = [], defaultValue, ...rest }) => {
  const [value, setValue] = useState<ReactSelectAutocompleteValue | null>(defaultValue)
  const onChange = useCallback((newValue: ReactSelectAutocompleteValue) => setValue(newValue), [])

  useImperativeHandle(
    apiRef,
    () => ({
      reset: () => {
        setValue(defaultValue)
      },
      clear: () => {
        setValue(null)
      }
    }),
    [defaultValue]
  )

  const fetch = async (query: string) => {
    const { data } = await loaderQuery<MetricsAutocompleteQuery>(MetricsAutocomplete, { query })

    return (data?.metricsAutocomplete || [])
      .filter((metric) => !exclusions.includes(metric.id))
      .map((metric) => ({ value: metric.id, label: metric.name }))
  }

  return (
    <ChakraAsyncSelect
      aria-label="metric-autocomplete"
      asyncFetch={fetch}
      placeholder="Start typing to search metrics"
      value={value}
      data-testid="metric-name-input"
      onChange={onChange}
      {...rest}
    />
  )
}

export default MetricsListField
