import groupBy from 'lodash/groupBy'
import { useAsyncValue } from 'react-router-dom'

import Header from './components/header'
import HeroPlaybooks from './components/heroPlaybooks'
import PlaybookGroup from './components/playbookGroup'
import WhatIsAPlaybook from './components/whatIsAPlaybook'

import { HeroHeader, PageStack } from '@app/shared/layout'
import withAwait from '@app/shared/withAwait'
import type { Playbook } from '@graphql/queries'

const List = () => {
  const {
    data: { playbooks }
  } = useAsyncValue() as { data: { playbooks: Playbook[] } }
  const groupedPlaybooks = groupBy(playbooks || [], 'category')

  // `doubleloop` is a magic category to create a bucket at the top of the playbooks page
  const { doubleloop, ...restPlaybooks } = groupedPlaybooks

  return (
    <PageStack>
      <Header />
      <HeroHeader
        title="Playbooks"
        subtitle="Kickstart your strategy by using playbooks from top thinkers in the industry."
      />
      {doubleloop && <HeroPlaybooks playbooks={doubleloop} />}

      {Object.keys(restPlaybooks).map((groupName) => (
        <PlaybookGroup key={groupName} groupName={groupName} playbooks={restPlaybooks[groupName]} />
      ))}
      <WhatIsAPlaybook />
    </PageStack>
  )
}

export default withAwait(List, 'playbooks')
